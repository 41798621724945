import { Icon, IPillsProps, Pill, Tooltip } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { FC } from 'react'
import Style from '../styles/Tag.module.sass'
import { TagTypes } from '../enums/TagTypes'
import { useRecoilState } from 'recoil'
import { SearchAutomationFilters } from '../stores/AppStore'
import { AppRoutes } from '../enums/AppRoutes'

export type tagType = TagTypes.SOURCE | TagTypes.CAPABILITY | TagTypes.PRACTICE | TagTypes.REGION
export interface ITagProps extends IPillsProps {
  id: string
  type: tagType
  label: string
  name?: string
  inSearch?: boolean
  close?: () => void
  tooltipMessage?: string
}

const Tag: FC<ITagProps> = (props) => {
  const [searchAutomationFilters, setSearchAutomationFilters] = useRecoilState(SearchAutomationFilters)
  const { id, type, label, name, inSearch, tooltipMessage } = props
  const tagClasses = classNames({
    [Style.tagPill]: true,
    [Style.source]: props.type === TagTypes.SOURCE,
    [Style.capability]: props.type === TagTypes.CAPABILITY,
    [Style.practice]: props.type === TagTypes.PRACTICE,
    [Style.region]: props.type === TagTypes.REGION,
  })

  const tooltipClasses = classNames({
    'tooltip-theme-dark': true,
    [Style.pillTooltip]: true,
  })

  const handlePillClick = () => {
    if (inSearch) {
      setSearchAutomationFilters({
        ...searchAutomationFilters,
        [type]: [...searchAutomationFilters[type], { id, label: name ?? label }],
      })
    } else {
      setSearchAutomationFilters({
        [TagTypes.CAPABILITY]: [],
        [TagTypes.SOURCE]: [],
        [TagTypes.PRACTICE]: [],
        [TagTypes.REGION]: [],
        [type]: [{ id, label: name ?? label }],
      })
      location.hash = `#/${AppRoutes.SEARCH}/`
    }
  }

  return (
    <Pill cssClass={tagClasses} onClick={handlePillClick} onClose={props.close} size={props.size}>
      {label}
      {tooltipMessage && (
        <Tooltip padding={20} cssClass={tooltipClasses} defaultUp show={tooltipMessage}>
          <Icon type={'info'} size={'medium'} className={Style.pillIcon} outlined />
        </Tooltip>
      )}
    </Pill>
  )
}

export default Tag
