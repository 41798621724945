import { atom, selector } from 'recoil'
import { getAllApprovers, getPermissionHistory } from '../api/UserService'

export const AllApprovers = selector({
  key: 'user_all_approvers',
  get: async () => {
    const response = await getAllApprovers({})
    return response?.data || {}
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const ShowAddNewApproverModal = atom({
  key: 'show_add_new_approver_modal',
  default: false,
})

export const ShowEditApproverRoleModal = atom({
  key: 'show_edit_approver_role_modal',
  default: false,
})

export const ShowRemoveApproverModal = atom({
  key: 'show_remove_approver_role_modal',
  default: false,
})

export const ShowPermissionHistoryModal = atom({
  key: 'show_permission_history_modal',
  default: false,
})

export const PermissionHistory = selector({
  key: 'user_permission_history',
  get: async () => {
    const response = await getPermissionHistory({})
    return response?.data || []
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})
