import { FC } from 'react'

import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'
import HttpErrorScreen from '../components/HttpErrorScreen'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'

interface ErrorPageProps {
  errorCode: number
}

const ErrorPage: FC<ErrorPageProps> = ({ errorCode }) => {
  if (appInsights) {
    appInsights.trackPageView({ name: 'ErrorScreen' })
  }

  const renderErrorScreen = () => {
    switch (errorCode) {
      case HttpStatusCodes.UNAUTHORISED:
        return (
          <HttpErrorScreen
            httpErrorCode={'401'}
            httpErrorLabel='Unauthorised'
            errorline1='You are not authorised to access this resource.'
            errorline2='Please contact your Admin or Support for any further assistance.'
          />
        )
      case HttpStatusCodes.FORBIDDEN:
        return (
          <HttpErrorScreen
            httpErrorCode={'403'}
            httpErrorLabel='Forbidden'
            errorline1='You don’t have permission to access this resource.'
            errorline2='Please contact your Admin or Support for any further assistance.'
          />
        )
      case HttpStatusCodes.INTERNAL_SERVER_ERROR:
        return (
          <HttpErrorScreen
            httpErrorCode={'500'}
            httpErrorLabel='Internal Server Error'
            errorline1='The system is encountering some issues or having time out'
            errorline2='Please contact your Admin or Support for any further assistance.'
          />
        )
      case HttpStatusCodes.NOT_FOUND:
      default:
        return (
          <HttpErrorScreen
            httpErrorCode={'404'}
            httpErrorLabel='Not Found'
            errorline1='The page you are looking for does not exist.'
            errorline2='Please contact your Admin or Support for any further assistance.'
          />
        )
    }
  }

  return <Page>{renderErrorScreen()}</Page>
}

export default ErrorPage
