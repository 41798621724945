import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'
import Style from '../styles/UploadAutomation.module.sass'
import {
  Button,
  Dropdown,
  FormInput,
  Pill,
  Tooltip,
  Grid,
  IDropdownItemProps,
  Icon,
  useToast,
  Checkbox,
  IOption,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { Practises } from '../enums/Practises'
import { Regions } from '../enums/Regions'
import { PlatformInterfaces } from '../enums/PlatformInterfaces'
import { AutomationSources, AutomationSourcesID } from '../enums/AutomationSources'
import { AutomationSourceUploadTypes } from '../enums/AutomationSourceUploadTypes'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import FileUploader from '../components/UploadAutomation/FileUploader'
import { dropZoneAcceptMacroFileTypes, microsoftExcelTypes, officeScriptTypes } from '../helpers/files'
import { useAuth0 } from '@auth0/auth0-react'
import ScriptLibraryModal from '../components/UploadAutomation/ScriptLibraryModal'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  ShowUploadCancelModal,
  ShowUploadScriptLibraryModal,
  ShowUploadSubmitModal,
  ShowUploadWarningModal,
} from '../stores/AutomationUploadStore'
import { AppRoutes } from '../enums/AppRoutes'
import { IAutomationUploadRequest } from '../models/api/IAutomationUploadRequest'
import { AutomationStates } from '../enums/AutomationStates'
import { postAutomationUpload } from '../api/AutomationService'
import { useNavigate, useParams } from 'react-router-dom'
import WarningModal from '../components/UploadAutomation/WarningModal'
import CancelModal from '../components/UploadAutomation/CancelModal'
import SubmitModal from '../components/UploadAutomation/SubmitModal'
import ErrorModal from '../components/ErrorModal'
import { CurrentUser, ShowErrorModal } from '../stores/AppStore'
import { MACRO_SAFELISTING_LINK, SCRIPT_LIBRARY_CREATE_URL } from '../config/config'
import {
  AllSources,
  AutomationDetails,
  automationDetailsRefresh,
  AutomationEditHistoryRefresh,
} from '../stores/AutomationViewerStore'
import LoadingScreen from '../components/LoadingScreen'
import { AutomationOriginators, IGetAutomationResponse } from '../models/api/IAutomationRequest'
import {
  ActiveManageTabState,
  ApprovedAutomationsAndScripts,
  MyContributions,
  PendingApprovalList,
} from '../stores/ManageAutomationStore'
import { TabsEnum } from '../enums/Manage'
import UserComboBox from '../components/UserComboBox'
import VerificationInformation from '../components/AutomationViewer/VerificationInformation'
import { AutomationApprovalDisplayStates } from '../enums/AutomationApprovalStates'
import { automationFormFields, automationFormSchema, TITLE_LIMIT } from '../validators/AutomationFormValidator'
import { formatValidationResult } from '../helpers/commonFunctions'
import { htmlEntitiesParser, trimProtocol } from '../helpers/utils'
import { AllCapabilities } from '../stores/CapabilityStore'
import SearchComboBox, { ISearchComboBoxProps } from '../components/SearchComboBox'
import { searchClients } from '../api/WorkdayService'
import { ResponseData } from '../models/api/IResponse'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import ErrorPage from './ErrorPage'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import SingleUserComboBox from '../components/SingleUserComboBox'

interface IFormErrors {
  [key: string]: string
}

interface IFieldToCheck {
  [key: string]: unknown
}

interface ISourcesDropdown extends IDropdownItemProps {
  uploadGuidanceUrl: string | null
}

const urlFields = [automationFormFields.automationUrl, automationFormFields.guidanceMaterialUrl]

const UploadAutomation: FC = () => {
  const { automationId } = useParams<{ automationId: string }>()

  const { user } = useAuth0()
  const navigate = useNavigate()
  const { addToast } = useToast()

  const isEditMode = !!automationId

  if (appInsights) {
    appInsights.trackPageView({ name: isEditMode ? 'EditAutomation' : 'UploadAutomation' })
  }

  const automationDetails = useRecoilValueLoadable(AutomationDetails({ id: automationId ?? '' }))
  const [automation, setAutomation] = useState<IGetAutomationResponse | null>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [tempSelectedSource, setTempSelectedSource] = useState<number>(0)
  const [formErrors, setFormErrors] = useState<IFormErrors>({})
  const [dirtyFields, setDirtyFields] = useState<string[]>([])
  const [approvingCapabilities, setApprovingCapabilities] = useState<IDropdownItemProps[]>([])
  const [capabilities, setCapabilities] = useState<IDropdownItemProps[]>([])
  const [clientResult, setClientResult] = useState<ISearchComboBoxProps[]>([])
  const [searchingClient, setSearchingClient] = useState<boolean>(false)
  const [sources, setSources] = useState<ISourcesDropdown[]>([])
  const [uploadGuidanceUrl, setUploadGuidanceUrl] = useState<string | null>(null)

  const setShowUploadScriptLibraryModal = useSetRecoilState(ShowUploadScriptLibraryModal)
  const setShowUploadCancelModal = useSetRecoilState(ShowUploadCancelModal)
  const setShowUploadWarningModal = useSetRecoilState(ShowUploadWarningModal)
  const setShowUploadSubmitModal = useSetRecoilState(ShowUploadSubmitModal)
  const setShowUploadErrorModal = useSetRecoilState(ShowErrorModal)
  const capabilitiesLoader = useRecoilValueLoadable(AllCapabilities)
  const currentUser = useRecoilValue(CurrentUser)

  const refreshApprovalList = useRecoilRefresher_UNSTABLE(PendingApprovalList)
  const refreshMyContributions = useRecoilRefresher_UNSTABLE(MyContributions)
  const refreshAutomationDetails = useSetRecoilState(automationDetailsRefresh)
  const refreshEditHistory = useSetRecoilState(AutomationEditHistoryRefresh)
  const setActiveManageTab = useSetRecoilState(ActiveManageTabState)
  const refreshApprovedAutomations = useRecoilRefresher_UNSTABLE(ApprovedAutomationsAndScripts)
  const sourcesLoader = useRecoilValueLoadable(AllSources)

  const emptyForm = {
    Id: '',
    Title: '',
    Description: '',
    AutomationStateId: AutomationStates.DRAFT,
    AutomationSourceId: 0,
    UploadTypeId: 0,
    CurrentActivityTime: 0,
    AutomatedActivityTime: 0,
    PlatformInterfaceId: 0,
    ThumbnailUrl: null,
    DateCreated: new Date(),
    AuthorUserId: `${currentUser?.AuthFederationId}`,
    DateLastModified: null,
    LastModifiedByUserId: `${user?.sub}`,

    AutomationPractices: [],
    AutomationRegions: [],
    AutomationCapabilities: [],
    AutomationOriginators: [],
    ApprovingCapability: null,
    AutomationClients: [],

    AutomationFile: null,
    AutomationUrl: null,

    AutomationAuthor: user || null,

    GuidanceMaterialUrl: null,
    MacroSafelisting: false,
  }

  const [automationDetailsForm, setAutomationDetailsForm] = useState<IAutomationUploadRequest>(emptyForm)
  const originatorRef = useRef<HTMLInputElement | null>(null)
  const verifierRef = useRef<HTMLInputElement | null>(null)

  const hasFormErrors = Object.keys(formErrors).length !== 0
  const VALID_PROTOCOL = 'https://'

  const handleAddOriginator = (key: string[]) => {
    const uniqueOriginators = new Set([...key])
    handleFormUpdate(
      {
        ...automationDetailsForm,
        AutomationOriginators: Array.from(uniqueOriginators),
      },
      automationFormFields.originators,
    )
    originatorRef.current?.blur()
  }

  const handleAddVerifier = (key: string[]) => {
    const uniqueVerifiers = new Set([...key])
    setAutomationDetailsForm({
      ...automationDetailsForm,
      AutomationVerifiers: Array.from(uniqueVerifiers),
    })
    verifierRef.current?.blur()
  }

  const handleClientUpdate = (items: ISearchComboBoxProps[]) => {
    const updatedClients = items.map((item) => ({ clientId: item.id.toString(), clientName: item.name ?? item.value }))
    setAutomationDetailsForm({
      ...automationDetailsForm,
      AutomationClients: updatedClients,
    })
    setClientResult([])
  }

  const handleAuthorChange = (option: IOption) => {
    setAutomationDetailsForm({
      ...automationDetailsForm,
      AuthorUserId: option.id as string,
    })
  }

  const practises: IDropdownItemProps[] = Practises.map((x) => ({ id: x.key, label: x.value }))
  const regions: IDropdownItemProps[] = Regions.map((x) => ({ id: x.key, label: x.value }))
  const [selectedOriginators, setSelectedOriginators] = useState<AutomationOriginators[]>(
    automation?.AutomationOriginators || [],
  )
  const [selectedVerifier, setSelectedVerifier] = useState<AutomationOriginators[]>(
    automation?.AutomationVerifiers || [],
  )

  const isOwner = automation?.AutomationAuthor?.UserEmail === user?.email
  const isDraft = automation?.AutomationState?.Id === AutomationStates.DRAFT
  const isInReview = automation?.AutomationState?.Id === AutomationStates.IN_REVIEW
  const hasApprovals = Boolean(automation?.AutomationApprovals?.length)
  const hasActionRequired = Boolean(
    automation?.AutomationApprovals?.filter(
      (approval) => approval.ApprovalState.Name === AutomationApprovalDisplayStates.ActionRequired,
    ).length,
  )
  const isApproved = automation?.AutomationState?.Id === AutomationStates.APPROVED

  const canUploadNewAutomation = useUserPermission(actions.UPLOAD_AUTOMATION)
  const canEditOwnAutomation = (useUserPermission(actions.EDIT_AUTOMATION) && isOwner) || (isOwner && hasActionRequired)
  const canEditPublishedAutomations = useUserPermission(actions.EDIT_PUBLISHED_AUTOMATIONS) && isApproved
  const canEditInReviewAutomations = useUserPermission(actions.EDIT_IN_REVIEW_AUTOMATIONS) && isInReview

  const canAccessPage = isEditMode
    ? canEditOwnAutomation || canEditPublishedAutomations || canEditInReviewAutomations
    : canUploadNewAutomation

  const showSaveDraft = !automation || isDraft

  const interfaces: IDropdownItemProps[] = PlatformInterfaces.map((x) => ({ id: x.key, label: x.value }))

  const isAutomationEditable = useMemo(() => {
    if (isDraft && isOwner) return true
    if (!automation && canUploadNewAutomation && !isEditMode) return true

    if (isDraft && isOwner) return true

    if (automation?.AutomationStateId === AutomationStates.IN_REVIEW && canEditInReviewAutomations) return true

    if (
      automation?.AutomationStateId === AutomationStates.IN_REVIEW &&
      canEditOwnAutomation &&
      isOwner &&
      hasActionRequired
    )
      return true

    if (automation?.AutomationStateId === AutomationStates.APPROVED && canEditPublishedAutomations) return true

    return false
  }, [
    automation,
    canEditInReviewAutomations,
    canEditOwnAutomation,
    canEditPublishedAutomations,
    canUploadNewAutomation,
    hasActionRequired,
    isDraft,
    isEditMode,
    isOwner,
  ])

  const allowedFileTypes = useMemo(() => {
    switch (automationDetailsForm.AutomationSourceId) {
      case AutomationSourcesID.MACRO:
        return dropZoneAcceptMacroFileTypes
      case AutomationSourcesID.OFFICE_SCRIPTS:
        return officeScriptTypes
      case AutomationSourcesID.EXCEL:
        return microsoftExcelTypes
      default:
        return []
    }
  }, [automationDetailsForm.AutomationSourceId])

  const estimatedTimeSaving = (
    automationDetailsForm.CurrentActivityTime - automationDetailsForm.AutomatedActivityTime
  ).toFixed(0)

  const mapApprovingCapabilities = useCallback(
    (formValue: IAutomationUploadRequest) => {
      const caps = formValue.AutomationCapabilities?.flatMap((data) => {
        const capability = capabilities.find((x) => Number(x.id) === data)
        return { id: data, label: capability?.label }
      })

      if (caps) {
        caps.sort((a, b) => {
          if (!b.label) return 1
          if (!a.label) return -1
          return a.label?.toString().localeCompare(b.label?.toLocaleString())
        })

        setApprovingCapabilities(caps)
      }
    },
    [capabilities],
  )

  useEffect(() => {
    if (capabilitiesLoader.state !== 'hasValue' || !capabilitiesLoader.contents) return
    const data = capabilitiesLoader.contents.map((x) => ({ id: x.Id.toString(), label: x.Name }))
    setCapabilities(data)
  }, [capabilitiesLoader.contents, capabilitiesLoader.state])

  useEffect(() => {
    if (automationDetailsForm.AutomationSourceId) {
      const sourceData = sources.find((source) => Number(source.id) === automationDetailsForm.AutomationSourceId)
      if (sourceData) setUploadGuidanceUrl(sourceData.uploadGuidanceUrl)
    }
  }, [automationDetailsForm.AutomationSourceId, sources])

  useEffect(() => {
    if (sourcesLoader.state !== 'hasValue' || !sourcesLoader.contents) return
    const data = sourcesLoader.contents
      .filter((data) => data.Id !== AutomationSourcesID.STREAMLINER)
      .map((x) => ({
        id: x.Id.toString(),
        label: x.Name,
        uploadGuidanceUrl: x.UploadGuidanceUrl,
      }))

    setSources(data)
  }, [sourcesLoader.contents, sourcesLoader.state])

  useEffect(() => {
    if (!automationDetails || automationDetails.state !== 'hasValue' || !automationDetails.contents) return
    setAutomation(automationDetails.contents)
  }, [automationDetails])

  useEffect(() => {
    if (currentUser) {
      setAutomationDetailsForm((prevForm) => {
        const updatedForm = {
          ...prevForm,
          AuthorUserId: isEditMode ? prevForm.AuthorUserId : currentUser.AuthFederationId,
        }
        return JSON.stringify(prevForm) !== JSON.stringify(updatedForm) ? updatedForm : prevForm
      })
    }
  }, [currentUser, isEditMode])

  useEffect(() => {
    if (automationId && automation) {
      setSelectedOriginators(automation?.AutomationOriginators ?? [])
      setSelectedVerifier(automation?.AutomationVerifiers ?? [])
      const formValue = {
        ...automation,
        Description: htmlEntitiesParser(automation?.Description) ?? '',
        AutomationStateId: automation.AutomationStateId ?? AutomationStates.DRAFT,
        AutomationSourceId: automation.AutomationSourceId ?? 0,
        UploadTypeId: automation.UploadTypeId ?? 0,
        CurrentActivityTime: automation.CurrentActivityTime ?? 0,
        AutomatedActivityTime: automation.AutomatedActivityTime ?? 0,
        PlatformInterfaceId: automation.PlatformInterfaceId ?? 0,
        DateCreated: new Date(automation.DateCreated),
        DateLastModified: new Date(),
        AuthorUserId: isEditMode ? automation.AuthorUserId : `${currentUser?.AuthFederationId}`,

        AutomationPractices: automation.AutomationPractices?.map((val) => Number(val.Id)) ?? [],
        AutomationRegions: automation.AutomationRegions?.map((val) => Number(val.Id)) ?? [],
        AutomationCapabilities: automation.AutomationCapabilities?.map((val) => Number(val.Id)) ?? [],
        AutomationOriginators: automation.AutomationOriginators?.map((val) => val.User.AuthFederationId) as string[],
        AutomationVerifiers: automation.AutomationVerifiers?.map((val) => val.User.AuthFederationId) as string[],
        ApprovingCapability: automation.ApprovingCapability,
        AutomationClients:
          automation?.AutomationClients?.map((client) => ({
            clientId: client.ClientId,
            clientName: client.ClientName,
          })) ?? [],

        AutomationFile: automation.AutomationFileUpload
          ? new File([], automation.AutomationFileUpload.OriginalFileName)
          : null,
        AutomationUrl: trimProtocol(automation.AutomationUrl?.Url ?? ''),
        GuidanceMaterialUrl: trimProtocol(automation.GuidanceMaterialUrl ?? ''),
        MacroSafelisting: automation?.MacroSafelisting ?? false,
      }

      setAutomationDetailsForm(formValue)
      mapApprovingCapabilities(formValue)
    }
  }, [
    automation,
    automationId,
    currentUser,
    hasActionRequired,
    isDraft,
    isInReview,
    mapApprovingCapabilities,
    user?.sub,
    isEditMode,
  ])

  useEffect(() => {
    // check if the fields are dirty
    if (dirtyFields.length === 0) return
    let fieldsToCheck: IFieldToCheck = {}
    Object.keys(automationDetailsForm).forEach((key) => {
      if (dirtyFields.includes(key)) {
        fieldsToCheck = { ...fieldsToCheck, [key]: automationDetailsForm[key as keyof IAutomationUploadRequest] }
      }
    })

    // validate only the fields that are dirty
    dirtyFields.forEach((field) => {
      if (urlFields.includes(field)) {
        fieldsToCheck[field] =
          fieldsToCheck[field] === '' ? fieldsToCheck[field] : `${VALID_PROTOCOL}${fieldsToCheck[field]}`
      }
    })

    const partialSchema = automationFormSchema.fork(dirtyFields, (fieldsSchema) => fieldsSchema.required())
    const result = partialSchema.validate(fieldsToCheck, { abortEarly: false })

    // format the validation errors
    const formattedErrors = formatValidationResult(result.error)
    if (Number(estimatedTimeSaving) < 0) {
      setFormErrors({
        ...formattedErrors,
        AutomatedActivityTime: 'Value should be less than or equal to Current Activity Time (Minutes)',
        CurrentActivityTime: 'Value should be greater than or equal to Automated Activity Time (Minutes)',
      })
    } else {
      return setFormErrors(formattedErrors ?? {})
    }
  }, [automationDetailsForm, dirtyFields, estimatedTimeSaving])
  if (isSubmitting) {
    return (
      <Page menu>
        <LoadingScreen text='Saving your automation...' />
      </Page>
    )
  }

  const capabilityPillClass = classNames({
    [Style.metadataPill]: true,
    [Style.capability]: true,
  })

  const practisePillClass = classNames({
    [Style.metadataPill]: true,
    [Style.practise]: true,
  })

  const regionPillClass = classNames({
    [Style.metadataPill]: true,
    [Style.region]: true,
  })

  const primaryButtonClass = classNames({
    [Style.uploadButton]: true,
    [Style.uploadButtonPrimary]: true,
  })

  const secondaryButtonClass = classNames({
    [Style.uploadButton]: true,
    [Style.uploadButtonSecondary]: true,
  })

  const capabilityDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.capability],
  })

  const practiceDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.practice],
  })

  const regionDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.region],
  })

  const originatorDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.originators],
  })

  const verifierDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
  })

  const sourceDropdownClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.automationSource],
  })

  const automamtionDescriptionClass = classNames({
    [Style.metadataLongText]: true,
    [Style.hasError]: !!formErrors[automationFormFields.description],
  })

  const automationUrlClass = classNames({
    [Style.uploadUrlField]: true,
    [Style.hasError]: !!formErrors[automationFormFields.automationUrl],
  })

  const approvingCapabilityClass = classNames({
    [Style.metadataDropDownBox]: true,
    [Style.hasError]: !!formErrors[automationFormFields.approvingCapability],
  })

  const guidanceMaterialUrlClass = classNames({
    [Style.uploadUrlField]: true,
    [Style.hasError]: !!formErrors[automationFormFields.guidanceMaterialUrl],
  })

  const handleFormUpdate = (formDetails: IAutomationUploadRequest, field: string) => {
    let formValue: IAutomationUploadRequest = urlFields.includes(field)
      ? { ...formDetails, [field]: trimProtocol(formDetails[field as keyof IAutomationUploadRequest] as string) }
      : formDetails

    if (field === automationFormFields.capability) {
      if (formValue.ApprovingCapability && !formValue.AutomationCapabilities?.includes(formValue.ApprovingCapability)) {
        formValue = { ...formValue, ApprovingCapability: null }
      }

      mapApprovingCapabilities(formValue)
    }

    setAutomationDetailsForm(formValue)
    if (!dirtyFields.includes(field)) {
      const dirtyFieldsCopy = dirtyFields
      dirtyFieldsCopy.push(field)
      setDirtyFields(dirtyFieldsCopy)
    }
  }

  const handlePillClose = (
    property: 'AutomationPractices' | 'AutomationRegions' | 'AutomationCapabilities',
    item: number,
  ) => {
    if (!isAutomationEditable) return
    const selectedPills = automationDetailsForm[property]
    handleFormUpdate(
      {
        ...automationDetailsForm,
        [property]: selectedPills?.filter((v: number) => v !== item) || [],
      },
      property,
    )
  }

  const showCapabilityPills = () => {
    return automationDetailsForm.AutomationCapabilities?.map((item) => (
      <div key={item} className={Style.metadataPill}>
        <Pill
          size='medium'
          cssClass={capabilityPillClass}
          onClose={() => {
            handlePillClose('AutomationCapabilities', item)
          }}
        >
          {capabilities.find((x) => x.id === item.toString())!.label}
        </Pill>
      </div>
    ))
  }

  const showPractisePills = () => {
    return automationDetailsForm.AutomationPractices?.map((item) => (
      <div key={item} className={Style.metadataPill}>
        <Pill
          size='medium'
          cssClass={practisePillClass}
          onClose={() => {
            handlePillClose('AutomationPractices', item)
          }}
        >
          {practises.find((x) => x.id === item.toString())!.label}
        </Pill>
      </div>
    ))
  }

  const showRegionPills = () => {
    return automationDetailsForm.AutomationRegions?.map((item) => (
      <div key={item} className={Style.metadataPill}>
        <Pill
          size='medium'
          cssClass={regionPillClass}
          onClose={() => {
            handlePillClose('AutomationRegions', item)
          }}
        >
          {regions.find((x) => x.id === item.toString())!.label}
        </Pill>
      </div>
    ))
  }

  const handleClientSearch = async (searchText: string) => {
    setClientResult([])
    if (searchText.length < 3) {
      return
    }

    setSearchingClient(true)
    const clientsList = ResponseData(await searchClients({ searchText })) || []
    const existingClients = automationDetailsForm.AutomationClients.map((client) => client.clientId)

    const result = clientsList
      .map((c) => ({
        id: c.clientId,
        value: `${c.clientName} [${c.clientId}]`,
        name: c.clientName,
      }))
      .filter((c) => !existingClients.includes(c.id))

    setClientResult(result)
    setSearchingClient(false)
  }

  const sourceName = AutomationSources.find((x) => x.key === automationDetailsForm.AutomationSourceId.toString())?.value
  const uploadType = parseInt(AutomationSourceUploadTypes.find((x) => x.key === sourceName)?.value as string)

  const isExternalUpload =
    uploadType === AutomationUploadTypes.SCRIPT_LIBRARY || uploadType === AutomationUploadTypes.STREAMLINER

  const getUploadInput = () => {
    if (!sourceName) return null

    switch (uploadType) {
      case AutomationUploadTypes.SCRIPT_LIBRARY:
        return (
          <div className={Style.scriptLibraryUploadBox}>
            <div className={Style.leftColumn}>
              <p>To upload this type of Automation, go to the Script Library to submit</p>
            </div>

            <Button
              cssClass={Style.scriptLibraryButton}
              onClick={() => {
                window.open(SCRIPT_LIBRARY_CREATE_URL, '_blank')
                setTimeout(() => {
                  setShowUploadScriptLibraryModal(true)
                  handleClearForm()
                })
              }}
              type='custom'
              size='extra small'
              label='Script Library'
            />
          </div>
        )

      case AutomationUploadTypes.UPLOAD_FILE:
        return (
          <>
            <div className={Style.uploadFileInstruction}>
              The {sourceName} file should be signed and internal. The {sourceName} code should be scanned with zero
              vulnerabilities
            </div>
            <Tooltip
              padding={32}
              cssClass='tooltip-theme-dark'
              defaultUp
              show='Please upload the macro-enabled document'
            >
              <div className={Style.uploadFileHeader}>Upload file *</div>
            </Tooltip>
            <FileUploader
              maxFiles={1}
              allowedFileTypes={allowedFileTypes}
              selectedFiles={automationDetailsForm.AutomationFile ? [automationDetailsForm.AutomationFile] : []}
              setSelectedFiles={(value) => {
                setAutomationDetailsForm({
                  ...automationDetailsForm,
                  AutomationFile: value[0],
                })
              }}
              cancelUpload={() => {
                setAutomationDetailsForm({
                  ...automationDetailsForm,
                  AutomationFile: null,
                })
              }}
              isDisabled={!isAutomationEditable}
              automationSourceId={automationDetailsForm.AutomationSourceId}
            />
          </>
        )

      case AutomationUploadTypes.LINK:
        return uploadUrlInput()

      default:
        return null
    }
  }

  const handleSaveAutomationUpload = async (stateId: number): Promise<void> => {
    const validateUrlUpload = uploadType === AutomationUploadTypes.LINK
    const request = {
      ...automationDetailsForm,
      Id: isEditMode ? automationId : '',
      UploadTypeId: uploadType,
      AutomationStateId: isApproved ? AutomationStates.APPROVED : stateId,
      AutomationUrl:
        uploadType === AutomationUploadTypes.LINK ? `${VALID_PROTOCOL}${automationDetailsForm.AutomationUrl}` : null,
      GuidanceMaterialUrl: automationDetailsForm.GuidanceMaterialUrl
        ? `${VALID_PROTOCOL}${automationDetailsForm.GuidanceMaterialUrl}`
        : null,
    }

    let fieldsToCheck: IFieldToCheck = {}
    Object.keys(automationFormFields).forEach((key) => {
      const field = automationFormFields[key as keyof typeof automationFormFields]

      fieldsToCheck = {
        ...fieldsToCheck,
        [field]: automationDetailsForm[field as keyof IAutomationUploadRequest],
      }
    })

    if (validateUrlUpload) {
      fieldsToCheck = {
        ...fieldsToCheck,
        AutomationUrl: `${VALID_PROTOCOL}${fieldsToCheck[automationFormFields.automationUrl]}`,
      }
    } else {
      delete fieldsToCheck[automationFormFields.automationUrl]
    }

    if (!automationDetailsForm.GuidanceMaterialUrl) {
      delete fieldsToCheck[automationFormFields.guidanceMaterialUrl]
    }

    const result = automationFormSchema.validate(fieldsToCheck, { abortEarly: false })
    if (result.error) {
      const formattedErrors = formatValidationResult(result.error)
      const mappedDirtyFields: string[] = []
      Object.keys(fieldsToCheck).forEach((key) => mappedDirtyFields.push(key))
      setDirtyFields(mappedDirtyFields)
      setFormErrors(formattedErrors ?? {})
      return
    }

    setIsSubmitting(true)
    try {
      const result = await postAutomationUpload(request)
      if (result?.success) {
        if (appInsights && !isEditMode) {
          // only track the new submissions
          appInsights.trackEvent({ name: 'Submitted Scripts' })
        }

        addToast({
          type: 'success',
          message: `Your automation has been submitted`,
          timeout: 10000,
        })

        if (isApproved || (isEditMode && !isDraft)) refreshApprovedAutomations()
        refreshMyContributions()
        refreshApprovalList()
        refreshAutomationDetails((n) => n + 1)
        refreshEditHistory((n) => n + 1)
        setIsSubmitting(false)
        if (isOwner) setActiveManageTab(TabsEnum.MY_CONTRIBUTIONS)
        else setActiveManageTab(TabsEnum.APPROVALS)
        navigate(`/${AppRoutes.MANAGE}`)
      } else {
        setShowUploadErrorModal(true)
      }
    } catch (error) {
      setShowUploadErrorModal(true)
    }

    setIsSubmitting(false)
  }

  const handleOpenUrl = (field: string) => {
    const fieldValue = automationDetailsForm[field as keyof IAutomationUploadRequest]?.valueOf()
    if (!fieldValue || formErrors[field]) return
    window.open(`${VALID_PROTOCOL}${fieldValue}`, '_blank')
  }

  const uploadUrlInput = () => {
    return (
      <div className={Style.uploadUrlContainer}>
        <Tooltip
          padding={32}
          cssClass='tooltip-theme-dark'
          defaultUp
          show='Provide a link to the Power Automate or Power App. Please ensure that the solution is shared with svc-pp-aip@aurecongroup.com to accelerate the review'
        >
          <div className={Style.uploadUrlHeading}>Upload URL *</div>
        </Tooltip>
        <div className={Style.uploadUrlInputBox}>
          <div className={automationUrlClass}>
            <Icon cssClass={Style.urlIcon} type={'link'} />
            <span className={Style.protocolLabel}>{VALID_PROTOCOL}</span>
            <FormInput
              placeholder='Type or paste a URL here'
              value={automationDetailsForm.AutomationUrl || ''}
              onChange={(value) =>
                handleFormUpdate(
                  { ...automationDetailsForm, AutomationUrl: value.trim() },
                  automationFormFields.automationUrl,
                )
              }
              iconOnRight={false}
              cssClass={Style.uploadUrlInput}
              disabled={!isAutomationEditable}
            />
          </div>

          <Button
            type='icon-round'
            icon='launch'
            cssClass={Style.uploadUrlOpenButton}
            disabled={!!formErrors[automationFormFields.automationUrl]}
            onClick={() => handleOpenUrl(automationFormFields.automationUrl)}
          />
        </div>
        {formErrors[automationFormFields.automationUrl] && (
          <p className={Style.errorMessage}>{formErrors[automationFormFields.automationUrl]}</p>
        )}
      </div>
    )
  }

  const commonProps = {
    required: !isExternalUpload,
    disabled: isExternalUpload || !isAutomationEditable,
  }

  const canSaveDraft = (): boolean => {
    return canSubmit()
  }

  const canSubmit = (): boolean => {
    if (isSubmitting) return false
    if (!automationDetailsForm?.AuthorUserId) return false
    if (isEditMode && !isAutomationEditable) return false
    if (!isEditMode && !canUploadNewAutomation) return false
    if (hasFormErrors) return false

    if (uploadType === AutomationUploadTypes.UPLOAD_FILE && !automationDetailsForm.AutomationFile) return false

    return true
  }

  const handleSelectedSourceChange = (value: number) => {
    if (automationDetailsForm.AutomationSourceId === value || automationDetailsForm.AutomationSourceId === 0) {
      handleFormUpdate(
        {
          ...automationDetailsForm,
          AutomationSourceId: value,
        },
        automationFormFields.automationSource,
      )
    } else {
      setTempSelectedSource(value)
      setShowUploadWarningModal(true)
    }
  }

  const handleWarningModalYes = () => {
    setSelectedOriginators([])
    setAutomationDetailsForm({
      ...emptyForm,
      AutomationSourceId: tempSelectedSource,
    })
    setFormErrors({})
    setDirtyFields([])
    setTempSelectedSource(0)
  }

  const handleClearForm = () => {
    setSelectedOriginators([])
    setAutomationDetailsForm({
      ...emptyForm,
    })
    setFormErrors({})
    setDirtyFields([])
    setTempSelectedSource(0)
  }

  const handleTitleChange = (value: string) => {
    if (value.length <= 100) {
      handleFormUpdate({ ...automationDetailsForm, Title: value }, automationFormFields.title)
    }
  }

  if (automationDetails.state === 'loading') {
    return (
      <Page menu>
        <div className={Style.uploadLoader}>
          <LoadingScreen size='extra small' text='Loading Details' />
        </div>
      </Page>
    )
  }

  if (!canAccessPage) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  return (
    <Page menu>
      <div className={Style.uploadScreen}>
        <div className={Style.pageHeading}>{isEditMode ? 'Edit' : 'Upload'} Automation</div>

        <Grid row cssClass={Style.uploadColumnsContainer}>
          <Grid item lg={6} cssClass={Style.fieldsColumnContainer}>
            <div id='Column1'>
              <Grid item xs={12} cssClass={Style.metadataSection}>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.metadataSectionHeading}>Automation</div>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Select the technical source of your automation solution'
                    >
                      <div className={Style.metadataFieldlabel}>Automation Source *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={sourceDropdownClass}>
                      <Dropdown
                        items={sources}
                        selectedItem={automationDetailsForm.AutomationSourceId.toString()}
                        onSelectItem={(v) => handleSelectedSourceChange(Number(v))}
                        required
                        placeholder={sources.length === 0 ? 'Loading...' : 'Automation Source'}
                        disabled={!isAutomationEditable || isEditMode || sources.length === 0}
                      />
                      {formErrors[automationFormFields.automationSource] && (
                        <p className={Style.errorMessage}>{formErrors[automationFormFields.automationSource]}</p>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {uploadGuidanceUrl && (
                  <Grid item xs={12} cssClass={Style.metadataRow}>
                    <span className={Style.fieldDetails}>
                      See{' '}
                      <a href={uploadGuidanceUrl} target='_blank' className={Style.uploadGuidanceUrl} rel='noreferrer'>
                        here <Icon type='launch' className={Style.uploadGuidanceUrl} />
                      </a>{' '}
                      for instructions on how to upload and add automation tracker to measure your contribution.
                    </span>
                  </Grid>
                )}
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.uploadInputContainer}>{getUploadInput()}</div>
                </Grid>
                {uploadType === AutomationUploadTypes.UPLOAD_FILE && (
                  <Grid item xs={12} cssClass={Style.metadataCheckboxRow}>
                    <div className={Style.metadataFieldCheckbox}>
                      <Checkbox
                        label=''
                        checked={automationDetailsForm.MacroSafelisting}
                        onChange={(value) =>
                          handleFormUpdate(
                            {
                              ...automationDetailsForm,
                              MacroSafelisting: value,
                            },
                            automationFormFields.macroSafelisting,
                          )
                        }
                        disabled={isEditMode}
                      />
                    </div>
                    <span className={Style.uploadSafelistingInstruction}>
                      This macro has been through the{' '}
                      <a href={MACRO_SAFELISTING_LINK} target='_blank' className={Style.uploadLink} rel='noreferrer'>
                        Aurecon Macro Safelisting
                      </a>{' '}
                      process *
                    </span>
                    {formErrors[automationFormFields.macroSafelisting] && (
                      <p className={Style.errorMessage}>{formErrors[automationFormFields.macroSafelisting]}</p>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} cssClass={Style.metadataSpacerRow} />
              <Grid item xs={12} cssClass={Style.metadataSection}>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.metadataSectionHeading}>Estimated Average Time Saved Per Run</div>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='How long does the original non-automated process take to complete in minutes. Please provide as accurate numbers as possible. If unknown put 0'
                    >
                      <div className={Style.metadataFieldlabel}>Current Activity Time (Minutes) *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={Style.metadataTextBox}>
                      <FormInput
                        cssClass={Style.metadataTextInput}
                        type='number'
                        placeholder='Enter number here'
                        value={automationDetailsForm?.CurrentActivityTime.toString() || ''}
                        onChange={(value) =>
                          handleFormUpdate(
                            {
                              ...automationDetailsForm,
                              CurrentActivityTime: parseFloat(value),
                            },
                            automationFormFields.currentActivityTime,
                          )
                        }
                        {...commonProps}
                        error={formErrors[automationFormFields.currentActivityTime]}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='How long does the automated process take to complete in minutes. Please provide as accurate numbers as possible. If unknown put 0'
                    >
                      <div className={Style.metadataFieldlabel}>Automated Activity Time (Minutes) *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={Style.metadataTextBox}>
                      <FormInput
                        cssClass={Style.metadataTextInput}
                        type='number'
                        placeholder='Enter number here'
                        value={automationDetailsForm?.AutomatedActivityTime.toString() || ''}
                        onChange={(value) =>
                          handleFormUpdate(
                            {
                              ...automationDetailsForm,
                              AutomatedActivityTime: parseFloat(value),
                            },
                            automationFormFields.automatedActivityTime,
                          )
                        }
                        error={formErrors[automationFormFields.automatedActivityTime]}
                        {...commonProps}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Calculated from automated process time subtracted from original process time'
                    >
                      <div className={Style.metadataFieldlabel}>Estimated Time Saving</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={Style.metadataPlainText}>{estimatedTimeSaving}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} cssClass={Style.metadataSpacerRow} />
              <Grid item xs={12} cssClass={Style.metadataSection}>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.metadataSectionHeading}>Automation Guidance Material</div>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <span className={Style.fieldDetails}>
                    Please provide a URL to your guidance/training material. This could be a link to a video in Stream
                    or a PDF file with instructions. We recommend the guidance material is stored in kHubs or on Hive so
                    this can be easily accessed.
                  </span>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.uploadInputContainer}>
                    <div className={Style.uploadUrlContainer}>
                      <div className={Style.uploadUrlHeading}>Guidance Material Link</div>
                      <div className={Style.uploadUrlInputBox}>
                        <div className={guidanceMaterialUrlClass}>
                          <Icon cssClass={Style.urlIcon} type={'link'} />
                          <span className={Style.protocolLabel}>{VALID_PROTOCOL}</span>
                          <FormInput
                            placeholder='Type or paste a URL here'
                            value={automationDetailsForm.GuidanceMaterialUrl || ''}
                            onChange={(value) =>
                              handleFormUpdate(
                                { ...automationDetailsForm, GuidanceMaterialUrl: value.trim() },
                                automationFormFields.guidanceMaterialUrl,
                              )
                            }
                            iconOnRight={false}
                            cssClass={Style.uploadUrlInput}
                            disabled={!isAutomationEditable}
                          />
                        </div>

                        <Button
                          type='icon-round'
                          icon='launch'
                          cssClass={Style.uploadUrlOpenButton}
                          disabled={!!formErrors[automationFormFields.guidanceMaterialUrl]}
                          onClick={() => handleOpenUrl(automationFormFields.guidanceMaterialUrl)}
                        />
                      </div>
                      {formErrors[automationFormFields.guidanceMaterialUrl] && (
                        <p className={Style.errorMessage}>{formErrors[automationFormFields.guidanceMaterialUrl]}</p>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={6} cssClass={Style.fieldsColumnContainer}>
            <div id='Column2'>
              <Grid item xs={12} cssClass={Style.metadataSection}>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <div className={Style.metadataSectionHeading}>Information</div>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Please provide a descriptive title. Note this is used in search'
                    >
                      <div className={Style.metadataFieldlabel}>Automation Title *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={Style.metadataTextBox}>
                      <FormInput
                        cssClass={Style.metadataTextInput}
                        type='text'
                        placeholder='Hint text here'
                        value={automationDetailsForm?.Title}
                        onChange={(value) => handleTitleChange(value)}
                        multilineLimit={TITLE_LIMIT}
                        error={formErrors[automationFormFields.title]}
                        {...commonProps}
                      />
                      <div className={Style.textFieldLimit}>
                        {TITLE_LIMIT - automationDetailsForm?.Title.length} characters left.
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show={
                        <div>
                          <p>Include details on the following:</p>
                          <ul>
                            <li>Overview of what the automation does</li>
                            <li>How this automation is used</li>
                            <li>Who the automation is used by (groups/teams etc)</li>
                            <li>Problem the automation is solving</li>
                          </ul>
                        </div>
                      }
                    >
                      <div className={Style.metadataFieldlabel}>Automation Description *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <div className={Style.metadataTextBox}>
                      <FormInput
                        cssClass={automamtionDescriptionClass}
                        type='text'
                        placeholder='Hint text here'
                        multiline={true}
                        multilineLimit={1000}
                        value={automationDetailsForm?.Description}
                        onChange={(value) =>
                          handleFormUpdate(
                            { ...automationDetailsForm, Description: value },
                            automationFormFields.description,
                          )
                        }
                        error={formErrors[automationFormFields.description]}
                        {...commonProps}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Select the primary capability for this automation. Multiple capabilities can be selected'
                    >
                      <div className={Style.metadataFieldlabel}>Capability *</div>
                    </Tooltip>
                  </Grid>
                  {automationDetailsForm.AutomationCapabilities &&
                    automationDetailsForm.AutomationCapabilities?.length > 0 && (
                      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                        <div className={Style.metadataPillsBox}>{showCapabilityPills()}</div>
                      </Grid>
                    )}
                  <Grid item xs={12} cssClass={capabilityDropdownClass}>
                    <Dropdown
                      multiple
                      selectedMultipleItems={
                        automationDetailsForm.AutomationCapabilities?.map((value) => value.toString()) || []
                      }
                      items={capabilities}
                      onSelectMultipleItems={(value) =>
                        handleFormUpdate(
                          {
                            ...automationDetailsForm,
                            AutomationCapabilities: value.length ? value.map(Number) : [],
                          },
                          automationFormFields.capability,
                        )
                      }
                      placeholder={capabilities.length === 0 ? 'Loading...' : '- Select an option -'}
                      required={!isExternalUpload}
                      disabled={isExternalUpload || !isAutomationEditable || capabilities.length === 0}
                      toggleAllItem='Select All'
                    />
                    {formErrors[automationFormFields.capability] && (
                      <p className={Style.errorMessage}>{formErrors[automationFormFields.capability]}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Select the capability that is qualified to technically verify and approve this automation. An email will be sent to the relevant approvers for their action.'
                    >
                      <div className={Style.metadataFieldlabel}>Authorisation Capability *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={approvingCapabilityClass}>
                    <Dropdown
                      items={approvingCapabilities}
                      selectedItem={automationDetailsForm.ApprovingCapability ?? 0}
                      onSelectItem={(value) =>
                        handleFormUpdate(
                          {
                            ...automationDetailsForm,
                            ApprovingCapability: Number(value),
                          },
                          automationFormFields.approvingCapability,
                        )
                      }
                      required={!isExternalUpload}
                      disabled={isExternalUpload || !isAutomationEditable || approvingCapabilities.length === 0}
                      toggleAllItem='Select All'
                    />
                    {formErrors[automationFormFields.approvingCapability] && (
                      <p className={Style.errorMessage}>{formErrors[automationFormFields.approvingCapability]}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Select the primary practice for this automation. Multiple practices can be selected'
                    >
                      <div className={Style.metadataFieldlabel}>Practice *</div>
                    </Tooltip>
                  </Grid>
                  {automationDetailsForm.AutomationPractices &&
                    automationDetailsForm.AutomationPractices?.length > 0 && (
                      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                        <div className={Style.metadataPillsBox}>{showPractisePills()}</div>
                      </Grid>
                    )}
                  <Grid item xs={12} cssClass={practiceDropdownClass}>
                    <Dropdown
                      multiple
                      selectedMultipleItems={
                        automationDetailsForm.AutomationPractices?.map((value) => value.toString()) || []
                      }
                      items={practises}
                      onSelectMultipleItems={(value) => {
                        handleFormUpdate(
                          {
                            ...automationDetailsForm,
                            AutomationPractices: value.length ? `${value}`.split(',').map(Number) : [],
                          },
                          automationFormFields.practice,
                        )
                      }}
                      {...commonProps}
                      toggleAllItem='Select All'
                    />
                    {formErrors[automationFormFields.practice] && (
                      <p className={Style.errorMessage}>{formErrors[automationFormFields.practice]}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show={
                        <p>
                          Select the primary region for this automation. Multiple regions can be selected. If this
                          automation can be used by all regions please select 'Global'
                        </p>
                      }
                    >
                      <div className={Style.metadataFieldlabel}>Region *</div>
                    </Tooltip>
                  </Grid>
                  {automationDetailsForm.AutomationRegions && automationDetailsForm.AutomationRegions?.length > 0 && (
                    <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                      <div className={Style.metadataPillsBox}>{showRegionPills()}</div>
                    </Grid>
                  )}
                  <Grid item xs={12} cssClass={regionDropdownClass}>
                    <Dropdown
                      multiple
                      selectedMultipleItems={
                        automationDetailsForm.AutomationRegions?.map((value) => value.toString()) || []
                      }
                      items={regions}
                      onSelectMultipleItems={(value) => {
                        handleFormUpdate(
                          {
                            ...automationDetailsForm,
                            AutomationRegions: value.length ? `${value}`.split(',').map(Number) : [],
                          },
                          automationFormFields.region,
                        )
                      }}
                      {...commonProps}
                      toggleAllItem='Select All'
                    />
                    {formErrors[automationFormFields.region] && (
                      <p className={Style.errorMessage}>{formErrors[automationFormFields.region]}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Identify the primary author/s (creators/developers) of the automation'
                    >
                      <div className={Style.metadataFieldlabel}>Primary Author *</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <SingleUserComboBox
                      currentUser={automation?.AuthorUserId ? automation.AutomationAuthor : currentUser}
                      disabled={!currentUser || isEditMode}
                      placeholder={currentUser ? 'Search for Author' : 'Loading...'}
                      onSelectedItemsChange={handleAuthorChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} cssClass={originatorDropdownClass}>
                  <UserComboBox
                    onSelectedItem={(key) => handleAddOriginator(key)}
                    selectedItems={selectedOriginators}
                    label='Originator *'
                    {...commonProps}
                    placeholder='Search for Username'
                    error={formErrors[automationFormFields.originators]}
                  />
                </Grid>
                <Grid item xs={12} cssClass={verifierDropdownClass}>
                  <UserComboBox
                    onSelectedItem={(key) => handleAddVerifier(key)}
                    selectedItems={selectedVerifier}
                    label='Verified by'
                    {...commonProps}
                    placeholder='Search for Username'
                  />
                </Grid>
                <Grid item xs={12} cssClass={Style.metadataDropDownBox}>
                  <SearchComboBox
                    selectedItems={automationDetailsForm.AutomationClients.map((item) => ({
                      id: item.clientId,
                      value: `${item.clientName} [${item.clientId}]`,
                      name: item.clientName,
                    }))}
                    label='Client'
                    {...commonProps}
                    placeholder='Search for Client'
                    onSearchTextChange={handleClientSearch}
                    items={clientResult}
                    isLoading={searchingClient}
                    onSelectedItemsChange={handleClientUpdate}
                  />
                </Grid>

                <Grid item xs={12} cssClass={Style.metadataRow}>
                  <Grid item xs={12} cssClass={Style.metadataFieldRow}>
                    <Tooltip
                      padding={32}
                      cssClass='tooltip-theme-dark'
                      defaultUp
                      show='Detail any platforms this automation interfaces with - e.g. Revit, MicroStation, Civil 3D, ProjectWise, One Space, etc'
                    >
                      <div className={Style.metadataFieldlabel}>Platform Interface</div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} cssClass={Style.metadataDropDownBox}>
                    <Dropdown
                      items={interfaces}
                      selectedItem={automationDetailsForm.PlatformInterfaceId.toString()}
                      onSelectItem={(value) =>
                        setAutomationDetailsForm({
                          ...automationDetailsForm,
                          PlatformInterfaceId: Number(value),
                        })
                      }
                      {...commonProps}
                      toggleAllItem='Select All'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {isEditMode && hasApprovals && (
          <>
            <div className={Style.uploadDivider} />
            <VerificationInformation automationApprovals={automation?.AutomationApprovals ?? []} />
          </>
        )}

        <div className={Style.uploadFooter}>
          <div className={Style.uploadDivider} />
          <div className={Style.uploadButtonBox}>
            <Button
              cssClass={secondaryButtonClass}
              type='custom'
              size='extra small'
              label='Cancel'
              onClick={() => setShowUploadCancelModal(true)}
            />
            {showSaveDraft && (
              <Button
                cssClass={primaryButtonClass}
                type='custom'
                size='extra small'
                label='Save As Draft'
                disabled={!canSaveDraft() || isSubmitting}
                onClick={() => handleSaveAutomationUpload(AutomationStates.DRAFT)}
              />
            )}

            <Button
              cssClass={primaryButtonClass}
              type='custom'
              size='extra small'
              label='Submit'
              disabled={!canSubmit()}
              onClick={() => setShowUploadSubmitModal(true)}
            />
          </div>
        </div>
      </div>
      <ScriptLibraryModal />
      <WarningModal onYes={handleWarningModalYes} />
      <CancelModal
        onYes={() => {
          location.hash = `#/${AppRoutes.DISCOVER}`
        }}
      />
      <SubmitModal
        onYes={() => handleSaveAutomationUpload(AutomationStates.IN_REVIEW)}
        isApproved={automation?.AutomationState?.Id === AutomationStates.APPROVED}
        isSystemAdmin={canEditPublishedAutomations || canEditInReviewAutomations}
      />
      <ErrorModal />
    </Page>
  )
}

export default UploadAutomation
