import { atom } from 'recoil'
import { IFeaturedAutomations } from '../models/api/IFeaturedAutomations'

export const ShowEditTopFeaturedModal = atom({
  key: 'show_edit_top_featured_modal',
  default: false,
})

export const ShowEditBottomFeaturedModal = atom({
  key: 'show_edit_bottom_featured_modal',
  default: false,
})

export const ShowReplaceAutomationModal = atom({
  key: 'show_replace_automation_modal',
  default: false,
})

export const FeaturedAutomationsData = atom<IFeaturedAutomations>({
  key: 'featured_automations_data',
  default: {
    top: null,
    left: null,
    right: null,
  },
})

export const ShowReorderFeaturedModal = atom({
  key: 'show_reorder_featured_modal',
  default: false,
})
