import { FC, useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { Icon, Tooltip } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/CommentButton.module.sass'
import { AutomationComments } from '../../../stores/AutomationViewerStore'
import { AutomationUploadTypes } from '../../../enums/AutomationUploadTypes'
import { AppRoutes } from '../../../enums/AppRoutes'
import { useNavigate } from 'react-router-dom'

export interface ICommentButtonProps {
  id: string
  size?: string
  defaultUp?: boolean
  uploadType?: number
}

const CommentButton: FC<ICommentButtonProps> = (props) => {
  const { id, size = '18px', defaultUp = true, uploadType } = props

  const automationCommentsLoader = useRecoilValueLoadable(AutomationComments({ automationId: id }))
  const [commentCount, setCommentCount] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (automationCommentsLoader.state === 'loading') return

    if (automationCommentsLoader.state === 'hasValue') {
      setCommentCount(automationCommentsLoader.contents?.length ?? 0)
      return
    }

    setCommentCount(0)
  }, [automationCommentsLoader])

  const handleRedirect = (e: React.MouseEvent) => {
    e.stopPropagation()
    let navigateLink = `/${AppRoutes.VIEW_AUTOMATION}/${id}`
    if (uploadType === AutomationUploadTypes.SCRIPT_LIBRARY)
      navigateLink = `/${AppRoutes.VIEW_AUTOMATION}/${id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`

    if (uploadType === AutomationUploadTypes.STREAMLINER)
      navigateLink = `/${AppRoutes.VIEW_AUTOMATION}/${id}/${AutomationUploadTypes.STREAMLINER}`

    navigate(`${navigateLink}#comments`)
  }

  return (
    <div className={Style.comments}>
      <button tabIndex={0} onClick={handleRedirect}>
        <Tooltip show={'Reply'} cssClass='tooltip-theme-dark' defaultUp={defaultUp}>
          <Icon type='comment' size={size} outlined />
        </Tooltip>
      </button>
      <div className={Style.numberOfcomments}>{automationCommentsLoader.state === 'loading' ? '' : commentCount}</div>
    </div>
  )
}

export default CommentButton
