import { atom, selector } from 'recoil'
import { TabsEnum } from '../enums/Manage'
import {
  getDeactivatedAutomations,
  getManageAutomationsTableRows,
  getMyContributions,
  postSearchAutomation,
} from '../api/AutomationService'
import { ResponseData } from '../models/api/IResponse'
import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { IManageAutomationTableRow } from '../models/api/IManageAutomationTableRow'
import {
  IAutomationSearchResponse,
  ISearchAutomationCount,
  ISearchAutomationCriteria,
} from '../models/api/IAutomationRequest'
import { TagTypes } from '../enums/TagTypes'

interface IFilterResultsProps {
  automationList: IManageAutomationTableRow[] | null
  search: string
  practicesFilter: IDropdownItemProps[]
  capabilitiesFilter: IDropdownItemProps[]
  regionsFilter: IDropdownItemProps[]
  sourcesFilter: IDropdownItemProps[]
}

export interface IFilterState {
  source: IDropdownItemProps[]
  capability: IDropdownItemProps[]
  practice: IDropdownItemProps[]
  region: IDropdownItemProps[]
}

const filterResults = (props: IFilterResultsProps) => {
  const { automationList, practicesFilter, capabilitiesFilter, regionsFilter, sourcesFilter, search } = props

  return automationList?.filter((automation) => {
    return (
      automation.Title.trim().toLowerCase().includes(search.trim().toLowerCase()) &&
      (practicesFilter.length === 0 ||
        automation.AutomationPractices?.some((practice) =>
          practicesFilter.some((p) => p.label?.toString().toLowerCase() === practice.Name.toLowerCase()),
        )) &&
      (capabilitiesFilter.length === 0 ||
        automation.AutomationCapabilities?.some((capability) =>
          capabilitiesFilter.some((c) => c.id?.toString() === capability.Id.toString()),
        )) &&
      (regionsFilter.length === 0 ||
        automation.AutomationCapabilities?.some((region) =>
          regionsFilter.some((regionFilter) => regionFilter.id?.toString() === region.Id.toString()),
        )) &&
      (sourcesFilter.length === 0 ||
        sourcesFilter.some((source) => source.id.toString() === automation.AutomationSource?.Id.toString()))
    )
  })
}

export const filtersLocalStorageEffect =
  (key: string) =>
  ({
    setSelf,
    onSet,
  }: {
    setSelf: (value: IFilterState) => void
    onSet: (callback: (newValue: IFilterState, _: unknown, isReset: boolean) => void) => void
  }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: IFilterState, _: unknown, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const ActiveManageTabState = atom<number>({
  key: 'current_active_tab_manage_page',
  default: TabsEnum.APPROVALS,
})

export const ManageSearchState = atom<string>({
  key: 'manage_search_state',
  default: '',
})

export const FetchMyContributions = selector({
  key: 'fetch_automation_my_contributions',
  get: async () => {
    try {
      const results = await getMyContributions({})
      return ResponseData(results)
    } catch (error) {
      return []
    }
  },
})

export const FetchPendingApprovalList = selector({
  key: 'fetch_automation_pending_approval_list',
  get: async () => {
    try {
      const results = await getManageAutomationsTableRows({})
      return ResponseData(results)
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const SearchText = atom<string>({
  key: 'search_text',
  default: '',
})

export const SearchAutomationCriteria = atom<ISearchAutomationCriteria>({
  key: 'search_automation_criteria',
  default: undefined,
})

export const FetchDeactivatedAutomations = selector({
  key: 'fetch_deactivated_automations',
  get: async () => {
    try {
      const results = await getDeactivatedAutomations({})
      return ResponseData(results)
    } catch (error) {
      return []
    }
  },
})

export const ManageAutomationFilters = atom({
  key: 'manage_automation_filters',
  default: {
    [TagTypes.SOURCE]: [] as IDropdownItemProps[],
    [TagTypes.CAPABILITY]: [] as IDropdownItemProps[],
    [TagTypes.PRACTICE]: [] as IDropdownItemProps[],
    [TagTypes.REGION]: [] as IDropdownItemProps[],
  },
})

export const MyContributions = selector({
  key: 'automation_my_contributions',
  get: async ({ get }) => {
    const automationList = get(FetchMyContributions)
    const search = get(ManageSearchState)
    const manageFilters = get(ManageAutomationFilters)

    return filterResults({
      automationList,
      search,
      practicesFilter: manageFilters.practice,
      sourcesFilter: manageFilters.source,
      capabilitiesFilter: manageFilters.capability,
      regionsFilter: manageFilters.region,
    })
  },
})

export const PendingApprovalList = selector({
  key: 'automation_pending_approval_list',
  get: async ({ get }) => {
    const automationList = get(FetchPendingApprovalList)
    const search = get(ManageSearchState)
    const manageFilters = get(ManageAutomationFilters)

    return filterResults({
      automationList,
      search,
      practicesFilter: manageFilters.practice,
      sourcesFilter: manageFilters.source,
      capabilitiesFilter: manageFilters.capability,
      regionsFilter: manageFilters.region,
    })
  },
})

export const ApprovedAutomationsAndScripts = selector({
  key: 'approved_automations_and_scripts',
  get: async ({ get }) => {
    try {
      const data = get(SearchAutomationCriteria)
      const searchCriteria = {
        searchText: data?.searchText ?? '',
        currentPage: data?.currentPage ?? 1,
        filters: data?.filters ? JSON.stringify(data.filters) : '',
        includeATC: data?.includeATC ?? true,
        includeSL: data?.includeSL ?? true,
        includeStreamliner: data?.includeStreamliner ?? true,
      }
      const results = await postSearchAutomation(searchCriteria)
      const searchResult = ResponseData(results) as IAutomationSearchResponse
      const sortedAutomations = [...searchResult.items]?.sort(
        (a, b) => new Date(b.VerificationDate).getTime() - new Date(a.VerificationDate).getTime(),
      )
      const approvedAutomations = { ...searchResult, items: sortedAutomations }
      return approvedAutomations
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const AutomationSearchResultCount = atom({
  key: 'search_result_count',
  default: {
    atcTotalCount: 0,
    scriptLibraryTotalCount: 0,
    streamlinerTotalCount: 0,
  } as ISearchAutomationCount,
})

export const ManagePageCount = atom<number>({
  key: 'automation_manage_page_count',
  default: 1,
})

export const DeactivatedAutomations = selector({
  key: 'automation_deactivated_list',
  get: async ({ get }) => {
    const automationList = get(FetchDeactivatedAutomations)
    const search = get(ManageSearchState)
    const manageFilters = get(ManageAutomationFilters)

    return filterResults({
      automationList,
      search,
      practicesFilter: manageFilters.practice,
      sourcesFilter: manageFilters.source,
      capabilitiesFilter: manageFilters.capability,
      regionsFilter: manageFilters.region,
    })
  },
})
