import { FC, useMemo, useState } from 'react'
import classNames from 'classnames'
import Style from '../styles/Menu.module.sass'
import { useLocation } from 'react-router-dom'
import { Button, FormInput, Icon, Loader, Pill } from '@aurecon-creative-technologies/styleguide'
import AutomationCenterLogo from '../assets/automation_center_logo.svg'
import AutomationCenterLogoSmall from '../assets/automation_center_logo_small.svg'
import config, {
  AUTOMATION_NETWORK_URL,
  FAQ_URL,
  RELEASE_NOTES_URL,
  SCRIPT_LIBRARY_URL,
  SUPPORT_HUB_URL,
  UI_WIDTH_COLLAPSE,
} from '../config/config'
import { useAuth0 } from '@auth0/auth0-react'
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilRefresher_UNSTABLE,
} from 'recoil'
import { AppVersion, HasDirtyForm, SearchCurrentPage } from '../stores/AppStore'
import { useMediaQuery } from 'react-responsive'
import { AppRoutes } from '../enums/AppRoutes'
import { FetchPendingApprovalList, SearchAutomationCriteria, SearchText } from '../stores/ManageAutomationStore'
import { isApproverUser } from '../helpers/appRoles'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import { AllSources } from '../stores/AutomationViewerStore'
import ConfirmModal from './ConfirmModal'
import ScriptLibraryLogo from '../assets/script_library_logo.svg'

const Menu: FC = () => {
  const loc = useLocation()
  const { logout } = useAuth0()
  const version = useRecoilValue(AppVersion)
  const [searchText, setSearchText] = useRecoilState(SearchText)
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const approvalsLoadable = useRecoilValueLoadable(FetchPendingApprovalList)
  const [searchAutomationCriteria, setSearchAutomationCriteria] = useRecoilState(SearchAutomationCriteria)
  const [hasDirtyForm, setHasDirtyForm] = useRecoilState(HasDirtyForm)
  const [nextLocationHash, setNextLocationHash] = useState<string>('')
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const refreshAutomationSources = useRecoilRefresher_UNSTABLE(AllSources)

  const { user } = useAuth0()
  const isApprover = isApproverUser(user)

  const canSearchAutomation = useUserPermission(actions.SEARCH_AUTOMATION)
  const canViewAutomationNetwork = useUserPermission(actions.VIEW_AUTOMATION_NETWORK_PAGE)
  const canViewReleaseNotesPage = useUserPermission(actions.VIEW_RELEASE_NOTE_PAGE)
  const canViewFAQPage = useUserPermission(actions.VIEW_FAQ_PAGE)
  const canViewProfilePage = useUserPermission(actions.VIEW_PROFILE_PAGE)
  const canUploadAutomation = useUserPermission(actions.UPLOAD_AUTOMATION)
  const canViewAllAutomationApprovals = useUserPermission(actions.VIEW_ALL_AUTOMATION_APPROVALS)
  const canViewAutomationApprovals = useUserPermission(actions.VIEW_APPROVAL_LIST)
  const canViewContributionList = useUserPermission(actions.VIEW_CONTRIBUTION_LIST)
  const canViewApplicationInsights = useUserPermission(actions.VIEW_APPLICATION_INSIGHTS)
  const canViewSettingsPage = useUserPermission(actions.VIEW_SETTINGS_PAGE)
  const setSearchCurrentPage = useSetRecoilState(SearchCurrentPage)

  const approvals = useMemo(() => {
    if (approvalsLoadable.state !== 'hasValue') return null
    const approvals = approvalsLoadable.contents
    const count = approvals?.filter((a) => !a.HasApprovalFromSameRole).length ?? 0
    return count > 99 ? '99+' : count
  }, [approvalsLoadable.contents, approvalsLoadable.state])

  const menuClasses = classNames({
    [Style.menu]: true,
    [Style.collapsed]: !isDesktop,
  })

  const discoverClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.DISCOVER}`),
  })

  const manageClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.MANAGE}`),
  })

  const uploadClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.UPLOAD_AUTOMATION}`),
  })

  const applicationInsightsClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.APPLICATION_INSIGHTS}`),
  })

  const profileClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.PROFILE}`),
  })

  const settingsClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.SETTINGS}`),
  })

  const staticMenuClasses = classNames({
    [Style.menuItem]: true,
  })

  const handleStaticMenuClick = (link: string) => {
    window.open(link, '_blank')
    setSearchText('')
  }

  const handleLocationChange = (locationValue: string) => {
    if (loc.pathname.includes(locationValue)) {
      return
    }

    if (loc.pathname.includes(`/${AppRoutes.SETTINGS}`) && hasDirtyForm) {
      setShowWarningModal(true)
      setNextLocationHash(locationValue)
    } else {
      loadNextLocation(locationValue)
    }
  }

  const loadNextLocation = (locationValue?: string) => {
    if (!locationValue && !nextLocationHash) {
      const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
      logout({ returnTo })
    } else {
      setShowWarningModal(false)
      setHasDirtyForm(false)
      refreshAutomationSources()
      location.hash = locationValue ?? nextLocationHash
    }
  }

  const handleSearch = (event: string) => {
    if (event === 'Enter') {
      location.hash = `#/${AppRoutes.DISCOVER}`
      setSearchAutomationCriteria({
        ...searchAutomationCriteria,
        searchText,
        currentPage: 1,
      })
      setSearchCurrentPage(1)
    }
  }

  const handleChange = (text: string) => {
    setSearchText(text)
    if (!text && loc.pathname.includes(`/${AppRoutes.SEARCH}`)) {
      setSearchAutomationCriteria({
        searchText: '',
        currentPage: 1,
      })
      setSearchCurrentPage(1)
    }
  }

  return (
    <>
      <div className={menuClasses}>
        <button
          className={Style.logoButton}
          onClick={() => {
            handleLocationChange(`#/`)
          }}
        >
          <img
            src={isDesktop ? AutomationCenterLogo : AutomationCenterLogoSmall}
            alt='Automation Center Logo'
            className={Style.automationCenterLogo}
          />
        </button>
        {isDesktop && canSearchAutomation && (
          <div className={Style.searchBox}>
            <FormInput
              type='text'
              placeholder={`Search`}
              onChange={(e) => handleChange(e)}
              value={searchText}
              onKeyDown={(e) => handleSearch(e)}
              cssClass={Style.searchInput}
            />
            <Icon className={Style.icon} type='search' outlined />
          </div>
        )}
        {canSearchAutomation && (
          <div
            className={discoverClasses}
            onClick={() => {
              handleLocationChange(`#/${AppRoutes.DISCOVER}`)
            }}
            role='none'
          >
            <Icon type='star_outline' outlined /> {isDesktop && <span>Discover</span>}
          </div>
        )}
        {(canViewAllAutomationApprovals || canViewAutomationApprovals || canViewContributionList) && (
          <div
            className={manageClasses}
            onClick={() => {
              handleLocationChange(`/${AppRoutes.MANAGE}`)
            }}
            role='none'
          >
            <Icon type='display_settings' outlined /> {isDesktop && <span>Manage</span>}
            {isApprover && approvals !== 0 && (canViewAutomationApprovals || canViewAllAutomationApprovals) && (
              <Pill size='small' cssClass={Style.approvalsPill}>
                {approvals ?? <Loader cssClass={Style.loader} size={'extra small'} />}
              </Pill>
            )}
          </div>
        )}
        {canUploadAutomation && (
          <div
            className={uploadClasses}
            onClick={() => {
              handleLocationChange(`/${AppRoutes.UPLOAD_AUTOMATION}`)
            }}
            role='none'
          >
            <Icon type='file_upload' outlined /> {isDesktop && <span>Upload</span>}
          </div>
        )}

        {canViewSettingsPage && (
          <div
            className={settingsClasses}
            onClick={() => {
              handleLocationChange(`/${AppRoutes.SETTINGS}`)
            }}
            role='none'
          >
            <Icon type='settings' outlined /> {isDesktop && <span>Settings</span>}
          </div>
        )}

        {canViewApplicationInsights && (
          <div
            className={applicationInsightsClasses}
            onClick={() => {
              handleLocationChange(`/${AppRoutes.APPLICATION_INSIGHTS}`)
            }}
            role='none'
          >
            <Icon type='analytics' outlined /> {isDesktop && <span>Application Insights</span>}
          </div>
        )}

        {canViewAutomationNetwork && (
          <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(AUTOMATION_NETWORK_URL)} role='none'>
            <Icon type='people_alt' outlined /> {isDesktop && <span>Automation Network</span>}
          </div>
        )}
        {canViewReleaseNotesPage && (
          <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(RELEASE_NOTES_URL)} role='none'>
            <Icon type='speaker_notes' outlined /> {isDesktop && <span>Release Notes</span>}
          </div>
        )}

        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(SCRIPT_LIBRARY_URL)} role='none'>
          <img src={ScriptLibraryLogo} alt='Script Library Logo' /> {isDesktop && <span>Script Library</span>}
        </div>

        {canViewFAQPage && (
          <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(FAQ_URL)} role='none'>
            <Icon type='quiz' outlined /> {isDesktop && <span>FAQ</span>}
          </div>
        )}
        {canViewProfilePage && (
          <div
            className={profileClasses}
            onClick={() => {
              handleLocationChange(`/${AppRoutes.PROFILE}`)
            }}
            role='none'
          >
            <Icon type='account_circle' outlined /> {isDesktop && <span>Profile</span>}
          </div>
        )}

        <div className={Style.menuFooter}>
          <div className={Style.contactUsBtnContainer}>
            <Button
              cssClass={Style.contactUsBtn}
              type='custom'
              size='extra small'
              label='Contact Us'
              onClick={() => handleStaticMenuClick(SUPPORT_HUB_URL)}
            />
          </div>
          <div className={Style.menuVersion}>{isDesktop ? `Automation Centre v${version}` : `v${version}`}</div>
          <div className={Style.legalLinks}>
            <a className={Style.link} href={`/#/${AppRoutes.TERMS_AND_CONDITION}`} target='_blank' rel='noreferrer'>
              {isDesktop ? 'Terms & Conditions' : 'T&C'}
            </a>
            {isDesktop && <span className={Style.separator}>|</span>}
            <a className={Style.link} href={`/#/${AppRoutes.PRIVACY}`} target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      <ConfirmModal
        title='Unsaved Changes'
        message='You have unsaved changes. Are you sure you want to leave this page?'
        onYes={() => {
          loadNextLocation()
        }}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false)
        }}
      />
    </>
  )
}

export default Menu
