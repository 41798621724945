import config, { AppRolesEnum, AuthoriserRoles } from '../config/config'
import { User } from '@auth0/auth0-react'

export const isApproverUser = (user: User | undefined): boolean => {
  if (!user?.[config.AUTH0_ROLES]) return false
  const userRoles = getUserRoles(user)
  const hasApproverRole = AuthoriserRoles.some((role) => userRoles.includes(role))
  return hasApproverRole
}

export const isUserSystemAdmin = (user: User | undefined): boolean => {
  if (!user?.[config.AUTH0_ROLES]) return false
  const userRoles = getUserRoles(user)
  return userRoles.includes(AppRolesEnum.SYSTEM_ADMIN)
}

export const getUserRoles = (user: User | undefined): string[] => {
  const userRoles = user?.[config.AUTH0_ROLES] ?? []
  const environmentRoles = [...Object.values(AppRolesEnum)]
  const matchingRoles = userRoles.filter((role: string) => environmentRoles.includes(role))
  if (matchingRoles.length === 0) userRoles.push(AppRolesEnum.STANDARD_USER)

  return userRoles
}
