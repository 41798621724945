import { atom, selector, selectorFamily } from 'recoil'
import {
  getAppFlags,
  getAppVersion,
  getSubmissionInsights,
  getDownloadsInsights,
  getUniqueUsers,
  getUniqueUsersByCountry,
  getPublishedInsights,
} from '../api/AppService'
import { TagTypes } from '../enums/TagTypes'
import { IDateInputDates, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { IUser } from '../models/IUser'
import { IUniqueUsersParams, IUniqueUsersResponse } from '../models/api/IApplicationInsights'
import { ResponseData } from '../models/api/IResponse'
import { LAUNCH_DATE } from '../config/config'

export const AppVersion = atom({
  key: 'version',
  default: '0.0.0',
  effects: [
    ({ setSelf }) => {
      getAppVersion().then((response) => {
        setSelf(response.data?.version ?? '0.0.0')
      })
    },
  ],
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const SearchValue = atom({
  key: 'search_value',
  default: '',
})

export const AutoSearchValue = atom({
  key: 'auto_search_value',
  default: '',
})

export const ShowErrorModal = atom<boolean>({
  key: 'show_error_modal',
  default: false,
})

export const SearchAutomationFilters = atom({
  key: 'search_automation_filters',
  default: {
    [TagTypes.SOURCE]: [] as IDropdownItemProps[],
    [TagTypes.CAPABILITY]: [] as IDropdownItemProps[],
    [TagTypes.PRACTICE]: [] as IDropdownItemProps[],
    [TagTypes.REGION]: [] as IDropdownItemProps[],
  },
})

export const CurrentUser = atom<IUser | null>({
  key: 'current_user',
  default: null,
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = await getAppFlags()
    return response.data || {}
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const SubmissionsInsights = selector({
  key: 'app_submission_insights',
  get: async () => {
    const response = await getSubmissionInsights({})
    return response?.data || { automationCentre: [], scriptLibrary: [], totalCount: 0 }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const DownloadInsights = selector({
  key: 'app_download_insights',
  get: async () => {
    const response = await getDownloadsInsights({})
    return response?.data || { automationCentre: [], scriptLibrary: [], totalCount: 0 }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const UniqueUsersInsights = selectorFamily<IUniqueUsersResponse | null, IUniqueUsersParams>({
  key: 'app_unique_users',
  get:
    ({ startDate, endDate }) =>
    async () => {
      try {
        const results = await getUniqueUsers({ startDate, endDate })
        return ResponseData(results)
      } catch (error) {
        return null
      }
    },
})

export const UniqueUsersDateRange = atom<IDateInputDates>({
  key: 'unique_users_date_range',
  default: {
    startDate: new Date(LAUNCH_DATE),
    endDate: new Date(),
  },
})

export const UniqueUsersByCountryInsights = selector({
  key: 'app_unique_users_by_country',
  get: async ({ get }) => {
    const dateRange = get(UniqueUsersDateRange)

    const response = await getUniqueUsersByCountry({
      startDate: dateRange.startDate?.toISOString() || null,
      endDate: dateRange.endDate?.toISOString() || null,
    })

    return response?.data || []
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const PublishedInsights = selector({
  key: 'app_published_insights',
  get: async () => {
    const response = await getPublishedInsights({})
    return response?.data || null
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const SearchCurrentPage = atom<number>({
  key: 'search_current_page',
  default: 1,
})

export const HasDirtyForm = atom<boolean>({
  key: 'has_dirty_form',
  default: false,
})
