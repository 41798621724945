import { FC } from 'react'

import Style from '../styles/HttpErrorScreen.module.sass'
import { Button, Container } from '@aurecon-creative-technologies/styleguide'
import AureconLogo from '../assets/aurecon_logo.png'
import AureconGrey from '../assets/aurecon_logo_grey.svg'
import AutomationCentreLogo from '../assets/automation_center_logo.svg'

interface IHttpErrorScreenProps {
  httpErrorCode: string
  httpErrorLabel: string
  errorline1: string
  errorline2?: string
}
const HttpErrorScreen: FC<IHttpErrorScreenProps> = ({ httpErrorCode, httpErrorLabel, errorline1, errorline2 }) => {
  const handleHomeClick = () => {
    window.location.replace('/')
  }

  return (
    <Container fluid cssClass={Style.container}>
      <div className={Style.aureconLogo}>
        <img src={AureconLogo} alt='Aurecon Logo' />
      </div>
      <div className={Style.contents}>
        <img src={AutomationCentreLogo} alt='Automation Centre Logo' />
        <h1 className={Style.errorCode}>{httpErrorCode}</h1>
        <h1 className={Style.errorLabel}>{httpErrorLabel}</h1>
        <p className={Style.line1}>{errorline1}</p>
        <p className={Style.line2}>{errorline2}</p>
        <Button type='custom' icon='home' label='Take Me Home' onClick={handleHomeClick} cssClass={Style.homeBtn} />
      </div>
      <img src={AureconGrey} alt='aurecon' className={Style.logoBackground} />
    </Container>
  )
}

export default HttpErrorScreen
