import { FC, useState, useEffect } from 'react'
import Style from '../styles/Search.module.sass'
import { appInsights } from '../api/AppInsights'
import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import { useParams } from 'react-router-dom'
import Page from '../components/Page'
import classNames from 'classnames'
import SearchAutomationCard from '../components/SearchAutomationCard'
import { IGetAutomationResponse, ISearchAutomationFilters } from '../models/api/IAutomationRequest'
import LoadingScreen from '../components/LoadingScreen'
import { SearchAutomationFilters, SearchCurrentPage } from '../stores/AppStore'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import {
  ApprovedAutomationsAndScripts,
  AutomationSearchResultCount,
  SearchAutomationCriteria,
} from '../stores/ManageAutomationStore'
import AutomationFilter from '../components/AutomationFilter'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import { SEARCH_PAGE_SIZE } from '../config/config'
import ErrorPage from './ErrorPage'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'

const Search: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Search' })
  const { searchText } = useParams<{ searchText: string }>()
  const [searchAutomationFilters] = useRecoilState(SearchAutomationFilters)
  const [searchResultCount, setSearchResultCount] = useRecoilState(AutomationSearchResultCount)
  const [searchAutomationCriteria, setSearchAutomationCriteria] = useRecoilState(SearchAutomationCriteria)
  const approvedAutomationScriptsLoader = useRecoilValueLoadable(ApprovedAutomationsAndScripts)
  const [automations, setAutomations] = useState<IGetAutomationResponse[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchCurrentPage, setSearchCurrentPage] = useRecoilState(SearchCurrentPage)

  const canViewSearchPage = useUserPermission(actions.SEARCH_AUTOMATION)
  const hasNextPage =
    searchResultCount.atcTotalCount >= SEARCH_PAGE_SIZE ||
    searchResultCount.scriptLibraryTotalCount >= SEARCH_PAGE_SIZE ||
    searchResultCount.streamlinerTotalCount >= SEARCH_PAGE_SIZE

  useEffect(() => {
    if (approvedAutomationScriptsLoader.state === 'loading') {
      setLoading(true)
      return
    }
    if (approvedAutomationScriptsLoader.state !== 'hasValue' || !approvedAutomationScriptsLoader.contents) return
    setLoading(false)
    if (!Array.isArray(approvedAutomationScriptsLoader.contents)) {
      const { items, atcTotalCount, scriptLibraryTotalCount, streamlinerTotalCount } =
        approvedAutomationScriptsLoader.contents
      setAutomations(items)
      setSearchResultCount({
        atcTotalCount: atcTotalCount ?? 0,
        scriptLibraryTotalCount: scriptLibraryTotalCount ?? 0,
        streamlinerTotalCount: streamlinerTotalCount ?? 0,
      })
    }
  }, [approvedAutomationScriptsLoader.contents, approvedAutomationScriptsLoader.state, setSearchResultCount])

  const cardClasses = classNames({
    [Style.sectionDefault]: automations.length < 4,
    [Style.section]: automations.length >= 4,
  })

  const handleNextPage = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      currentPage: searchCurrentPage + 1,
      includeATC: searchResultCount.atcTotalCount >= SEARCH_PAGE_SIZE,
      includeSL: searchResultCount.scriptLibraryTotalCount >= SEARCH_PAGE_SIZE,
      includeStreamliner: searchResultCount.streamlinerTotalCount >= SEARCH_PAGE_SIZE,
    })
    setSearchCurrentPage(searchCurrentPage + 1)
  }

  const handlePreviousPage = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      currentPage: searchCurrentPage - 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(searchCurrentPage - 1)
  }

  const handleApplyFilter = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      filters: searchAutomationFilters as ISearchAutomationFilters,
      currentPage: 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(1)
  }

  const handleClearFilter = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      filters: undefined,
      currentPage: 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(1)
  }

  if (!canViewSearchPage) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  return (
    <Page menu>
      <Container cssClass={Style.layoutContainer} fluid>
        <header className={Style.hero}>
          {searchText ? <h2>Results for "{searchText}"</h2> : <h2>Discover automations</h2>}
        </header>
        <AutomationFilter
          cssClass={Style.filters}
          filterState={SearchAutomationFilters}
          showApplyButton={true}
          handleApplyFilter={handleApplyFilter}
          handleClearFilter={handleClearFilter}
        />
        {loading && (
          <div className={Style.loadingContainer}>
            <LoadingScreen size='extra small' text={searchText ? 'Loading search results' : 'Loading automations'} />
          </div>
        )}
        {!loading && automations.length > 0 && (
          <>
            <Grid row xs={12} gap={12} cssClass={cardClasses}>
              {automations.map((automation) => (
                <SearchAutomationCard automation={automation} key={automation.Id} />
              ))}
            </Grid>
            <div className={Style.sectionTitle}>
              <div className={Style.pageReference}>
                <p>Page {searchCurrentPage}</p>
              </div>
              <div className={Style.pageButtonsContainer}>
                <Button
                  onClick={handlePreviousPage}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_arrow_left'
                  disabled={searchCurrentPage === 1}
                  cssClass={Style.pageButtons}
                />
                <Button
                  onClick={handleNextPage}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_arrow_right'
                  disabled={!hasNextPage}
                  cssClass={Style.pageButtons}
                />
              </div>
            </div>
          </>
        )}
        {!loading && automations.length === 0 && (
          <div className={Style.loadingContainer}>
            <p className={Style.noResultText}>There are no results that match your search</p>
          </div>
        )}
      </Container>
    </Page>
  )
}

export default Search
