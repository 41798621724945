import { AppRolesEnum } from '../config/config'

export const AppRoleName = {
  [AppRolesEnum.TECHNICAL]: 'Technical',
  [AppRolesEnum.IT]: 'IT',
  [AppRolesEnum.DIGITAL]: 'Digital',
}

export const ApproverRoleName = {
  TECHNICAL: 'Technical',
  IT: 'IT',
  DIGITAL: 'Digital',
}

export const AppRoles = {
  SystemAdmin: AppRolesEnum.SYSTEM_ADMIN,
  TechnicalApprover: AppRolesEnum.TECHNICAL,
  ITApprover: AppRolesEnum.IT,
  DigitalApprover: AppRolesEnum.DIGITAL,
  StandardUser: AppRolesEnum.STANDARD_USER,
}

export const AppRoleLabel = {
  [AppRoles.SystemAdmin]: 'System Admin',
  [AppRoles.ITApprover]: 'IT Authoriser',
  [AppRoles.DigitalApprover]: 'Digital Authoriser',
  [AppRoles.TechnicalApprover]: 'Technical Authoriser',
  [AppRoles.StandardUser]: 'User',
}
