import { FC, ReactNode } from 'react'
import { Grid, IButtonProps, Icon, Modal } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/CommonModals.module.sass'
import classNames from 'classnames'

interface ConfirmModalProps {
  title: string
  message: string | ReactNode
  open: boolean
  loading?: boolean
  onYes?: () => void
  onNo?: () => void
  onClose: () => void
  labelYes?: string
  labelClose?: string
  hideYes?: boolean
  size?: 'small' | 'medium' | 'large'
  isCloseButton?: boolean
  cssClass?: string
  titleIcon?: string
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const actions: IButtonProps[] = []

  if (!props.hideYes) {
    actions.push({
      type: 'secondary',
      label: props.labelYes ?? 'Yes',
      cssClass: Style.secondaryButton,
      loading: props.loading,
      disabled: props.loading,
      onClick: props?.onYes,
    })
  }

  actions.push({
    type: 'primary',
    label: props.labelClose ?? 'No',
    disabled: props.loading,
    cssClass: Style.primaryButton,
    onClick: () => (props.onNo ? props.onNo() : props.onClose()),
  })

  return (
    <Modal
      isShowing={props.open}
      shouldOverlayClose={false}
      isOverlay
      isCloseButton={props.isCloseButton}
      modalPadding={{ top: '24px', right: '24px', left: '24px', bottom: '24px' }}
      onClose={props.onClose}
      actions={actions}
      cssClass={classNames(Style.commonModal, props?.cssClass)}
      size={props.size ?? 'medium'}
    >
      <Grid row cssClass={Style.modalContainer}>
        <Grid item xs={12} cell>
          <h2>
            {props.titleIcon && <Icon type={props.titleIcon} className={Style.confirmModalIcon} />}
            {props.title}
          </h2>
          <span className={Style.message}>{props.message}</span>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmModal
