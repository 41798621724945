import { FC, useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Loader } from '@aurecon-creative-technologies/styleguide'
import { LINE_CHART_COLOUR } from '../../config/config'

export interface ILineChartData {
  name: string
  value: number
}
interface LineChartProps {
  isLoading?: boolean
  lineData?: ILineChartData[]
}

const AppInsightsLineChart: FC<LineChartProps> = (props) => {
  const { isLoading, lineData } = props
  const [maxValue, setMaxValue] = useState(0)

  useEffect(() => {
    if (isLoading || !lineData) return
    const maxData = Math.max(...lineData.map((item) => item.value))
    setMaxValue(Math.ceil(maxData * 1.1))
  }, [isLoading, lineData])

  if (isLoading)
    return (
      <div className={PageStyle.loaderContainer}>
        <Loader size='small' />
      </div>
    )

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        width={500}
        height={300}
        data={lineData}
        margin={{
          top: 5,
          right: 50,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray='0 0' />
        <XAxis dataKey='name' />
        <YAxis domain={[0, maxValue]} />
        <Tooltip />
        <Legend />
        <Line dataKey='value' stroke={LINE_CHART_COLOUR} strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default AppInsightsLineChart
