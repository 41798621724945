import { FC, useEffect, useState } from 'react'
import FeatureImagePlaceholder from '../assets/featured_card1.png'
import { ITagProps } from './Tag'
import { useMediaQuery } from 'react-responsive'
import { BLOB_FEATURED_CONTAINER, UI_WIDTH_COLLAPSE } from '../config/config'
import { renderTags } from './AutomationCard'
import Likes from './Likes'

import Style from '../styles/FeaturedCard.module.sass'
import { getAutomationAssets } from '../api/StorageService'
import { Loader } from '@aurecon-creative-technologies/styleguide'

export interface IFeaturedCardProps {
  title: string
  id: string
  creationDate: string
  description: string
  tags: ITagProps[]
  onClick?: () => void
  thumbnail?: string | null
  thumbnailFile?: File | null
  inSettings?: boolean
}

const FeaturedCard: FC<IFeaturedCardProps> = (props) => {
  const { title, id, creationDate, description, tags, thumbnail, onClick, inSettings, thumbnailFile } = props
  const [thumbnailUrl, setThumbnailUrl] = useState<string>()
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false)

  useEffect(() => {
    const getThumbnail = async () => {
      if (!thumbnail) {
        setThumbnailUrl(FeatureImagePlaceholder)
        return
      }
      setIsThumbnailLoading(true)
      const url = await getAutomationAssets({
        container: BLOB_FEATURED_CONTAINER,
        blobName: thumbnail,
      })

      setThumbnailUrl(url ?? FeatureImagePlaceholder)

      setIsThumbnailLoading(false)
    }

    if (inSettings) {
      if (thumbnailFile && thumbnailFile.size !== 0) setThumbnailUrl(URL.createObjectURL(thumbnailFile))
      else getThumbnail()
      return
    }

    if (thumbnail) getThumbnail()
    else setThumbnailUrl(FeatureImagePlaceholder)
  }, [inSettings, thumbnail, thumbnailFile])

  return (
    <div className={Style.featuredCard} onClick={onClick} role='none'>
      <div className={Style.details}>
        <div className={Style.featuredTag}>Featured</div>
        <div className={Style.title}>{title}</div>
        <div className={Style.metadata}>
          <Likes id={id} isDisabled={inSettings} />
          <div className={Style.creationDate}>{creationDate}</div>
        </div>
        <div className={Style.description}>{description}</div>

        <div className={Style.tags}>{renderTags(tags, isDesktop)}</div>
      </div>

      <div className={Style.thumbnail}>
        {isThumbnailLoading ? (
          <Loader cssClass={Style.loader} size='small' />
        ) : (
          <img alt='img' src={thumbnailUrl ?? FeatureImagePlaceholder} />
        )}
      </div>
    </div>
  )
}

export default FeaturedCard
