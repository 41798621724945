import { getMediaBlob } from '../api/StorageService'
import { BLOB_MACROS_CONTAINER, BLOB_OFFICE_SCRIPTS_CONTAINER } from '../config/config'
import { AutomationSourcesID } from '../enums/AutomationSources'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { ValidationError } from 'joi'
import { appInsights } from '../api/AppInsights'
import { IUser } from '../models/IUser'
import { AutomationStates } from '../enums/AutomationStates'

export const handleDownload = async (automation: IGetAutomationResponse, user: IUser | null) => {
  const automationFile = automation.AutomationFileUpload
  const source = automation.AutomationSourceId
  const container = source === AutomationSourcesID.MACRO ? BLOB_MACROS_CONTAINER : BLOB_OFFICE_SCRIPTS_CONTAINER

  if (!automationFile) {
    return
  }

  const response = await getMediaBlob({
    container,
    automationId: automationFile.AutomationId,
    blobName: automationFile.FileUri,
  })

  if (response?.data) {
    const url = window.URL.createObjectURL(response.data)
    const a = document.createElement('a')
    a.href = url
    a.download = automationFile.OriginalFileName
    a.click()
  }

  if (!appInsights || !user || automation.AutomationStateId !== AutomationStates.APPROVED) return
  const appInsightProps = {
    name: 'Download Automations',
    properties: {
      id: automation.Id,
      title: automation.Title,
      user,
      application: 'Automation Centre',
    },
  }

  appInsights.trackEvent(appInsightProps)
}

export const formatValidationResult = (error: ValidationError | undefined) => {
  if (!error) return
  const formattedErrors: { [key: string]: string } = {}
  error.details.flatMap((detail) => {
    const key = detail.path.join('.')
    formattedErrors[key] = detail.message.replace(/"/g, '')
  })

  return formattedErrors
}

export const markdownLinkify = (text: string) => {
  const linkifiedText = []
  let currentText = ''
  let linkText = ''
  let linkUrl = ''

  for (const char of text) {
    if (char === '[') {
      if (currentText) {
        linkifiedText.push(currentText)
        currentText = ''
      }
    } else if (char === ']') {
      linkText = currentText
      currentText = ''
    } else if (char === '(') {
      if (linkText) {
        linkUrl = ''
      }
    } else if (char === ')') {
      if (linkUrl) {
        linkifiedText.push(
          <a href={linkUrl} target='_blank' rel='noreferrer'>
            {linkText}
          </a>,
        )
        linkText = ''
        linkUrl = ''
      }
    } else if (linkText) {
      linkUrl += char
    } else {
      currentText += char
    }
  }

  if (currentText) {
    linkifiedText.push(currentText)
  }

  return <>{linkifiedText}</>
}
