import FeaturedItemDraggable, { isItemData } from './FeaturedItemDraggable'
import Style from '../../styles/common/modals/ReorderFeaturedModal.module.sass'
import { IFeaturedAutomationResponse } from '../../models/api/IFeaturedAutomations'
import { FC, useEffect } from 'react'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash'
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge'
import { flushSync } from 'react-dom'

type TFeaturedListProps = {
  listItems: IFeaturedAutomationResponse[]
  setListItems: (items: IFeaturedAutomationResponse[]) => void
  setIsChanged: (isChanged: boolean) => void
}

const FeaturedList: FC<TFeaturedListProps> = (props) => {
  const { listItems, setListItems, setIsChanged } = props
  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isItemData(source.data)
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0]
        if (!target) {
          return
        }

        const sourceData = source.data
        const targetData = target.data

        if (!isItemData(sourceData) || !isItemData(targetData)) {
          return
        }

        const indexOfSource = listItems.findIndex((item) => item.Id === sourceData.Id)
        const indexOfTarget = listItems.findIndex((item) => item.Id === targetData.Id)

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData)

        flushSync(() => {
          setIsChanged(true)
          setListItems(
            reorderWithEdge({
              list: listItems,
              startIndex: indexOfSource,
              indexOfTarget,
              closestEdgeOfTarget,
              axis: 'vertical',
            }),
          )
        })

        const element = document.querySelector(`[data-task-id="${sourceData.id}"]`)
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element)
        }
      },
    })
  }, [listItems, setIsChanged, setListItems])

  return (
    <div className={Style.draggableContainer}>
      {listItems.map((item, index) => (
        <FeaturedItemDraggable key={item.Id} item={item} index={index} />
      ))}
    </div>
  )
}

export default FeaturedList
