import React, { useCallback } from 'react'
import { htmlEntitiesParser, ID_NAME_REGEX, indexKey, URL_MATCHER_REGEX, URL_SPLITTER_REGEX } from '../../helpers/utils'
import Style from '../../styles/Comments.module.sass'

interface LinkifyProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string
}

const Linkify: React.FC<LinkifyProps> = ({ text, className }) => {
  const formatMentionedUsers = useCallback((commentText: string) => {
    const idNameRegex = ID_NAME_REGEX
    const formattedText = commentText.split(idNameRegex).map((part, index) => {
      if (index % 3 === 0) {
        return part
      } else if (index % 3 === 1) {
        const id = part
        const name = commentText.split(idNameRegex)[index + 1]
        return (
          <span key={id} id={id} className={Style.mention}>
            {name}
          </span>
        )
      } else {
        return null
      }
    })

    return formattedText
  }, [])

  const parsedText = htmlEntitiesParser(text)
  if (!parsedText) {
    return <span className={className}>{text}</span>
  }

  const textParts = parsedText.split(URL_SPLITTER_REGEX)
  return (
    <div className={className}>
      {textParts.map((part) => {
        const isURL = URL_MATCHER_REGEX.test(part)
        return isURL ? (
          <a key={indexKey()} href={part} target='_blank' rel='noopener noreferrer'>
            {part}
          </a>
        ) : (
          formatMentionedUsers(part)
        )
      })}
    </div>
  )
}

export default Linkify
