import React, { useCallback, useEffect, useRef, useState } from 'react'
import Style from '../../styles/common/modals/ReorderFeaturedModal.module.sass'
import { Button, Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { IFeaturedAutomationResponse } from '../../models/api/IFeaturedAutomations'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { attachClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import invariant from 'tiny-invariant'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { AutomationUploadTypes } from '../../enums/AutomationUploadTypes'
import { AppRoutes } from '../../enums/AppRoutes'
interface FeaturedItemDraggableProps {
  item: IFeaturedAutomationResponse
  index: number
}

export type DraggableState = { type: 'idle' } | { type: 'preview'; container: HTMLElement } | { type: 'dragging' }
export const idleState: DraggableState = { type: 'idle' }
export const draggingState: DraggableState = { type: 'dragging' }

export const itemDataKey = Symbol('itemData')

const getItemData = (item: IFeaturedAutomationResponse, index: number) => {
  return { [itemDataKey]: true, ...item, index }
}

export function isItemData(data: Record<string | symbol, unknown>) {
  return data[itemDataKey] === true
}

function DragPreview({ item }: { readonly item: IFeaturedAutomationResponse }) {
  return (
    <div className={Style.dragPreview}>
      <Icon type='drag_indicator' outlined className={Style.dragIndicator} />
      <span className={Style.featuredTitle}>{item.Title}</span>
      <Button type='icon-round' size='extra small' icon='open_in_new' cssClass={Style.openButton} />
    </div>
  )
}

const FeaturedItemDraggable: React.FC<FeaturedItemDraggableProps> = ({ item, index }) => {
  const featuredItemRef = useRef(null)
  const [draggableState, setDraggableState] = useState<DraggableState>(idleState)

  useEffect(() => {
    const element = featuredItemRef.current
    invariant(element)

    return combine(
      draggable({
        element,
        getInitialData: () => getItemData(item, index),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: '5px',
              y: '5px',
            }),
            render({ container }) {
              setDraggableState({ type: 'preview', container })
            },
          })
        },
        onDragStart: () => setDraggableState(draggingState),
        onDrop: () => setDraggableState(idleState),
      }),
      dropTargetForElements({
        element: element,
        canDrop: ({ source }) => {
          if (source.element === element) {
            return false
          }

          return isItemData(source.data)
        },
        getData: ({ input, element }) => {
          const data = getItemData(item, index)
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky() {
          return true
        },
        onDragLeave() {
          setDraggableState(idleState)
        },
        onDrop() {
          setDraggableState(idleState)
        },
      }),
    )
  }, [index, item, item.Id])

  const handleOpen = useCallback(() => {
    const { Id, UploadTypeId } = item
    if (UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${Id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`, '_blank')
      return
    }
    if (UploadTypeId === AutomationUploadTypes.STREAMLINER) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${Id}/${AutomationUploadTypes.STREAMLINER}`, '_blank')
      return
    }

    window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${Id}`, '_blank')
  }, [item])

  const draggableContainerClass = classNames({
    [Style.featuredItemContainer]: true,
    [Style.dragging]: draggableState === draggingState,
  })

  return (
    <Grid key={item.Id} item xs={12} cssClass={Style.featuredItem}>
      <div ref={featuredItemRef} className={draggableContainerClass}>
        <Icon type='drag_indicator' outlined className={Style.dragIndicator} />
        <span className={Style.featuredTitle}>{item.Title}</span>
        <Button
          type='icon-round'
          size='extra small'
          icon='open_in_new'
          cssClass={Style.openButton}
          onClick={handleOpen}
        />
      </div>
      {draggableState.type === 'preview' ? createPortal(<DragPreview item={item} />, draggableState.container) : null}
    </Grid>
  )
}

export default FeaturedItemDraggable
