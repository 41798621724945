import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { IAutomationLike } from '../models/api/IManageAutomationTableRow'
import { postlikeAutomation } from '../api/AutomationService'
import { AutomationLikes } from '../stores/AutomationViewerStore'
import { CurrentUser } from '../stores/AppStore'
import { ReactComponent as ThumbUp } from '../assets/thumb_up.svg'
import { ReactComponent as ThumbUpLiked } from '../assets/thumb_up_liked.svg'
import { Tooltip } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/Likes.module.sass'

export interface ILikesProps {
  id: string
  size?: string
  defaultUp?: boolean
  isDisabled?: boolean
}

const Likes: FC<ILikesProps> = (props) => {
  const { id, size = '18px', defaultUp = true, isDisabled } = props

  const currentUser = useRecoilValue(CurrentUser)

  const automationLikesLoader = useRecoilValueLoadable(AutomationLikes({ id }))
  const refreshLikes = useRecoilRefresher_UNSTABLE(AutomationLikes({ id }))
  const [automationLikes, setAutomationLikes] = useState<IAutomationLike[]>([])

  const [liked, setLiked] = useState<boolean>(false)

  useEffect(() => {
    const liked = automationLikes?.some((l) => l.LikedByUserId == currentUser?.AuthFederationId)

    setLiked(liked)
  }, [automationLikes, currentUser])

  useEffect(() => {
    if (automationLikesLoader.state === 'loading') return

    if (automationLikesLoader.state === 'hasValue') {
      setAutomationLikes(automationLikesLoader.contents ?? [])
      return
    }

    setAutomationLikes([])
  }, [automationLikesLoader])

  const handleLikeClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setLiked((l) => !l)
    await postlikeAutomation({ id })
    refreshLikes()
  }
  const handleLikeKeydown = async (e: React.KeyboardEvent) => {
    if (e.key === 'Space' || e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      setLiked((l) => !l)
      await postlikeAutomation({ id })
      refreshLikes()
    }
  }

  return (
    <div className={Style.likes}>
      <button
        onClick={handleLikeClick}
        onKeyDown={handleLikeKeydown}
        tabIndex={0}
        className={liked ? Style.liked : ''}
        disabled={isDisabled}
      >
        <Tooltip show={liked ? 'Unlike' : 'Like'} cssClass='tooltip-theme-dark' defaultUp={defaultUp}>
          {liked ? <ThumbUpLiked height={size} width={size} /> : <ThumbUp height={size} width={size} />}
        </Tooltip>
      </button>
      <div className={Style.numberOfLikes}>{automationLikes?.length || ''}</div>
    </div>
  )
}

export default Likes
