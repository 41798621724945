import { IKeyValue } from '../models/IKeyValue'

export const Practises: IKeyValue[] = [
  { key: '1', value: 'Asset Management' },
  { key: '2', value: 'Aviation' },
  { key: '3', value: 'Bridges & Civil Structures' },
  { key: '4', value: 'Buildings Defence' },
  { key: '5', value: 'Buildings Electrical' },
  { key: '6', value: 'Buildings Mechanical' },
  { key: '7', value: 'Buildings Structures' },
  { key: '8', value: 'Buildings Telco' },
  { key: '9', value: 'Delivery Management' },
  { key: '10', value: 'Digital' },
  { key: '11', value: 'Engagement & Change Advisory' },
  { key: '12', value: 'Environment & Planning' },
  { key: '13', value: 'Geospatial' },
  { key: '14', value: 'Ground & Underground Engineering' },
  { key: '15', value: 'Industrial EI&C' },
  { key: '16', value: 'Industrial Mechanical' },
  { key: '17', value: 'Industrial Structures' },
  { key: '18', value: 'Infrastructure Advisory' },
  { key: '19', value: 'Integrated Design' },
  { key: '20', value: 'People Movement & Place' },
  { key: '21', value: 'Land Infrastructure' },
  { key: '22', value: 'Materials' },
  { key: '23', value: 'Ports, Marine & Coastal' },
  { key: '24', value: 'Power Generation' },
  { key: '25', value: 'Power Transmission & Distribution' },
  { key: '26', value: 'Process Systems' },
  { key: '27', value: 'Program Performance' },
  { key: '28', value: 'Rail & Mass Transit' },
  { key: '29', value: 'Registered Training Org' },
  { key: '30', value: 'Roads' },
  { key: '31', value: 'Sustainability & Climate Change' },
  { key: '32', value: 'Water' },
  { key: '33', value: 'Water Resources' },
]
