import { Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useRef, useState } from 'react'
import Style from '../../styles/common/MentionTextArea.module.sass'
import { ContentState, EditorState, Modifier } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention'
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import { searchUsers } from '../../api/UserService'
import { addNewCommentFormSchema } from '../../validators/AddNewCommentFormValidator'
import { formatValidationResult } from '../../helpers/commonFunctions'

interface Props {
  limit: number
  editorState: EditorState
  isReply?: boolean
  setEditorState: Dispatch<SetStateAction<EditorState>>
  setFormErrors: Dispatch<SetStateAction<{ [key: string]: string }>>
  setIsFormActive: Dispatch<SetStateAction<boolean>>
  setTouched: Dispatch<SetStateAction<boolean>>
}

const Entry = (props: EntryComponentProps): ReactElement => {
  const { mention, theme, ...parentProps } = props

  return (
    <div {...parentProps} key={mention.id}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
      </div>
    </div>
  )
}

const MentionTextArea: React.FC<Props> = ({
  limit,
  editorState,
  isReply,
  setEditorState,
  setFormErrors,
  setIsFormActive,
  setTouched,
}) => {
  const editor = useRef<Editor>(null)
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState([] as MentionData[])
  const [loading, setLoading] = useState(false)

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: Style,
      supportWhitespace: true,
      mentionTrigger: '@',
    })
    const { MentionSuggestions } = mentionPlugin
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  const onChange = useCallback(
    (_editorState: EditorState) => {
      setEditorState(_editorState)
      const validationResult = addNewCommentFormSchema.validate(
        { comment: _editorState.getCurrentContent().getPlainText() },
        { abortEarly: false },
      )
      if (!validationResult.error) {
        setFormErrors({})
        return
      }

      const formattedErrors = formatValidationResult(validationResult.error)
      setFormErrors(formattedErrors ?? {})
    },
    [setEditorState, setFormErrors],
  )

  const handleBeforeInput = (input: string): 'handled' | 'not-handled' => {
    const currentContent = editorState.getCurrentContent()
    const currentLength = currentContent.getPlainText().length

    if (currentLength + input.length > limit) {
      return 'handled'
    }
    return 'not-handled'
  }

  const handlePastedText = (
    pastedText: string,
    html: string | undefined,
    editorState: EditorState,
  ): 'handled' | 'not-handled' => {
    const currentContent = editorState.getCurrentContent()
    const currentText = currentContent.getPlainText()
    const combinedText = currentText + pastedText

    if (combinedText.length > limit) {
      const allowedText = combinedText.substring(0, limit)

      const newContentState = ContentState.createFromText(allowedText)
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
      setEditorState(newEditorState)
    } else {
      const newContentState = Modifier.insertText(currentContent, editorState.getSelection(), pastedText)
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
      setEditorState(newEditorState)
    }

    return 'handled'
  }

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])

  const onSearchChange = useCallback(async ({ value }: { value: string }) => {
    setLoading(true)
    if (value.length < 3) {
      setLoading(false)
      return
    }
    const results = await searchUsers({ email: value })
    const mentions: MentionData[] =
      results?.data?.map((user) => ({
        id: user.auth0UserId,
        name: user.name ?? '',
      })) || []
    setLoading(false)
    setSuggestions(mentions)
  }, [])

  const focusEditor = () => {
    editor.current?.focus()
  }

  const handleOnBlur = () => {
    setTouched(true)
    setIsFormActive(false)
  }

  const limitCounter = useMemo(
    () => limit - editorState.getCurrentContent().getPlainText().length,
    [editorState, limit],
  )

  return (
    <div className={Style.mainContainer}>
      <div onClick={focusEditor} className={isReply ? Style.editorReply : Style.editor} role='none'>
        <Editor
          editorState={editorState}
          onChange={onChange}
          onFocus={() => setIsFormActive(true)}
          onBlur={handleOnBlur}
          plugins={plugins}
          ref={editor}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          entryComponent={Entry}
          renderEmptyPopup={loading}
        />
      </div>
      <div className={Style.characterCount}>{`${limitCounter} characters left`}</div>
    </div>
  )
}

export default MentionTextArea
