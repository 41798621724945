import { atom, selector, selectorFamily } from 'recoil'
import { ApproverModalActionTypes } from '../enums/AutomationViewer'
import { IAutomationSources, IEditHistory, IGetAutomationResponse } from '../models/api/IAutomationRequest'
import {
  getAutomation,
  getAutomationCommentHistory,
  getAutomationComments,
  getAutomationEditHistory,
  getAutomationLikes,
  getAutomationSources,
  getFeaturedAutomations,
  getFeaturedContent,
} from '../api/AutomationService'
import { ResponseData } from '../models/api/IResponse'
import { IAutomationLike } from '../models/api/IManageAutomationTableRow'
import { IAutomationCommentResponse, ICommentHistory } from '../models/api/IAutomationCommentModel'
import { ICommentItemProps } from '../components/AutomationViewer/comments/CommentItem'
import { IFeaturedAutomationResponse } from '../models/api/IFeaturedAutomations'

export interface IShowApproverModal {
  show: boolean
  type: number
}

export const ShowApproverModal = atom<IShowApproverModal>({
  key: 'show_approver_modal',
  default: { show: false, type: ApproverModalActionTypes.CLOSE },
})

export const ShowActionNotRequiredModal = atom<Pick<IShowApproverModal, 'show'>>({
  key: 'show_action_not_required_modal',
  default: { show: false },
})

export const CurrentAutomationType = atom<number>({
  key: 'current_automation_type',
  default: 0,
})

export const CurrentAutomationId = atom<string>({
  key: 'current_automation_id',
  default: '',
})

export const automationDetailsRefresh = atom({
  key: 'counterState',
  default: 0,
})

export const featuredDetailsRefresh = atom({
  key: 'featuredCounterState',
  default: 0,
})

export const AutomationCommentsRefresh = atom({
  key: 'automation_comments_refresh',
  default: 0,
})

export const AutomationEditHistoryRefresh = atom({
  key: 'automation_edit_history_refresh',
  default: 0,
})

export const AutomationCommentsHistoryRefresh = atom({
  key: 'automation_comments_history_refresh',
  default: 0,
})

export const ShowEditHistoryModal = atom({
  key: 'show_edit_history_modal',
  default: false,
})

export const ShowCommentsHistoryModal = atom({
  key: 'show_comments_history_modal',
  default: false,
})

type AutomationDetailsParams = {
  id: string
  typeId?: number
}

type AutomationLikesParams = {
  id: string
}

type IGetAutomationParams = {
  automationId: string
}

export const AutomationDetails = selectorFamily<IGetAutomationResponse | null, AutomationDetailsParams>({
  key: 'automation_details',
  get:
    ({ id, typeId }) =>
    async ({ get }) => {
      if (!id) return null
      get(automationDetailsRefresh)
      const results = await getAutomation({ id, typeId })
      if (!results?.success) {
        throw results
      }
      return ResponseData(results)
    },
})

export const AutomationLikes = selectorFamily<IAutomationLike[] | null, AutomationLikesParams>({
  key: 'automation_likes',
  get:
    ({ id }) =>
    async () => {
      try {
        if (!id) return null
        const results = await getAutomationLikes({ id })
        return ResponseData(results)
      } catch (error) {
        console.log(error)
        return null
      }
    },
})

export const AutomationComments = selectorFamily<IAutomationCommentResponse[] | null, IGetAutomationParams>({
  key: 'automation_comments',
  get:
    ({ automationId }) =>
    async ({ get }) => {
      try {
        if (!automationId) return null
        get(AutomationCommentsRefresh)
        const results = await getAutomationComments({ automationId })
        return ResponseData(results) as IAutomationCommentResponse[] | null
      } catch (error) {
        console.log(error)
        return null
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const AutomationCommentHistory = selectorFamily<ICommentHistory[] | null, IGetAutomationParams>({
  key: 'automation_comment_history',
  get:
    ({ automationId }) =>
    async ({ get }) => {
      try {
        if (!automationId) {
          return []
        }
        get(AutomationCommentsRefresh)
        const results = await getAutomationCommentHistory({ automationId })
        return ResponseData(results) as ICommentHistory[]
      } catch (error) {
        console.log(error)
        return null
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const AutomationCommentsAtom = atom<ICommentItemProps[]>({
  key: 'automation_comments',
  default: [],
})

export const EditHistory = selectorFamily<IEditHistory[] | null, IGetAutomationParams>({
  key: 'automation_edit_history',
  get:
    ({ automationId }) =>
    async ({ get }) => {
      try {
        if (!automationId) return null
        get(AutomationEditHistoryRefresh)
        const results = await getAutomationEditHistory({ automationId })
        return ResponseData(results)
      } catch (error) {
        console.log(error)
        return null
      }
    },
})

export const AllSources = selector({
  key: 'all_sources_loader',
  get: async (): Promise<IAutomationSources[]> => {
    try {
      const results = await getAutomationSources({})
      if (!results) return []
      const sources = ResponseData(results) as IAutomationSources[]
      return sources
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const AllFeaturedAutomations = selector({
  key: 'all_featured_loader',
  get: async (): Promise<IFeaturedAutomationResponse[]> => {
    try {
      const results = await getFeaturedAutomations({})
      if (!results) return []
      const data = ResponseData(results) as IFeaturedAutomationResponse[]
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const FeaturedContent = selectorFamily<string | null, AutomationDetailsParams>({
  key: 'featured_content_loader',
  get:
    ({ id }) =>
    async ({ get }) => {
      if (!id) return null
      get(featuredDetailsRefresh)
      const results = await getFeaturedContent({ id })
      if (!results?.success) {
        throw results
      }
      return ResponseData(results) as string
    },
})
