import { Button, IButtonProps, Loader, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/EditFeaturedModal.module.sass'
import { ShowEditBottomFeaturedModal, ShowReplaceAutomationModal } from '../../../stores/SettingsStore'
import FileUploader from '../../UploadAutomation/FileUploader'
import { IGetAutomationResponse } from '../../../models/api/IAutomationRequest'
import { imageFileTypes } from '../../../helpers/files'
import { FeaturedAutomationsEnum } from '../../../enums/FeaturedAutomationsEnum'
import { IFeaturedAutomations, IFeaturedDetails } from '../../../models/api/IFeaturedAutomations'
import JoditTextEditor from '../JoditTextEditor'
import { FeaturedContent } from '../../../stores/AutomationViewerStore'
import { getTextFromHTML } from '../../../helpers/utils'

export interface IBottomFeaturedModalProps {
  featuredData: IFeaturedAutomations
  onFeatureUpdate: (data: IFeaturedDetails) => void
  selectedFeaturedAutomation: number
  onCancel: () => void
  automationId?: string
}

const EditBottomFeaturedModal: FC<IBottomFeaturedModalProps> = (props) => {
  const { featuredData, onFeatureUpdate, selectedFeaturedAutomation, onCancel, automationId } = props
  const [showEditFeaturedModal, setShowEditFeaturedModal] = useRecoilState(ShowEditBottomFeaturedModal)
  const featuredContentLoader = useRecoilValueLoadable(FeaturedContent({ id: String(automationId) }))
  const setShowReplaceModal = useSetRecoilState(ShowReplaceAutomationModal)
  const [automation, setAutomation] = useState<IGetAutomationResponse>()
  const [selectedThumbnail, setSelectedThumbnail] = useState<File | null>(null)
  const [selectedBanner, setSelectedBanner] = useState<File | null>(null)
  const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState<string>()
  const [selectedBannerUrl, setSelectedBannerUrl] = useState<string>()
  const [editorContent, setEditorContent] = useState<string>('')

  const featuredLabel = selectedFeaturedAutomation === FeaturedAutomationsEnum.LEFT ? 'Left' : 'Right'

  useEffect(() => {
    const selectedFeatured =
      selectedFeaturedAutomation === FeaturedAutomationsEnum.LEFT ? featuredData.left : featuredData.right
    if (!selectedFeatured) return
    setAutomation(selectedFeatured.automation)
    setEditorContent(selectedFeatured.content ?? '')
    setSelectedBanner(selectedFeatured.bannerFile ?? null)
    setSelectedThumbnail(selectedFeatured.thumbnailFile ?? null)
    setSelectedThumbnailUrl(selectedFeatured.thumbnailUrl)
    setSelectedBannerUrl(selectedFeatured.bannerUrl)
  }, [featuredData, selectedFeaturedAutomation])

  useEffect(() => {
    if (featuredContentLoader.state === 'loading') return

    if (featuredContentLoader.state === 'hasValue') {
      setEditorContent(featuredContentLoader.contents ?? '')
    }
  }, [featuredContentLoader])

  const handleClose = () => {
    setShowEditFeaturedModal(false)
    onCancel()
  }

  const handleSave = () => {
    if (!automation) return
    onFeatureUpdate({
      automation,
      content: editorContent,
      bannerFile: selectedBanner,
      thumbnailFile: selectedThumbnail,
      thumbnailUrl: selectedThumbnailUrl,
      bannerUrl: selectedBannerUrl,
    })

    setShowEditFeaturedModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'secondary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Save',
      type: 'primary',
      onClick: handleSave,
      cssClass: Style.primaryButton,
      disabled: !selectedBanner || !selectedThumbnail || !getTextFromHTML(editorContent),
    },
  ]

  return (
    <Modal
      isShowing={showEditFeaturedModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.modalContainer}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.modalHeader}>
          <h2 className={Style.iconRow}>Edit {featuredLabel} Automation?</h2>
        </div>
        <div className={Style.modalContent}>
          <span>
            Update the thumbnail and message that will visually represent the featured automation on the home page.
          </span>
          <div className={Style.topContainer}>
            <div className={Style.detailsContainer}>
              <span className={Style.detailsLabel}>Automation Title</span>
              <span className={Style.detailsText}>{automation?.Title}</span>
            </div>
            <div className={Style.replaceButtonContainer}>
              <Button
                type='custom'
                cssClass={Style.primaryButton}
                label='Replace Automation'
                onClick={() => {
                  setShowReplaceModal(true)
                  setShowEditFeaturedModal(false)
                }}
              />
            </div>
          </div>
          <div className={Style.fileUploadContainer}>
            <div className={Style.dropboxContainer}>
              <div className={Style.dropbox}>
                <span className={Style.detailsLabel}>Upload {featuredLabel} Background *</span>
                <FileUploader
                  maxFiles={1}
                  allowedFileTypes={imageFileTypes}
                  selectedFiles={selectedBanner ? [selectedBanner] : []}
                  setSelectedFiles={(value) => {
                    setSelectedBanner(value[0])
                  }}
                  cancelUpload={() => {
                    setSelectedBanner(null)
                  }}
                  isDisabled={false}
                  automationSourceId={0}
                  showPreview={true}
                  fileUrl={selectedBannerUrl}
                />
              </div>
              <div className={Style.dropbox}>
                <span className={Style.detailsLabel}>Upload {featuredLabel} Thumbnail *</span>
                <FileUploader
                  maxFiles={1}
                  allowedFileTypes={imageFileTypes}
                  selectedFiles={selectedThumbnail ? [selectedThumbnail] : []}
                  setSelectedFiles={(value) => {
                    setSelectedThumbnail(value[0])
                  }}
                  cancelUpload={() => {
                    setSelectedThumbnail(null)
                  }}
                  isDisabled={false}
                  automationSourceId={0}
                  showPreview={true}
                  fileUrl={selectedThumbnailUrl}
                />
              </div>
            </div>
          </div>
          <div className={Style.featuredEditorContainer}>
            <span className={Style.detailsLabel}>Message *</span>
            <div className={Style.editorContainer}>
              {featuredContentLoader.state === 'loading' ? (
                <Loader />
              ) : (
                <JoditTextEditor content={editorContent} onChanged={(data) => setEditorContent(data)} />
              )}
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default EditBottomFeaturedModal
