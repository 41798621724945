export enum CapabilityEnum {
  Asset_Management_Performance = 1,
  Aviation = 2,
  Bridges_Civil_Structures = 3,
  Building_Electrical = 4,
  Building_Mechnical = 5,
  Building_Structures = 6,
  Digital = 7,
  Engagement_Change_Advisory = 8,
  Environment_Planning = 9,
  Geospatial = 10,
  Ground_Underground_Engineering = 11,
  Industrial_Systems = 12,
  Infrastructure_Advisory = 13,
  Integrated_Design = 14,
  People_Movement_Place = 15,
  Land_Infrastructure = 16,
  Ports_Marine_Coastal = 17,
  Power_Generation = 18,
  Power_Transmission_Distribution = 19,
  Process_Systems = 20,
  Program_Performance = 21,
  Rail_Mass_Transit = 22,
  Roads = 23,
  Sustainability_Climate_Change = 24,
  Water = 25,
  Information_Technology = 26,
  Admin_Other = 27,
}

export const CapabilityLabels = {
  [CapabilityEnum.Asset_Management_Performance]: 'Asset Management & Performance',
  [CapabilityEnum.Aviation]: 'Aviation',
  [CapabilityEnum.Bridges_Civil_Structures]: 'Bridges & Civil Structures',
  [CapabilityEnum.Building_Electrical]: 'Building Electrical',
  [CapabilityEnum.Building_Mechnical]: 'Building Mechanical',
  [CapabilityEnum.Building_Structures]: 'Building Structures',
  [CapabilityEnum.Digital]: 'Digital',
  [CapabilityEnum.Engagement_Change_Advisory]: 'Engagement & Change Advisory',
  [CapabilityEnum.Environment_Planning]: 'Environment & Planning',
  [CapabilityEnum.Geospatial]: 'Geospatial',
  [CapabilityEnum.Ground_Underground_Engineering]: 'Ground & Underground Engineering',
  [CapabilityEnum.Industrial_Systems]: 'Industrial Systems',
  [CapabilityEnum.Infrastructure_Advisory]: 'Infrastructure Advisory',
  [CapabilityEnum.Integrated_Design]: 'Integrated Design',
  [CapabilityEnum.People_Movement_Place]: 'People Movement & Place',
  [CapabilityEnum.Land_Infrastructure]: 'Land Infrastructure',
  [CapabilityEnum.Ports_Marine_Coastal]: 'Ports, Marine & Coastal',
  [CapabilityEnum.Power_Generation]: 'Power Generation',
  [CapabilityEnum.Power_Transmission_Distribution]: 'Power Transmission & Distribution',
  [CapabilityEnum.Process_Systems]: 'Process Systems',
  [CapabilityEnum.Program_Performance]: 'Program Performance',
  [CapabilityEnum.Rail_Mass_Transit]: 'Rail & Mass Transit',
  [CapabilityEnum.Sustainability_Climate_Change]: 'Sustainability & Climate Change',
  [CapabilityEnum.Water]: 'Water',
  [CapabilityEnum.Information_Technology]: 'Information Technology',
  [CapabilityEnum.Admin_Other]: 'Admin/Other',
}
