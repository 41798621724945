import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Icon, Tooltip } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/FileUploader.module.sass'
import { BLOB_FEATURED_CONTAINER, MAX_AUTOMATION_FILE_SIZE } from '../../config/config'

import {
  excelMacroTypes,
  microsoftExcelTypes,
  officeScriptTypes,
  powerpointMacroTypes,
  projectMacroTypes,
  publisherMacroTypes,
  visioMacroTypes,
  wordMacroTypes,
  imageFileTypes,
} from '../../helpers/files'
import { fileSize } from '../../helpers/fileSize'
import { indexKey } from '../../helpers/utils'
import { AutomationSourcesID } from '../../enums/AutomationSources'
import classNames from 'classnames'
import { getAutomationAssets } from '../../api/StorageService'

interface FileUploaderProps {
  allowedFileTypes: string[]
  maxFiles: number
  selectedFiles: File[]
  setSelectedFiles: (files: File[]) => void
  cancelUpload: () => void
  isDisabled: boolean
  automationSourceId: number
  showPreview?: boolean
  fileUrl?: string
}

const FileUploader: React.FC<FileUploaderProps> = ({
  allowedFileTypes,
  maxFiles,
  selectedFiles,
  isDisabled,
  automationSourceId,
  setSelectedFiles,
  cancelUpload,
  showPreview, // if file is an image
  fileUrl,
}) => {
  const [preview, setPreview] = useState<string | null>(null)

  useEffect(() => {
    const getPreview = async () => {
      if (!fileUrl) return
      const url = await getAutomationAssets({
        container: BLOB_FEATURED_CONTAINER,
        blobName: fileUrl,
      })

      if (url) setPreview(url)
    }

    if (selectedFiles[0]?.size === 0 && !fileUrl && showPreview) {
      setSelectedFiles([])
      return
    }
    if (showPreview && selectedFiles.length > 0) {
      if (selectedFiles[0].size === 0) getPreview()
      else setPreview(URL.createObjectURL(selectedFiles[0]))
    }
  }, [fileUrl, selectedFiles, setSelectedFiles, showPreview])

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles(acceptedFiles)
      if (showPreview) {
        setPreview(URL.createObjectURL(acceptedFiles[0]))
      }
    } else {
      cancelUpload()
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: showPreview ? { 'image/*': allowedFileTypes } : { 'application/office': allowedFileTypes },
    maxFiles,
  })

  const hasFiles = selectedFiles?.length > 0

  const fileUploadClasses = classNames({
    [Style.fileUploadContainer]: true,
    [Style.hasError]: !hasFiles,
  })

  const trashCanRow = () => {
    return (
      <div className={Style.trashIconRow}>
        <div className={Style.trashIcon}>
          <Button
            type='icon-round'
            size='extra small'
            icon='delete_outline'
            cssClass={Style.buttonStyle}
            disabled={!hasFiles || isDisabled}
            onClick={cancelUpload}
          />
        </div>
      </div>
    )
  }

  const renderFileTypes = () => {
    switch (automationSourceId) {
      case AutomationSourcesID.MACRO:
        return (
          <>
            <div>
              Excel <span className={Style.spanExtension}>({excelMacroTypes.join(', ')})</span>
            </div>
            <div>
              PowerPoint <span className={Style.spanExtension}>({powerpointMacroTypes.join(', ')})</span>
            </div>
            <div>
              Word <span className={Style.spanExtension}>({wordMacroTypes.join(', ')})</span>
            </div>
            <div>
              Project <span className={Style.spanExtension}>({projectMacroTypes.join(', ')})</span>
            </div>
            <div>
              Publisher <span className={Style.spanExtension}>({publisherMacroTypes.join(', ')})</span>
            </div>
            <div>
              Visio <span className={Style.spanExtension}>({visioMacroTypes.join(', ')})</span>
            </div>
          </>
        )
      case AutomationSourcesID.OFFICE_SCRIPTS:
        return (
          <div>
            <span className={Style.spanExtension}>{officeScriptTypes.join(', ')}</span>
          </div>
        )
      case AutomationSourcesID.EXCEL:
        return (
          <div>
            <span className={Style.spanExtension}>{microsoftExcelTypes.join(', ')}</span>
          </div>
        )
      default:
        return (
          <div>
            <span className={Style.spanExtension}>{imageFileTypes.join(', ')}</span>
          </div>
        )
    }
  }

  const uploadBox = () => {
    return (
      <>
        <div className={fileUploadClasses}>
          <div {...getRootProps()} className={Style.dropzoneBox}>
            <Icon type={'upload_file'} size={'35px'} className={Style.dropzoneIcon} />
            <div className={Style.instructionText}>Drop files to upload or click to add</div>
            <div className={Style.instructionText}>Maximum size: {fileSize(MAX_AUTOMATION_FILE_SIZE)}</div>
            <div className={Style.instructionText}>
              <Tooltip
                padding={32}
                cssClass='tooltip-theme-dark'
                show={
                  <div className={Style.dropzoneToolTip}>
                    <div className={Style.tooltipHeading}>Supported file types:</div>
                    <div className={Style.tooltipBody}>{renderFileTypes()}</div>
                  </div>
                }
              >
                <div className={Style.infoText}>
                  <Icon size='13px' type='information' className={Style.infoIcon} />
                  <div>List of supported file types</div>
                </div>
              </Tooltip>
            </div>

            <input disabled={isDisabled} {...getInputProps()} />
          </div>
          {trashCanRow()}
        </div>
        {!hasFiles && <p className={Style.errorMessage}>Missing file</p>}
      </>
    )
  }

  const filesBox = () => {
    return (
      <div className={Style.fileUploadContainer}>
        <div className={Style.filesBox}>
          {showPreview && preview ? (
            <img src={preview} alt='Preview' />
          ) : (
            <>
              <div id='fileIcon'>
                <Icon type='article' size='40px' outlined className={Style.fileIcon} />
              </div>
              {selectedFiles.map((file) => (
                <div key={indexKey()} className={Style.fileName}>
                  {file.size ? `${file.name} - ${fileSize(file.size)}` : file.name}
                </div>
              ))}
            </>
          )}
        </div>

        {trashCanRow()}
      </div>
    )
  }

  return <>{hasFiles ? filesBox() : uploadBox()}</>
}

export default FileUploader
