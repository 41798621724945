import Joi from 'joi'
import { AutomationSourcesID } from '../enums/AutomationSources'

export const TITLE_LIMIT = 100

export const automationFormFields = {
  title: 'Title',
  description: 'Description',
  capability: 'AutomationCapabilities',
  practice: 'AutomationPractices',
  region: 'AutomationRegions',
  automationSource: 'AutomationSourceId',
  originators: 'AutomationOriginators',
  currentActivityTime: 'CurrentActivityTime',
  automatedActivityTime: 'AutomatedActivityTime',
  automationUrl: 'AutomationUrl',
  approvingCapability: 'ApprovingCapability',
  guidanceMaterialUrl: 'GuidanceMaterialUrl',
  macroSafelisting: 'MacroSafelisting',
}

export const automationFormSchema = Joi.object({
  Title: Joi.string().trim().max(TITLE_LIMIT).messages({
    'string.empty': 'This field is required',
    'string.max': 'Title must be 100 characters or less',
  }),
  Description: Joi.string().trim().max(1000).messages({
    'string.empty': 'This field is required',
    'string.max': 'Description must be 1000 characters or less',
  }),
  AutomationCapabilities: Joi.array().min(1).messages({ 'array.min': 'Please select at least one option' }),
  AutomationPractices: Joi.array().min(1).messages({ 'array.min': 'Please select at least one option' }),
  AutomationOriginators: Joi.array().min(1).messages({ 'array.min': 'This field is required' }),
  AutomationRegions: Joi.array().min(1).messages({ 'array.min': 'Please select at least one option' }),
  AutomationSourceId: Joi.number().min(1).messages({ 'number.min': 'Please select an option' }),
  ApprovingCapability: Joi.number()
    .not(null)
    .messages({ 'number.base': 'This field is required', 'any.empty': 'This field is required' }),
  AutomatedActivityTime: Joi.number().integer().greater(-1).less(99999).messages({
    'number.integer': 'Value must be an integer',
    'number.greater': 'Value should be greater than or equal to 0',
    'number.less': 'Value must be lower than 99999',
  }),
  CurrentActivityTime: Joi.number().integer().greater(-1).less(99999).messages({
    'number.integer': 'Value must be an integer',
    'number.greater': 'Value should be greater than or equal to 0',
    'number.less': 'Value must be lower than 99999',
  }),
  AutomationUrl: Joi.string()
    .regex(/^(?:(?:(?:https?):)?\/\/)?(?:[\w-]+\.)+[a-z]{2,20}(?:\/\S*)?$/) // requires valid TLDs
    .message('Invalid URL')
    .uri({ scheme: ['https'] })
    .message('Invalid URL')
    .messages({ 'any.uri': 'Invalid URL', 'string.empty': 'This field is required' }),
  GuidanceMaterialUrl: Joi.string()
    .pattern(/^(?:(?:(?:https?):)?\/\/)?(?:[\w-]+\.)+[a-z]{2,20}(?:\/\S*)?$/) // requires valid TLDs
    .allow('')
    .optional()
    .messages({ 'any.uri': 'Invalid URL', 'string.pattern.base': 'Invalid URL' }),
  MacroSafelisting: Joi.boolean().when('AutomationSourceId', {
    is: AutomationSourcesID.MACRO,
    then: Joi.valid(true).required().messages({
      'any.required': 'This field is required',
      'any.only': 'This field is required',
    }),
    otherwise: Joi.optional(),
  }),
})
