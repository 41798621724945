import { FC, useEffect, useState } from 'react'
import Style from '../styles/Hero.module.sass'
import { Button, Grid } from '@aurecon-creative-technologies/styleguide'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { AppRoutes } from '../enums/AppRoutes'
import { BLOB_FEATURED_CONTAINER, TOUR_LINK } from '../config/config'
import { getAutomationAssets } from '../api/StorageService'
import DefaultHeroImage from '../assets/hero_default_bg.png'

interface HeroProps {
  automation?: IGetAutomationResponse
  firstName?: string
  coverPhoto?: File | null
  bannerUrl?: string
  inSettings?: boolean
}

const Hero: FC<HeroProps> = (props) => {
  const { inSettings, coverPhoto, bannerUrl } = props
  const [backgroundImg, setBackgroundImg] = useState<string>()

  useEffect(() => {
    const getThumbnail = async () => {
      if (!bannerUrl) return
      setBackgroundImg('') // reset banner
      const url = await getAutomationAssets({
        container: BLOB_FEATURED_CONTAINER,
        blobName: bannerUrl,
      })

      if (url) setBackgroundImg(url)
    }

    if (inSettings) {
      if (coverPhoto && coverPhoto.size !== 0) setBackgroundImg(URL.createObjectURL(coverPhoto))
      else getThumbnail()
      return
    }

    if (bannerUrl) getThumbnail()
    else setBackgroundImg(DefaultHeroImage)
  }, [bannerUrl, coverPhoto, inSettings])

  const handleClick = (automationId: string) => {
    if (inSettings) return
    location.hash = `#/${AppRoutes.FEATURED}/${automationId}`
  }

  const handleTakeATourClick = () => {
    if (inSettings) return
    window.open(TOUR_LINK, '_blank')
  }

  return (
    <div className={Style.heroContainer}>
      <Grid row xl={2} cssClass={Style.welcomePanel}>
        <div className={Style.welcome}>Welcome</div>
        <div className={Style.user}>{props.firstName}</div>
        <div className={Style.message}>
          Automation Centre is our centralised hub to share, search and run automation solutions.
        </div>
        <Button label='Take a Tour' cssClass={Style.takeATour} type='text' onClick={handleTakeATourClick} />
      </Grid>
      <Grid
        xl={10}
        style={{
          backgroundImage: `url(${backgroundImg})`,
        }}
        cssClass={Style.heroImage}
      >
        <div onClick={() => handleClick(props.automation?.Id ?? '')} className={Style.featuredDetails} role='none'>
          <div className={Style.featuredTitle}>{props.automation?.Title}</div>
          <div className={Style.featuredDescription}>{props.automation?.Description}</div>
        </div>
      </Grid>
    </div>
  )
}

export default Hero
