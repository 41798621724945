import { FC, useEffect, useState } from 'react'
import PageStyle from '../styles/FeaturedAutomationSettings.module.sass'
import { Button, Grid, Icon, Loader, useToast } from '@aurecon-creative-technologies/styleguide'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import ErrorPage from './ErrorPage'
import classNames from 'classnames'
import { useAuth0 } from '@auth0/auth0-react'
import FeaturedCard from '../components/FeaturedCard'
import Hero from '../components/Hero'
import { indexKey, dateToString, mapAutomationSourcesToTag, mapAutomationCapabilitiesToTag } from '../helpers/utils'
import EditTopFeaturedModal from '../components/common/modals/EditTopFeaturedModal'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  FeaturedAutomationsData,
  ShowEditBottomFeaturedModal,
  ShowEditTopFeaturedModal,
  ShowReorderFeaturedModal,
} from '../stores/SettingsStore'
import ReplaceFeaturedModal from '../components/common/modals/ReplaceFeaturedModal'
import EditBottomFeaturedModal from '../components/common/modals/EditBottomFeaturedModal'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { FeaturedAutomationsEnum } from '../enums/FeaturedAutomationsEnum'
import { IFeaturedAutomations, IFeaturedDetails } from '../models/api/IFeaturedAutomations'
import { AppRoutes } from '../enums/AppRoutes'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { updateFeaturedAutomation } from '../api/AutomationService'
import { AllFeaturedAutomations, featuredDetailsRefresh } from '../stores/AutomationViewerStore'
import { HeroAutomation, TemporaryFeaturedAutomations } from '../enums/TemporaryFeatured'
import { TagTypes } from '../enums/TagTypes'
import { SearchCurrentPage, SearchAutomationFilters, HasDirtyForm } from '../stores/AppStore'
import { SearchAutomationCriteria } from '../stores/ManageAutomationStore'
import ReorderFeaturedModal from '../components/common/modals/ReorderFeaturedModal'

const FeaturedAutomationSettings: FC = () => {
  const emptyForm = { top: null, left: null, right: null }
  const canViewFeaturedAutomations = useUserPermission(actions.VIEW_FEATURED_AUTOMATION_SETTINGS)
  const featuredLoader = useRecoilValueLoadable(AllFeaturedAutomations)
  const [featuredAutomations, setFeaturedAutomations] = useRecoilState(FeaturedAutomationsData)
  const [selectedFeatured, setSelectedFeatured] = useState<number>(FeaturedAutomationsEnum.TOP)
  const [initialFeaturedData, setInitialFeaturedData] = useState<IFeaturedAutomations>(emptyForm)
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const setShowEditTopModal = useSetRecoilState(ShowEditTopFeaturedModal)
  const setShowEditBottomModal = useSetRecoilState(ShowEditBottomFeaturedModal)
  const refreshFeaturedAutomations = useRecoilRefresher_UNSTABLE(AllFeaturedAutomations)
  const setSearchCurrentPage = useSetRecoilState(SearchCurrentPage)
  const setSearchAutomationCriteria = useSetRecoilState(SearchAutomationCriteria)
  const setSearchAutomationFilters = useSetRecoilState(SearchAutomationFilters)
  const automationStateCounter = useSetRecoilState(featuredDetailsRefresh)
  const refreshFeaturedDetails = () => automationStateCounter((num) => num + 1)
  const [publishedDate, setPublishedDate] = useState<Date>()
  const [hasDirtyForm, setHasDirtyForm] = useRecoilState(HasDirtyForm)

  const setShowReorderFeaturedModal = useSetRecoilState(ShowReorderFeaturedModal)
  const { user } = useAuth0()
  const { addToast, removeToast } = useToast()

  useEffect(() => {
    if (featuredLoader.state === 'loading') return

    if (featuredLoader.state === 'hasValue' && featuredLoader.contents) {
      const topData =
        featuredLoader.contents.find((item) => item.FeatureLocation === FeaturedAutomationsEnum.TOP) ?? HeroAutomation
      const leftData =
        featuredLoader.contents.find((item) => item.FeatureLocation === FeaturedAutomationsEnum.LEFT) ??
        TemporaryFeaturedAutomations[0]
      const rightData =
        featuredLoader.contents.find((item) => item.FeatureLocation === FeaturedAutomationsEnum.RIGHT) ??
        TemporaryFeaturedAutomations[1]

      if (topData.PublishedDate) setPublishedDate(topData.PublishedDate)
      if (!leftData && !topData && !rightData) return
      const data = {
        top: {
          automation: topData as IGetAutomationResponse,
          content: '',
          bannerFile: new File([], 'banner.jpeg'),
          thumbnailFile: new File([], 'thumbnail.jpeg'),
          thumbnailUrl: topData?.ThumbnailUrl,
          bannerUrl: topData?.BannerUrl,
        },
        left: {
          automation: leftData as IGetAutomationResponse,
          content: '',
          bannerFile: new File([], 'banner.jpeg'),
          thumbnailFile: new File([], 'thumbnail.jpeg'),
          thumbnailUrl: leftData?.ThumbnailUrl,
          bannerUrl: leftData?.BannerUrl,
        },
        right: {
          automation: rightData as IGetAutomationResponse,
          content: '',
          bannerFile: new File([], 'banner.jpeg'),
          thumbnailFile: new File([], 'thumbnail.jpeg'),
          thumbnailUrl: rightData?.ThumbnailUrl,
          bannerUrl: rightData?.BannerUrl,
        },
      }
      setFeaturedAutomations(data)
      setInitialFeaturedData(data)
    }
  }, [featuredLoader, setFeaturedAutomations])

  useEffect(() => {
    setSearchAutomationCriteria({
      filters: undefined,
      currentPage: 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
      searchText: '',
    })
    setSearchCurrentPage(1)
    setSearchAutomationFilters({
      [TagTypes.SOURCE]: [],
      [TagTypes.CAPABILITY]: [],
      [TagTypes.PRACTICE]: [],
      [TagTypes.REGION]: [],
    })
  })

  const handleBottomFeaturedUpdate = (data: IFeaturedDetails) => {
    if (selectedFeatured === FeaturedAutomationsEnum.LEFT) {
      setFeaturedAutomations({ ...featuredAutomations, left: data })
      setInitialFeaturedData({ ...initialFeaturedData, left: data })
    } else if (selectedFeatured === FeaturedAutomationsEnum.RIGHT) {
      setFeaturedAutomations({ ...featuredAutomations, right: data })
      setInitialFeaturedData({ ...initialFeaturedData, right: data })
    }

    setHasDirtyForm(true)
  }

  const handleBottomFeaturedCancel = () => {
    if (selectedFeatured === FeaturedAutomationsEnum.LEFT) {
      setFeaturedAutomations({ ...featuredAutomations, left: initialFeaturedData.left })
    } else if (selectedFeatured === FeaturedAutomationsEnum.RIGHT) {
      setFeaturedAutomations({ ...featuredAutomations, right: initialFeaturedData.right })
    }

    refreshFeaturedDetails()
  }

  const handleOpenLink = (selected: IGetAutomationResponse | null) => {
    if (!selected) return
    if (selected.UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`, '_blank')
      return
    }

    if (selected.UploadTypeId === AutomationUploadTypes.STREAMLINER) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}/${AutomationUploadTypes.STREAMLINER}`, '_blank')
      return
    }

    window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}`, '_blank')
  }

  const handlePublish = async () => {
    try {
      const infoToast = addToast({
        type: 'info',
        message: `Publishing featured automations, please wait...`,
      })

      setIsPublishing(true)
      const result = await updateFeaturedAutomation(featuredAutomations)
      removeToast(infoToast)
      if (result?.success) {
        refreshFeaturedAutomations()
        refreshFeaturedDetails()
        setHasDirtyForm(false)
        addToast({
          type: 'success',
          message: `Featured automations have been published`,
          timeout: 5000,
        })
      } else {
        throw new Error('publish failed')
      }
    } catch (error) {
      addToast({
        type: 'error',
        message: `Publish unsuccessful`,
        timeout: 5000,
      })
    } finally {
      setIsPublishing(false)
    }
  }

  if (!canViewFeaturedAutomations) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  const changeOrderClasses = classNames({
    [PageStyle.changeOrderButton]: true,
    [PageStyle.controls]: true,
  })

  const publishClasses = classNames({
    [PageStyle.publishButton]: true,
    [PageStyle.controls]: true,
  })

  const topFeaturedAutomationClasses = classNames({
    [PageStyle.rightControlsContainer]: true,
    [PageStyle.actionsContainer]: true,
  })

  if (featuredLoader.state === 'loading')
    return (
      <div className={PageStyle.loaderContainer}>
        <Loader label={'Loading Automations'} />
      </div>
    )

  if (featuredLoader.state === 'hasError' || (featuredLoader.state === 'hasValue' && !featuredLoader.contents)) {
    return (
      <div className={PageStyle.loaderContainer}>
        <span>Unable to load the featured automations. Please refresh the page.</span>
      </div>
    )
  }

  return (
    <div className={PageStyle.settingsContainer}>
      <div className={PageStyle.topControlsContainer}>
        <div className={PageStyle.controlsContainer}>
          <Button
            type='custom'
            icon={'menu'}
            cssClass={changeOrderClasses}
            label='Change Order'
            onClick={() => setShowReorderFeaturedModal(true)}
          />
          <Button
            type='custom'
            cssClass={publishClasses}
            label={isPublishing ? 'Publishing' : 'Publish'}
            onClick={handlePublish}
            disabled={isPublishing || !hasDirtyForm}
          />
          <span className={PageStyle.lastPublished}>
            Last Published: {publishedDate ? dateToString(publishedDate, 'MM/dd/yyyy hh:mm a') : 'Not Available'}
          </span>
        </div>
        {/* TOP FEATURED AUTOMATION */}
        <div className={topFeaturedAutomationClasses}>
          <Icon
            type={'launch'}
            size={'large'}
            className={PageStyle.actionsIcon}
            onClick={() => handleOpenLink(featuredAutomations.top?.automation ?? null)}
          />
          <Icon
            type={'edit'}
            size={'large'}
            className={PageStyle.actionsIcon}
            onClick={() => {
              setShowEditTopModal(true)
              setSelectedFeatured(FeaturedAutomationsEnum.TOP)
            }}
          />
        </div>
      </div>
      <div>
        <Hero
          firstName={user?.nickname?.split('.')[0]}
          automation={featuredAutomations?.top?.automation}
          coverPhoto={featuredAutomations?.top?.bannerFile}
          bannerUrl={featuredAutomations.top?.bannerUrl}
          inSettings={true}
        />
      </div>

      <Grid row xs={12} gap={12} cssClass={PageStyle.section}>
        {/* LEFT FEATURED AUTOMATION */}
        <Grid item right gap={1} xl={6} cssClass={PageStyle.featuredItem} key={indexKey()}>
          <div className={PageStyle.featuredItemContainer}>
            <div className={topFeaturedAutomationClasses}>
              <Icon
                type={'launch'}
                size={'large'}
                className={PageStyle.actionsIcon}
                onClick={() => handleOpenLink(featuredAutomations.left?.automation ?? null)}
              />
              <Icon
                type={'edit'}
                size={'large'}
                className={PageStyle.actionsIcon}
                onClick={() => {
                  setShowEditBottomModal(true)
                  setSelectedFeatured(FeaturedAutomationsEnum.LEFT)
                }}
              />
            </div>
            {featuredAutomations.left && (
              <FeaturedCard
                title={featuredAutomations.left?.automation.Title}
                id={featuredAutomations.left?.automation.Id}
                description={featuredAutomations.left?.automation.Description}
                creationDate={dateToString(featuredAutomations.left?.automation.DateCreated)}
                tags={[
                  ...mapAutomationSourcesToTag(featuredAutomations.left?.automation),
                  ...mapAutomationCapabilitiesToTag(featuredAutomations.left?.automation),
                ]}
                thumbnail={featuredAutomations.left?.automation.ThumbnailUrl ?? featuredAutomations.left?.automation.Id}
                thumbnailFile={featuredAutomations.left.thumbnailFile}
                inSettings={true}
              />
            )}
          </div>
        </Grid>
        {/* RIGHT FEATURED AUTOMATION */}
        <Grid item right gap={1} xl={6} cssClass={PageStyle.featuredItem} key={indexKey()}>
          <div className={PageStyle.featuredItemContainer}>
            <div className={topFeaturedAutomationClasses}>
              <Icon
                type={'launch'}
                size={'large'}
                className={PageStyle.actionsIcon}
                onClick={() => handleOpenLink(featuredAutomations.right?.automation ?? null)}
              />
              <Icon
                type={'edit'}
                size={'large'}
                className={PageStyle.actionsIcon}
                onClick={() => {
                  setShowEditBottomModal(true)
                  setSelectedFeatured(FeaturedAutomationsEnum.RIGHT)
                }}
              />
            </div>
            {featuredAutomations.right && (
              <FeaturedCard
                title={featuredAutomations.right?.automation.Title}
                id={featuredAutomations.right?.automation.Id}
                description={featuredAutomations.right?.automation.Description}
                creationDate={dateToString(featuredAutomations.right?.automation.DateCreated)}
                tags={[
                  ...mapAutomationSourcesToTag(featuredAutomations.right?.automation),
                  ...mapAutomationCapabilitiesToTag(featuredAutomations.right?.automation),
                ]}
                thumbnail={
                  featuredAutomations.right?.automation.ThumbnailUrl ?? featuredAutomations.right?.automation.Id
                }
                thumbnailFile={featuredAutomations.right.thumbnailFile}
                inSettings={true}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {featuredAutomations?.top && selectedFeatured === FeaturedAutomationsEnum.TOP && (
        <EditTopFeaturedModal
          onTopFeaturedUpdate={(data) => {
            setFeaturedAutomations({ ...featuredAutomations, top: data })
            setInitialFeaturedData({ ...initialFeaturedData, top: data })
            setHasDirtyForm(true)
          }}
          onCancel={() => {
            setFeaturedAutomations({ ...featuredAutomations, top: initialFeaturedData.top })
            refreshFeaturedDetails()
          }}
          featuredData={featuredAutomations}
        />
      )}
      {featuredAutomations && selectedFeatured !== FeaturedAutomationsEnum.TOP && (
        <EditBottomFeaturedModal
          onFeatureUpdate={(data) => handleBottomFeaturedUpdate(data)}
          onCancel={() => handleBottomFeaturedCancel()}
          featuredData={featuredAutomations}
          selectedFeaturedAutomation={selectedFeatured}
          automationId={
            selectedFeatured === FeaturedAutomationsEnum.LEFT
              ? featuredAutomations.left?.automation.Id
              : featuredAutomations.right?.automation.Id
          }
        />
      )}
      <ReplaceFeaturedModal editingFeatured={selectedFeatured} />
      <ReorderFeaturedModal />
    </div>
  )
}

export default FeaturedAutomationSettings
