import Joi from 'joi'

export const addNewCommentFormFields = {
  commentText: 'comment',
}

export const addNewCommentFormSchema = Joi.object({
  comment: Joi.string().trim().required().max(500).messages({
    'string.empty': 'This is a required field',
    'string.required': 'This is a required field',
    'string.max': 'Comment must be 500 characters or less',
  }),
})
