//This should match the table LUUploadType
export const AutomationUploadTypes = {
  SCRIPT_LIBRARY: 1,
  UPLOAD_FILE: 2,
  LINK: 3,
  OTHER: 4,
  STREAMLINER: 5,
}

export enum AutomationType {
  AUTOMATION = 'automation',
  FEATURED = 'featured',
  SCRIPT_LIBRARY = 'script library',
  STREAMLINER = 'streamliner',
}
