import HeroImage from '../assets/recall_bamboo.png'
import Featured2Image from '../assets/featured1.png'
import Featured3Image from '../assets/featured3.png'
import { AutomationUploadTypes } from './AutomationUploadTypes'

import Featured1 from '../assets/featured-thumbnail-1.png'
import Featured2 from '../assets/featured-thumbnail-2.png'
import { IFeaturedAutomationResponse } from '../models/api/IFeaturedAutomations'
import { FeaturedAutomationsEnum } from './FeaturedAutomationsEnum'
import DefaultHeroImage from '../assets/hero_default_bg.png'
import CoverDefault from '../assets/cover_default.png'

export const HeroAutomation: IFeaturedAutomationResponse = {
  Id: '827F6AB5-03A4-48EE-A499-8F6641B4DBA3',
  Title: 'Bamboo - A Recall App',
  Description:
    'Bamboo allows you to tease structured information out of unstructured raw data. Upload a PDF, set a structure, and let AI generate and fill your structure with information from your PDF.',
  AuthorUserId: 'user1',
  AutomationSources: [
    {
      Id: 11,
      Name: 'Other',
    },
  ],
  AutomationStateId: 3,
  AutomationState: {
    Id: 3,
    Name: 'Authorised',
  },
  AutomationSourceId: 11,
  UploadTypeId: AutomationUploadTypes.LINK,
  PlatformInterfaceId: 1,
  ThumbnailUrl: '1e80bd3f-0403-4dc0-b2b5-1bf2965b2845',
  Thumbnail: null,
  ApprovingCapability: 0,
  AutomationAuthor: {
    AuthFederationId: '0',
    UserEmail: '',
    UserFullName: 'Alex Owens',
  },
  AutomationCapabilities: [
    {
      Id: '7',
      Name: 'Digital',
    },
  ],
  AutomationPractices: [
    {
      Id: '10',
      Name: 'Digital',
    },
  ],
  AutomationRegions: [
    {
      Id: '1',
      Name: 'Global',
    },
  ],
  AutomationOriginators: [
    {
      AutomationId: '827F6AB5-03A4-48EE-A499-8F6641B4DBA3',
      User: {
        AuthFederationId: '1',
        UserEmail: '',
        UserFullName: 'Alex Owens',
      },
      UserId: '',
    },
    {
      AutomationId: '827F6AB5-03A4-48EE-A499-8F6641B4DBA3',
      User: {
        AuthFederationId: '1',
        UserEmail: '',
        UserFullName: 'Theodoros Galanos',
      },
      UserId: '',
    },
    {
      AutomationId: '827F6AB5-03A4-48EE-A499-8F6641B4DBA3',
      User: {
        AuthFederationId: '1',
        UserEmail: '',
        UserFullName: 'Luke Hughes',
      },
      UserId: '',
    },
  ],
  AutomationPlatformInterfaces: [],
  DateCreated: new Date('2024/08/20'),
  DateLastModified: new Date('2024/08/20'),
  CurrentActivityTime: 20,
  AutomatedActivityTime: 5,
  AutomationUrl: {
    AutomationId: '',
    Url: 'https://recall.aurecongroup.digital/#/chat-bamboo',
  },
  GuidanceMaterialUrl:
    'https://aurecongroup.sharepoint.com/sites/khub-creativetechnology/SitePages/Recall-marks-V1.0-milestone-with-new-app-Bamboo.aspx?ct=1724109222946&or=Teams-HL&ga=1&LOF=1',
  LastModifiedByUserId: null,
  AutomationApprovals: [
    {
      ApproverRole: {
        Id: '',
        Name: '',
      },
      ApproverComment: '',
      ApprovalState: {
        Id: '',
        Name: '',
      },
      DateCreated: '',
      ApprovedBy: {
        AuthFederationId: '0',
        UserEmail: '',
        UserFullName: 'Ian Petherbridge',
      },
      Override: false,
      OverrideRole: 0,
      OverrideRoleDetails: null,
      IsResolved: false,
    },
    {
      ApproverRole: {
        Id: '',
        Name: '',
      },
      ApproverComment: '',
      ApprovalState: {
        Id: '',
        Name: '',
      },
      DateCreated: '',
      ApprovedBy: {
        AuthFederationId: '0',
        UserEmail: '',
        UserFullName: 'Nathan Lawrence',
      },
      Override: false,
      OverrideRole: 0,
      OverrideRoleDetails: null,
      IsResolved: false,
    },
    {
      ApproverRole: {
        Id: '',
        Name: '',
      },
      ApproverComment: '',
      ApprovalState: {
        Id: '',
        Name: '',
      },
      DateCreated: '',
      ApprovedBy: {
        AuthFederationId: '0',
        UserEmail: '',
        UserFullName: 'Tony Fontanoz',
      },
      Override: false,
      OverrideRole: 0,
      OverrideRoleDetails: null,
      IsResolved: false,
    },
  ],
  VerificationDate: new Date('2024/08/22'),
  IsActive: true,
  MacroSafelisting: true,
  BannerUrl: DefaultHeroImage,
  FeatureLocation: FeaturedAutomationsEnum.TOP,
}

export const TemporaryFeaturedAutomations: IFeaturedAutomationResponse[] = [
  {
    Id: '6B91A17B-4FC1-4E45-B4F2-74C6D8AC7B72',
    Title: 'Automated Report Generator',
    Description:
      'The Automatic Report Generator (ARG) is a tool that leverages our technical knowledge bases to expedite the formation of deliverable documentation.  It allows users to nominate a capability and discipline specific document for first draft generation, automatically combining selected template sections for a project bespoke output. ',
    AuthorUserId: 'user1',
    AutomationSources: [
      {
        Id: 10,
        Name: 'Power Apps ',
      },
    ],
    AutomationStateId: 3,
    AutomationState: {
      Id: 3,
      Name: 'Authorised',
    },
    AutomationSourceId: 1,
    UploadTypeId: AutomationUploadTypes.LINK,
    PlatformInterfaceId: 1,
    ThumbnailUrl: '1e80bd3f-0403-4dc0-b2b5-1bf2965b2845',
    Thumbnail: Featured2,
    ApprovingCapability: 0,
    AutomationAuthor: {
      AuthFederationId: '0',
      UserEmail: '',
      UserFullName: 'Johanna Steriovski',
    },
    AutomationCapabilities: [
      {
        Id: '5',
        Name: 'Building Mechanical',
      },
    ],
    AutomationPractices: [
      {
        Id: '6',
        Name: 'Buildings Mechanical',
      },
    ],
    AutomationRegions: [
      {
        Id: '1',
        Name: 'Global',
      },
    ],
    AutomationOriginators: [
      {
        AutomationId: '6B91A17B-4FC1-4E45-B4F2-74C6D8AC7B72',
        User: {
          AuthFederationId: '1',
          UserEmail: '',
          UserFullName: 'Johanna Steriovski',
        },
        UserId: '',
      },
    ],
    AutomationPlatformInterfaces: [
      {
        Id: '',
        Name: 'SharePoint',
      },
    ],
    DateCreated: new Date('2023/04/19'),
    DateLastModified: new Date('2023/04/19'),
    CurrentActivityTime: 90,
    AutomatedActivityTime: 30,
    AutomationUrl: {
      AutomationId: '',
      Url: 'https://apps.powerapps.com/play/e/0a57948f-1c99-e020-94cd-0d23870b5eca/a/31cba515-14f1-4676-a7e8-e518216597b2?tenantId=c1bd07b1-b7b6-47ca-9ef0-cf66735daae6&source=portal ',
    },
    GuidanceMaterialUrl: null,
    LastModifiedByUserId: null,
    AutomationApprovals: [],
    VerificationDate: new Date('2023/04/19'),
    IsActive: true,
    MacroSafelisting: true,
    BannerUrl: CoverDefault,
    FeatureLocation: FeaturedAutomationsEnum.LEFT,
  },
  {
    Id: '089347A1-87B9-4762-B6C2-BDEBCB35C8C2',
    Title: 'Load case title and combination generator',
    Description:
      'This tool has been built to allow speedy development of systematic load case naming and load combination generation for structural analysis models.  ',
    AuthorUserId: 'user1',
    AutomationStateId: 3,
    AutomationState: {
      Id: 3,
      Name: 'Authorised',
    },
    AutomationSourceId: 8,
    UploadTypeId: AutomationUploadTypes.UPLOAD_FILE,
    PlatformInterfaceId: 1,
    ThumbnailUrl: '288b88e0-161c-4c5f-b171-c2d639de48f4',
    Thumbnail: Featured1,
    ApprovingCapability: 0,
    AutomationSources: [
      {
        Id: 8,
        Name: 'Microsoft VBA Code (Macro)',
      },
    ],
    AutomationLikes: [],
    AutomationAuthor: {
      AuthFederationId: '0',
      UserEmail: '',
      UserFullName: 'Richard Woollard',
    },
    AutomationCapabilities: [
      {
        Id: '17',
        Name: 'Ports, Marine & Coastal',
      },
    ],
    AutomationPractices: [
      {
        Id: '23',
        Name: 'Ports, Marine & Coastal',
      },
    ],
    AutomationRegions: [
      {
        Id: '1',
        Name: 'Global',
      },
    ],
    AutomationOriginators: [
      {
        AutomationId: '089347A1-87B9-4762-B6C2-BDEBCB35C8C2',
        User: {
          AuthFederationId: '1',
          UserEmail: '',
          UserFullName: 'Richard Woollard',
        },
        UserId: '',
      },
    ],
    AutomationPlatformInterfaces: [
      {
        Id: '',
        Name: 'Excel',
      },
    ],
    DateCreated: new Date('2022/09/16'),
    DateLastModified: new Date('2022/09/23'),
    CurrentActivityTime: 60,
    AutomatedActivityTime: 30,
    AutomationFileUpload: {
      AutomationId: '3bfb62fe-6adc-4685-83ea-df1d932521c3',
      FileUri: 'ba7de6be-7e69-47d3-9665-9a1b721f5483',
      OriginalFileName: 'PMC-LcGenerator.xlsm',
    },
    GuidanceMaterialUrl: null,
    LastModifiedByUserId: null,
    AutomationApprovals: [
      {
        ApproverRole: {
          Id: '',
          Name: '',
        },
        ApproverComment: '',
        ApprovalState: {
          Id: '',
          Name: '',
        },
        DateCreated: '',
        ApprovedBy: {
          AuthFederationId: '0',
          UserEmail: '',
          UserFullName: 'Saman Khajehzadeh',
        },
        Override: false,
        OverrideRole: 0,
        OverrideRoleDetails: null,
        IsResolved: false,
      },
    ],
    VerificationDate: new Date('2022/09/23'),
    IsActive: true,
    MacroSafelisting: true,
    BannerUrl: CoverDefault,
    FeatureLocation: FeaturedAutomationsEnum.RIGHT,
  },
]

export const featuredText = [
  {
    id: '6B91A17B-4FC1-4E45-B4F2-74C6D8AC7B72',
    section1: [
      'The Automatic Report Generator (ARG) is a tool that leverages our technical knowledge bases to expedite the formation of deliverable documentation.  It allows users to nominate a capability and discipline specific document for first draft generation, automatically combining selected template sections for a project bespoke output. ',
      ' Where previously one may have used an archival document for the basis of a first draft the ARG taps into our Knowledge Hub base of standardised template sections, ensuring a single source of truth for up to date and verified base documentation. This approach furthermore reduces the risk of carry over errors such as previous project naming conventions or referencing of out-of-date standards.',
      'The tool is also empowered by connection to Dynamics allowing users to search and select projects for auto population of cover sheet and document control page details such as Project Manager and Client Name. ',
      'Once selections have been submitted via the app the user will shortly receive an email with their bespoke document attached.',
    ],
    section2: [
      `Remember this is a tool that empowers users to fast track the creation of first draft documents and will require manual review and updating.`,
      `New capabilities and disciplines are activated regularly.`,
      `Have a document you’d like to ARG? reach out to the Automation and Platforms team.`,
    ],
    image: Featured2Image,
  },
  {
    id: '827F6AB5-03A4-48EE-A499-8F6641B4DBA3',
    section1: [
      `Bamboo is the latest experimental app to join Aurecon's first intelligent assistant Recall. It is designed to help you build structured information out of raw, unstructured data. Bamboo can read through large PDF files to search for specified data, and return the results as structured data. View [more details](https://aurecongroup.sharepoint.com/sites/khub-creativetechnology/SitePages/Recall-marks-V1.0-milestone-with-new-app-Bamboo.aspx?referrer=Yammer&referrerScenario=Feed.View) on how to use Bamboo.`,
    ],
    image: HeroImage,
  },
  {
    id: '089347A1-87B9-4762-B6C2-BDEBCB35C8C2',
    section1: [
      'This tool has been built to allow speedy development of systematic load case naming and load combination generation for structural analysis models.  ',
      'It is as simple as entering each primary use case grouping and clicking a button to generate the load case titles to be pasted into Space Gass and used in CombinationInput.  ',
      'Already have an existing Space Gass model you would like to re-format? This automation can handle that too! ',
    ],
    image: Featured3Image,
  },
]
