import { IButtonProps, Modal, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/RemoveApproverModal.module.sass'
import { AllApprovers, PermissionHistory, ShowRemoveApproverModal } from '../../../stores/UserStore'
import { IGetApproverResponse } from '../../../models/api/IUserRequestModel'
import { removeApprover } from '../../../api/UserService'
import { AppRoleName } from '../../../enums/AppRoles'
import { AppRolesEnum } from '../../../config/config'

interface IRemoveApproverModalProps {
  selectedUser: IGetApproverResponse | null
}

const RemoveApproverModal: FC<IRemoveApproverModalProps> = ({ selectedUser }) => {
  const [showRemoveApproverModal, setShowRemoveApproverModal] = useRecoilState(ShowRemoveApproverModal)
  const resetAllApproverList = useRecoilRefresher_UNSTABLE(AllApprovers)
  const resetPermissionHistory = useRecoilRefresher_UNSTABLE(PermissionHistory)
  const [user, setUser] = useState<IGetApproverResponse>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { addToast } = useToast()

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser)
    }
  }, [selectedUser])

  const handleClose = () => {
    setIsSubmitting(false)
    setShowRemoveApproverModal(false)
  }

  const handleSave = async () => {
    if (!user) return

    const payload = {
      email: user.email,
      roles: user.roles,
    }

    setIsSubmitting(true)
    const result = await removeApprover(payload)

    if (!result.success) {
      addToast({
        type: 'error',
        message: 'Failed to remove approver.',
        timeout: 4000,
        datetime: Date.now(),
      })
      return
    }

    resetAllApproverList()
    resetPermissionHistory()
    setIsSubmitting(false)
    handleClose()
    addToast({
      type: 'success',
      message: 'Authoriser has been removed.',
      timeout: 4000,
      datetime: Date.now(),
    })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: isSubmitting ? 'Saving' : 'Yes',
      type: 'secondary',
      onClick: handleSave,
      cssClass: Style.secondaryButton,
      disabled: isSubmitting,
    },
    {
      label: 'No',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.primaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showRemoveApproverModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='small'
      cssClass={Style.removeApproverModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Remove Authoriser?</h2>
        </div>
        <div className={Style.modalContent}>
          This action will remove the user as {selectedUser?.roles[0] === AppRolesEnum.IT ? 'an ' : 'a '}
          <span className={Style.userName}>{`${
            AppRoleName[selectedUser?.roles[0] as keyof typeof AppRolesEnum]
          }`}</span>{' '}
          authoriser role. Are you sure you want to remove <span className={Style.userName}>{selectedUser?.name}</span>?
        </div>
      </>
    </Modal>
  )
}

export default RemoveApproverModal
