import { FC, useRef, useState } from 'react'
import Style from '../styles/ApproverComboBox.module.sass'
import { ComboBox, Grid, IOption, Tooltip } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import debounce from 'debounce'
import { searchUsers } from '../api/UserService'
import { IGetApproverResponse } from '../models/api/IUserRequestModel'
import { ResponseData } from '../models/api/IResponse'
import { useRecoilValueLoadable } from 'recoil'
import { AllApprovers } from '../stores/UserStore'
interface IApproverComboBox {
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
  tooltipMessage?: string
  onSelectedItemsChange: (data: IOption) => void
}

export interface IApproverComboBoxProps extends IOption {
  name?: string
}

const ApproverComboBox: FC<IApproverComboBox> = (props) => {
  const { label, placeholder, required, disabled, error, tooltipMessage, onSelectedItemsChange } = props
  const userRef = useRef<HTMLInputElement | null>(null)
  const [val, setVal] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<IOption[]>([])
  const allApprovers = useRecoilValueLoadable(AllApprovers)

  const handleAddClient = (newOption: IApproverComboBoxProps | null) => {
    if (!newOption || !userRef.current) return

    onSelectedItemsChange(newOption)
    userRef.current.value = newOption.value
    setVal(newOption.value)
    userRef.current.blur()
  }

  const onSearch = debounce(async (event: React.FormEvent<HTMLDivElement>) => {
    if (!userRef.current) return
    const value = (event.target as HTMLInputElement).value
    if (val === value) return
    setVal(value)
    userRef.current.value = value
    if (value.length < 3) {
      setUsers([])
      return
    }
    setIsLoading(true)
    const userList = ResponseData(await searchUsers({ email: value })) || []
    const allApproversList = allApprovers.contents as IGetApproverResponse[]

    const approversWithEmails = new Set(allApproversList.filter((a) => a.roles.length > 0).map((a) => a.email))

    const filteredUsers = userList.filter((user) => !approversWithEmails.has(user.email))
    setUsers(filteredUsers.map((u) => ({ id: u.email ?? '', value: `${u.name} (${u.email})` })))
    setIsLoading(false)
  }, 500)

  const comboBoxClasses = classNames({
    [Style.comboBox]: true,
    [Style.loading]: isLoading,
  })

  return (
    <Grid item xs={12} cssClass={Style.metadataRow}>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        {tooltipMessage ? (
          <Tooltip padding={32} cssClass='tooltip-theme-dark' defaultUp show={tooltipMessage}>
            <div className={Style.metadataFieldlabel}>
              {label} {required ? '*' : ''}
            </div>
          </Tooltip>
        ) : (
          <div className={Style.metadataFieldlabel}>
            {label} {required ? '*' : ''}
          </div>
        )}
      </Grid>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={comboBoxClasses} onChange={onSearch}>
          <ComboBox
            options={users || []}
            onSelect={handleAddClient}
            required={required}
            selected={val}
            disabled={disabled}
            placeholder={placeholder}
            ref={userRef}
            icon={isLoading ? 'sync' : undefined}
          />
        </div>
        {error && <p className={Style.errorMessage}>{error}</p>}
      </Grid>
    </Grid>
  )
}

export default ApproverComboBox
