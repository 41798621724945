import { FC, useEffect, useState } from 'react'
import Style from '../styles/Settings.module.sass'
import { appInsights } from '../api/AppInsights'
import { Container } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import ErrorPage from './ErrorPage'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import ApproverManagement from './ApproverManagement'
import UploadGuidance from './UploadGuidance'
import { SettingsTabsEnum } from '../enums/SettingsTabs'
import ConfirmModal from '../components/ConfirmModal'
import { useRecoilState, useRecoilRefresher_UNSTABLE } from 'recoil'
import { HasDirtyForm } from '../stores/AppStore'
import { AllSources } from '../stores/AutomationViewerStore'
import FeaturedAutomationSettings from './FeaturedAutomationSettings'

const Settings: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Settings' })
  const [activeTab, setActiveTab] = useState<number>(SettingsTabsEnum.AUTHORISERS_MANAGEMENT)
  const [targetTab, setTargetTab] = useState<number>(0)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [hasDirtyForm, setHasDirtyForm] = useRecoilState(HasDirtyForm)
  const refreshAutomationSources = useRecoilRefresher_UNSTABLE(AllSources)

  const canViewUserList = useUserPermission(actions.VIEW_USER_LIST)
  const canEditUploadGuidance = useUserPermission(actions.EDIT_UPLOAD_GUIDANCE)
  const canEditFeaturedAutomations = useUserPermission(actions.EDIT_FEATURED_AUTOMATION)

  useEffect(() => {
    if (!canViewUserList && canEditUploadGuidance) setActiveTab(SettingsTabsEnum.UPLOAD_GUIDANCE)
    else if (canViewUserList) setActiveTab(SettingsTabsEnum.AUTHORISERS_MANAGEMENT)
  }, [canEditUploadGuidance, canViewUserList, setActiveTab])

  const handleWarningModal = (value: number) => {
    if (value === activeTab) return
    if (hasDirtyForm) {
      setTargetTab(value)
      setShowWarningModal(true)
    } else {
      setActiveTab(value)
    }
  }

  const handleChangeTab = () => {
    if (hasDirtyForm) {
      setShowWarningModal(false)
      setHasDirtyForm(false)
      refreshAutomationSources()
    }
    setActiveTab(targetTab)
  }

  if (!canViewUserList && !canEditUploadGuidance && !canEditFeaturedAutomations)
    return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  return (
    <Page menu manage>
      <Container cssClass={Style.layoutContainer} fluid>
        <header className={Style.hero}>
          <h2>Settings</h2>
          <div className={Style.tabs}>
            {canViewUserList && (
              <div
                className={`${Style.tab} ${activeTab === SettingsTabsEnum.AUTHORISERS_MANAGEMENT ? Style.active : ''}`}
                onClick={() => {
                  handleWarningModal(SettingsTabsEnum.AUTHORISERS_MANAGEMENT)
                }}
                role='none'
              >
                Authorisers Managements
              </div>
            )}
            {canEditUploadGuidance && (
              <div
                className={`${Style.tab} ${activeTab === SettingsTabsEnum.UPLOAD_GUIDANCE ? Style.active : ''}`}
                onClick={() => {
                  handleWarningModal(SettingsTabsEnum.UPLOAD_GUIDANCE)
                }}
                role='none'
              >
                Upload Guidance
              </div>
            )}
            {canEditFeaturedAutomations && (
              <div
                className={`${Style.tab} ${activeTab === SettingsTabsEnum.FEATURED_AUTOMATIONS ? Style.active : ''}`}
                onClick={() => {
                  handleWarningModal(SettingsTabsEnum.FEATURED_AUTOMATIONS)
                }}
                role='none'
              >
                Featured Automations
              </div>
            )}
          </div>
        </header>
        {activeTab === SettingsTabsEnum.AUTHORISERS_MANAGEMENT && <ApproverManagement />}
        {activeTab === SettingsTabsEnum.UPLOAD_GUIDANCE && <UploadGuidance />}
        {activeTab === SettingsTabsEnum.FEATURED_AUTOMATIONS && <FeaturedAutomationSettings />}
      </Container>
      <ConfirmModal
        title='Unsaved Changes'
        message='You have unsaved changes. Are you sure you want to leave this page?'
        onYes={() => {
          handleChangeTab()
        }}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false)
        }}
      />
    </Page>
  )
}

export default Settings
