import { IButtonProps, IHeader, Modal, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import Style from '../../../styles/common/modals/PermissionHistoryModal.module.sass'
import LoadingScreen from '../../LoadingScreen'
import { PAGE_SIZE } from '../../../config/config'
import { dateToString, indexKey } from '../../../helpers/utils'
import CustomPagination from '../CustomPagination'

import { EditHistory, ShowEditHistoryModal } from '../../../stores/AutomationViewerStore'
import { IEditHistory } from '../../../models/api/IAutomationRequest'

import { AutomationFormFieldsLabel } from '../../../enums/AutomationFormFieldsEnum'
import EditHistoryAction from '../../AutomationViewer/EditHistoryAction'

export interface IEditHistoryModalProps {
  automationId: string
}

const EditHistoryModal: FC<IEditHistoryModalProps> = (props) => {
  const { automationId } = props
  const editHistoryLoader = useRecoilValueLoadable(EditHistory({ automationId }))
  const [showEditHistoryModal, setShowEditHistoryModal] = useRecoilState(ShowEditHistoryModal)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [userSort, setUserSort] = useState('none')
  const [fieldSort, setFieldSort] = useState('none')
  const [dateSort, setDateSort] = useState('desc')
  type sortFields = 'user' | 'field' | 'action' | 'date'

  const handleClose = () => {
    setShowEditHistoryModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
  ]

  const tableHeaders = [
    {
      label: 'User',
      sort: userSort,
      onSort: (sortString: string) => handleSort(sortString, 'user'),
    },

    {
      label: 'Fields',
      sort: fieldSort,
      onSort: (sortString: string) => handleSort(sortString, 'field'),
    },
    {
      label: 'Action',
    },
    {
      label: 'Date Change',
      sort: dateSort,
      onSort: (sortString: string) => handleSort(sortString, 'date'),
    },
  ] as IHeader[]

  const handleSort = useCallback((sortString: string, type: sortFields) => {
    setUserSort(type === 'user' ? sortString : 'none')
    setFieldSort(type === 'field' ? sortString : 'none')
    setDateSort(type === 'date' ? sortString : 'none')
  }, [])

  const editHistorySorted = useMemo(() => {
    const history =
      editHistoryLoader.state === 'hasValue' && editHistoryLoader.contents ? [...editHistoryLoader.contents] : []

    if (userSort !== 'none') {
      return history.sort((a: IEditHistory, b: IEditHistory) => {
        const sort = userSort === 'asc' ? 1 : -1
        return a.CreatedBy.UserFullName?.localeCompare(b.CreatedBy.UserFullName) * sort
      })
    }

    if (fieldSort !== 'none') {
      return history.sort((a: IEditHistory, b: IEditHistory) => {
        const sort = fieldSort === 'asc' ? 1 : -1
        return a.FieldName.localeCompare(b.FieldName) * sort
      })
    }

    if (dateSort !== 'none') {
      return history.sort((a: IEditHistory, b: IEditHistory) => {
        const sort = dateSort === 'asc' ? 1 : -1
        return (
          dateToString(a.CreateDate, 'MM/dd/yyyy HH:mm a')?.localeCompare(
            dateToString(b.CreateDate, 'MM/dd/yyyy HH:mm a'),
          ) * sort
        )
      })
    }

    return history
  }, [dateSort, editHistoryLoader.contents, editHistoryLoader.state, fieldSort, userSort])

  const pageCount =
    editHistoryLoader.state === 'loading' || !editHistoryLoader.contents
      ? 0
      : editHistoryLoader.contents.length / PAGE_SIZE
  const maxPages = Math.ceil(pageCount > 0 ? pageCount : 1)

  return (
    <Modal
      isShowing={showEditHistoryModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.permissionHistoryModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Edit History</h2>
        </div>
        <div className={Style.modalContent}>
          <div className={Style.tableContainer}>
            {editHistoryLoader.state === 'loading' ? (
              <LoadingScreen size='medium' text='Loading...' />
            ) : (
              <Table cssClass={Style.usersTable} headers={tableHeaders}>
                {editHistorySorted.length > 0 ? (
                  editHistorySorted
                    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                    .map((history: IEditHistory) => (
                      <TableRow key={indexKey()} rowClass={Style.tableRow}>
                        <TableCell cellClass={Style.userName}>{history.CreatedBy.UserFullName}</TableCell>
                        <TableCell cellClass={Style.userEmail}>
                          {AutomationFormFieldsLabel[history.FieldName as keyof typeof AutomationFormFieldsLabel]}
                        </TableCell>
                        <TableCell cellClass={Style.action}>
                          <EditHistoryAction historyData={history}></EditHistoryAction>
                        </TableCell>
                        <TableCell cellClass={Style.userName}>
                          {dateToString(history.CreateDate, 'MM/dd/yyyy hh:mm a')}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow rowClass={Style.tableRow}>
                    <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                      No available data
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            )}
          </div>
          {editHistoryLoader.state !== 'loading' && (
            <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={maxPages} />
          )}
        </div>
      </>
    </Modal>
  )
}

export default EditHistoryModal
