import { Button, IButtonProps, Loader, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/EditFeaturedModal.module.sass'
import { ShowEditTopFeaturedModal, ShowReplaceAutomationModal } from '../../../stores/SettingsStore'
import FileUploader from '../../UploadAutomation/FileUploader'
import { IGetAutomationResponse } from '../../../models/api/IAutomationRequest'
import { imageFileTypes } from '../../../helpers/files'
import { IFeaturedAutomations, IFeaturedDetails } from '../../../models/api/IFeaturedAutomations'
import JoditTextEditor from '../JoditTextEditor'
import { FeaturedContent } from '../../../stores/AutomationViewerStore'
import { getTextFromHTML } from '../../../helpers/utils'

export interface ITopFeaturedAutomationProps {
  featuredData: IFeaturedAutomations
  onTopFeaturedUpdate: (data: IFeaturedDetails) => void
  onCancel: () => void
}

const EditTopFeaturedModal: FC<ITopFeaturedAutomationProps> = (props) => {
  const { featuredData, onTopFeaturedUpdate, onCancel } = props
  const [showEditTopModal, setShowEditTopModal] = useRecoilState(ShowEditTopFeaturedModal)
  const setShowReplaceModal = useSetRecoilState(ShowReplaceAutomationModal)
  const featuredContentLoader = useRecoilValueLoadable(FeaturedContent({ id: String(featuredData.top?.automation.Id) }))
  const [automation, setAutomation] = useState<IGetAutomationResponse>()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [editorContent, setEditorContent] = useState<string>('')

  useEffect(() => {
    const topData = featuredData.top
    if (!topData) return
    setAutomation(topData.automation)
    if (topData.bannerFile) setSelectedFile(topData.bannerFile)
    setEditorContent(topData.content ?? '')
  }, [featuredData])

  useEffect(() => {
    if (featuredContentLoader.state === 'loading') return

    if (featuredContentLoader.state === 'hasValue') {
      setEditorContent(featuredContentLoader.contents ?? '')
    }
  }, [featuredContentLoader])

  const handleClose = () => {
    setShowEditTopModal(false)
    onCancel()
  }

  const handleSave = () => {
    if (!automation) return
    onTopFeaturedUpdate({
      automation,
      content: editorContent,
      bannerFile: selectedFile,
      bannerUrl: featuredData.top?.bannerUrl,
    })

    setShowEditTopModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'secondary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Save',
      type: 'primary',
      onClick: handleSave,
      cssClass: Style.primaryButton,
      disabled: !selectedFile || !getTextFromHTML(editorContent),
    },
  ]

  return (
    <Modal
      isShowing={showEditTopModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.modalContainer}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.modalHeader}>
          <h2 className={Style.iconRow}>Edit Top Automation?</h2>
        </div>
        <div className={Style.modalContent}>
          <span>
            Update the thumbnail and message that will visually represent the featured automation on the home page.
          </span>
          <div className={Style.topContainer}>
            <div className={Style.detailsContainer}>
              <span className={Style.detailsLabel}>Automation Title</span>
              <span className={Style.detailsText}>{automation?.Title}</span>
            </div>
            <div className={Style.replaceButtonContainer}>
              <Button
                type='custom'
                cssClass={Style.primaryButton}
                label='Replace Automation'
                onClick={() => {
                  setShowReplaceModal(true)
                  setShowEditTopModal(false)
                }}
              />
            </div>
          </div>
          <div className={Style.fileUploadContainer}>
            <span className={Style.detailsLabel}>Upload Thumbnail *</span>
            <div className={Style.topFeaturedDropboxContainer}>
              <FileUploader
                maxFiles={1}
                allowedFileTypes={imageFileTypes}
                selectedFiles={selectedFile ? [selectedFile] : []}
                setSelectedFiles={(value) => {
                  setSelectedFile(value[0])
                }}
                cancelUpload={() => {
                  setSelectedFile(null)
                }}
                isDisabled={false}
                automationSourceId={0}
                showPreview={true}
                fileUrl={featuredData.top?.bannerUrl}
              />
            </div>
          </div>
          <div className={Style.featuredEditorContainer}>
            <span className={Style.detailsLabel}>Message *</span>
            <div className={Style.editorContainer}>
              {featuredContentLoader.state === 'loading' ? (
                <Loader />
              ) : (
                <JoditTextEditor content={editorContent} onChanged={(data) => setEditorContent(data)} />
              )}
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default EditTopFeaturedModal
