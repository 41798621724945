export const AppRoutes = {
  DISCOVER: 'discover',
  MANAGE: 'manage',
  UPLOAD_AUTOMATION: 'upload-automation',
  APPLICATION_INSIGHTS: 'application-insights',
  UPLOAD: 'upload',
  SEARCH: 'search',
  FEATURED: 'featured',
  VIEW_AUTOMATION: 'view',
  TERMS_AND_CONDITION: 'termsandconditions',
  PRIVACY: 'privacy',
  PROFILE: 'profile',
  SETTINGS: 'settings',
}
