import { FC, useCallback, useState } from 'react'
import Style from '../../../styles/Comments.module.sass'
import { Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { addNewComment } from '../../../api/AutomationService'
import { useSetRecoilState } from 'recoil'
import { AutomationCommentsHistoryRefresh, AutomationCommentsRefresh } from '../../../stores/AutomationViewerStore'
import MentionTextArea from '../../common/MentionTextArea'
import { convertToRaw, EditorState, RawDraftEntity } from 'draft-js'
import { IAutomationCommentRequest } from '../../../models/api/IAutomationCommentModel'

interface CommentFormProps {
  automationId: string
  automationTitle: string
  automationType: string
  isReply?: boolean
  threadId?: string
}

const CommentForm: FC<CommentFormProps> = (props: CommentFormProps) => {
  const { addToast } = useToast()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isFormActive, setIsFormActive] = useState<boolean>(false)
  const [touched, setTouched] = useState(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const refreshComments = useSetRecoilState(AutomationCommentsRefresh)
  const refreshCommentsHistory = useSetRecoilState(AutomationCommentsHistoryRefresh)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const hasFormErrors = Object.keys(formErrors).length !== 0

  const handlePostClick = useCallback(async () => {
    if (hasFormErrors) {
      return
    }

    const mentions = Object.values(convertToRaw(editorState.getCurrentContent()).entityMap)
      .filter((entity: RawDraftEntity) => entity.type === 'mention')
      .map((entity: RawDraftEntity) => entity.data.mention)
      .reduce((acc, mention) => {
        if (!acc.some((m: typeof mentions) => m.id === mention.id)) {
          acc.push(mention)
        }
        return acc
      }, [])

    const data: IAutomationCommentRequest = {
      automationId: props.automationId,
      automationTitle: props.automationTitle,
      automationType: props.automationType,
      commentText: editorState.getCurrentContent().getPlainText(),
      threadId: props.threadId,
      mentions,
    }

    setIsSubmitting(true)
    const response = await addNewComment(data)
    if (!response?.success) {
      addToast({
        type: 'error',
        message: 'Failed to post comment',
        timeout: 5000,
      })
      setIsSubmitting(false)
      return
    }

    addToast({
      type: 'success',
      message: 'Your comment has been posted',
      timeout: 5000,
    })
    setTouched(false)
    setEditorState(EditorState.createEmpty())
    setIsSubmitting(false)
    refreshComments((n) => n + 1)
    refreshCommentsHistory((n) => n + 1)
  }, [
    addToast,
    editorState,
    hasFormErrors,
    props.automationId,
    props.automationTitle,
    props.automationType,
    props.threadId,
    refreshComments,
    refreshCommentsHistory,
  ])

  return (
    <div className={Style.commentFormContainer}>
      <MentionTextArea
        limit={500}
        isReply={props.isReply}
        editorState={editorState}
        setEditorState={setEditorState}
        setFormErrors={setFormErrors}
        setIsFormActive={setIsFormActive}
        setTouched={setTouched}
      />
      {touched && formErrors.comment && <div className={Style.inputError}>{formErrors.comment}</div>}
      <div className={isFormActive ? Style.commentButtonsGreenPrimary : Style.commentButtonsSecondary}>
        <Button
          type='default'
          size='medium'
          disabled={hasFormErrors || isSubmitting || editorState.getCurrentContent().getPlainText().length === 0}
          label={isSubmitting ? 'Posting' : 'Post'}
          cssClass={Style.post}
          onClick={handlePostClick}
        />
      </div>
    </div>
  )
}

export default CommentForm
