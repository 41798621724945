import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Button, DateInput, IDateInputDates, IHeader, useToast } from '@aurecon-creative-technologies/styleguide'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { Loadable } from 'recoil'
import AppInsightsBarChart from './AppInsightsBarChart'
import { IAppInsightsData } from '../../models/api/IApplicationInsights'
import { BarGraphSettings, INSIGHTS_MAX_PAGE_SIZE, LAUNCH_DATE } from '../../config/config'
import CustomPagination from '../common/CustomPagination'
import { dateToString, filterArrayByDate, sortArray } from '../../helpers/utils'
import { ApplicationsLabel } from '../../enums/ApplicationInsightsEnums'
import AppInsightsTable from './AppInsightsTable'
import { ResponseData } from '../../models/api/IResponse'
import { exportDownloadsInsights } from '../../api/AppService'

interface IDownloadProps {
  downloadInsightsLoadable: Loadable<IAppInsightsData>
}

const TABLE_HEADER_USERNAME = 'username'
const TABLE_HEADER_TITLE = 'title'
const TABLE_HEADER_APPLICATION = 'application'
const TABLE_HEADER_DATE = 'date'

const Downloads: React.FC<IDownloadProps> = (props) => {
  const { addToast, removeToast } = useToast()
  const [titleSort, setTitleSort] = useState('none')
  const [usernameSort, setUsernameSort] = useState('none')
  const [applicationSort, setApplicationSort] = useState('none')
  const [dateSort, setDateSort] = useState('none')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const downloadInsights = props.downloadInsightsLoadable
  const canDownloadInsights = useUserPermission(actions.DOWNLOAD_INSIGHTS)
  const [downloads, setDownloads] = useState<IAppInsightsData>({
    automationCentre: [],
    scriptLibrary: [],
    totalCount: 0,
  })
  const [dateFilter, setDateFilter] = useState<IDateInputDates>({
    startDate: null,
    endDate: null,
  })
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if (downloadInsights.state === 'hasValue') {
      setDownloads(downloadInsights.contents)
    }
  }, [downloadInsights])

  useEffect(() => {
    const allDownloads = [...downloads.automationCentre, ...(downloads.scriptLibrary ?? [])]
    if (allDownloads.length === 0) return
    const sortedDownloads = sortArray(allDownloads, 'dateCreated', 'asc')
    setDateFilter({
      startDate: new Date(sortedDownloads[0]?.dateCreated),
      endDate: new Date(),
    })
  }, [downloads.automationCentre, downloads.scriptLibrary])

  const handleSort = useCallback((sortString: string, type: string) => {
    setTitleSort(type === TABLE_HEADER_TITLE ? sortString : 'none')
    setUsernameSort(type === TABLE_HEADER_USERNAME ? sortString : 'none')
    setApplicationSort(type === TABLE_HEADER_APPLICATION ? sortString : 'none')
    setDateSort(type === TABLE_HEADER_DATE ? sortString : 'none')
  }, [])

  const downloadsSorted = useMemo(() => {
    const allDownloads = [...downloads.automationCentre, ...(downloads.scriptLibrary ?? [])]
    const downloadsFilter =
      !dateFilter.startDate && !dateFilter.endDate
        ? allDownloads
        : filterArrayByDate(allDownloads, dateFilter, 'dateCreated')

    if (titleSort !== 'none') return sortArray(downloadsFilter, 'title', titleSort)
    if (usernameSort !== 'none') return sortArray(downloadsFilter, 'author.UserFullName', usernameSort)
    if (applicationSort !== 'none') return sortArray(downloadsFilter, 'application', applicationSort)
    if (dateSort !== 'none') return sortArray(downloadsFilter, 'dateCreated', dateSort)
    return downloadsFilter
  }, [
    downloads.automationCentre,
    downloads.scriptLibrary,
    dateFilter,
    titleSort,
    usernameSort,
    applicationSort,
    dateSort,
  ])

  const barData = useMemo(() => {
    return [
      {
        name: ApplicationsLabel.AUTOMATIONCENTRE,
        value:
          downloadsSorted.filter((download) => download.application === ApplicationsLabel.AUTOMATIONCENTRE).length ?? 0,
        fill: BarGraphSettings.BarGraphColours.AutomationCentre,
      },
      // temporarily hide the script library data while waiting for the changes from SL side
      // {
      //   name: ApplicationsLabel.SCRIPTLIBRARY,
      //   value:
      //     downloadsSorted.filter((download) => download.application === ApplicationsLabel.SCRIPTLIBRARY).length ?? 0,
      //   fill: BarGraphSettings.BarGraphColours.ScriptLibrary,
      // },
    ]
  }, [downloadsSorted])

  const pageCount = downloadsSorted.length / INSIGHTS_MAX_PAGE_SIZE
  const maxPages = Math.ceil(pageCount > 0 ? pageCount : 1)

  const tableHeaders = [
    {
      label: 'Automation Title',
      sort: titleSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_TITLE),
    },
    {
      label: 'Author',
      sort: usernameSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_USERNAME),
    },
    {
      label: 'Application',
      sort: applicationSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_APPLICATION),
    },
    {
      label: 'Date of Download',
      sort: dateSort,
      onSort: (sortString: string) => handleSort(sortString, TABLE_HEADER_DATE),
    },
  ] as IHeader[]

  const handleCSVDownload = useCallback(async () => {
    const infoToast = addToast({
      type: 'info',
      message: `Generating CSV file, please wait...`,
    })
    setIsDownloading(true)
    const startDate = dateFilter.startDate ?? new Date(LAUNCH_DATE)
    const endDate = dateFilter.endDate ?? new Date()
    const filename = `Total Downloads-${dateToString(startDate, 'ddMMyyyy')} to ${dateToString(
      endDate,
      'ddMMyyyy',
    )}.csv`
    const csv = ResponseData(
      await exportDownloadsInsights({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }),
    )

    if (!csv) {
      removeToast(infoToast)
      addToast({
        type: 'error',
        message: `Failure to export, please try again`,
        timeout: 5000,
      })
      setIsDownloading(false)
      return
    }

    removeToast(infoToast)
    addToast({
      type: 'success',
      message: `Download data has been exported as CSV file.`,
      timeout: 5000,
    })

    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    link.download = filename
    link.click()
    link.remove()
    setIsDownloading(false)
  }, [addToast, dateFilter.endDate, dateFilter.startDate, removeToast])

  return (
    <div className={PageStyle.appInsightsContainer}>
      <div className={PageStyle.appInsightsHeader}>
        <div className={PageStyle.appInsightsTitle}>
          <div className={PageStyle.title}>Downloads</div>
        </div>
        <div className={PageStyle.actionContainer}>
          <div className={PageStyle.dateRange}>
            <div className={PageStyle.dateRangeTitle}> Date Range </div>
            <DateInput dates={dateFilter} rangeType cssClass={PageStyle.dateRangeInput} onDateChange={setDateFilter} />
          </div>
          {canDownloadInsights && (
            <Button
              cssClass={PageStyle.customDownloadButton}
              type='custom'
              icon='file_download'
              onClick={handleCSVDownload}
              disabled={isDownloading || downloadsSorted.length === 0}
            />
          )}
        </div>
      </div>
      <div className={PageStyle.appInsightsContents}>
        <div className={PageStyle.appInsightsGraph}>
          <div className={PageStyle.totalCount}>{downloadsSorted.length}</div>
          <AppInsightsBarChart isLoading={downloadInsights.state === 'loading'} barData={barData}></AppInsightsBarChart>
        </div>
        <div className={PageStyle.appInsightsTable}>
          <AppInsightsTable
            isLoading={downloadInsights.state === 'loading'}
            tableData={downloadsSorted}
            tableHeaders={tableHeaders}
            currentPage={currentPage}
          ></AppInsightsTable>
          <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={maxPages} />
        </div>
      </div>
    </div>
  )
}

export default Downloads
