import { IButtonProps, IHeader, Modal, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import Style from '../../../styles/common/modals/CommentHistoryModal.module.sass'
import LoadingScreen from '../../LoadingScreen'
import { dateToString, indexKey } from '../../../helpers/utils'
import { AutomationCommentHistory, ShowCommentsHistoryModal } from '../../../stores/AutomationViewerStore'
import { ICommentHistory } from '../../../models/api/IAutomationCommentModel'
import Linkify from '../Linkify'

export interface ICommentsHistoryModalProps {
  automationId: string
}

const CommentsHistoryModal: FC<ICommentsHistoryModalProps> = (props) => {
  const { automationId } = props
  const commentHistoryLoader = useRecoilValueLoadable(AutomationCommentHistory({ automationId }))
  const [showCommentsHistoryModal, setShowCommentsHistoryModal] = useRecoilState(ShowCommentsHistoryModal)
  const [userSort, setUserSort] = useState('none')
  const [commentTextSort, setCommentTextSort] = useState('none')
  const [actionSort, setActionSort] = useState('none')
  const [dateSort, setDateSort] = useState('desc')
  type sortFields = 'user' | 'commentText' | 'action' | 'date'

  const handleClose = () => {
    setShowCommentsHistoryModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
  ]

  const tableHeaders = [
    {
      label: 'User',
      sort: userSort,
      onSort: (sortString: string) => handleSort(sortString, 'user'),
    },

    {
      label: 'Comments',
      sort: commentTextSort,
      onSort: (sortString: string) => handleSort(sortString, 'commentText'),
    },
    {
      label: 'Action',
      sort: actionSort,
      onSort: (sortString: string) => handleSort(sortString, 'action'),
    },
    {
      label: 'Date Change',
      sort: dateSort,
      onSort: (sortString: string) => handleSort(sortString, 'date'),
    },
  ] as IHeader[]

  const handleSort = useCallback((sortString: string, type: sortFields) => {
    setUserSort(type === 'user' ? sortString : 'none')
    setCommentTextSort(type === 'commentText' ? sortString : 'none')
    setActionSort(type === 'action' ? sortString : 'none')
    setDateSort(type === 'date' ? sortString : 'none')
  }, [])

  const editHistorySorted = useMemo(() => {
    const history =
      commentHistoryLoader.state === 'hasValue' && commentHistoryLoader.contents
        ? [...commentHistoryLoader.contents]
        : []

    if (userSort !== 'none') {
      return history.sort((a: ICommentHistory, b: ICommentHistory) => {
        const sort = userSort === 'asc' ? 1 : -1
        return a.UserName?.localeCompare(b.UserName) * sort
      })
    }

    if (commentTextSort !== 'none') {
      return history.sort((a: ICommentHistory, b: ICommentHistory) => {
        const sort = commentTextSort === 'asc' ? 1 : -1
        return a.CommentText?.localeCompare(b.CommentText) * sort
      })
    }

    if (actionSort !== 'none') {
      return history.sort((a: ICommentHistory, b: ICommentHistory) => {
        const sort = actionSort === 'asc' ? 1 : -1
        return a.Action.localeCompare(b.Action) * sort
      })
    }

    if (dateSort !== 'none') {
      return history.sort((a: ICommentHistory, b: ICommentHistory) => {
        const sort = dateSort === 'asc' ? 1 : -1
        return (
          dateToString(a.DateChanged, 'MM/dd/yyyy HH:mm a')?.localeCompare(
            dateToString(b.DateChanged, 'MM/dd/yyyy HH:mm a'),
          ) * sort
        )
      })
    }

    return history
  }, [commentHistoryLoader.state, commentHistoryLoader.contents, userSort, commentTextSort, actionSort, dateSort])

  return (
    <Modal
      isShowing={showCommentsHistoryModal}
      isCloseButton={false}
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.permissionHistoryModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Comments History</h2>
        </div>
        <div className={Style.modalContent}>
          <div className={Style.tableContainer}>
            {commentHistoryLoader.state === 'loading' ? (
              <LoadingScreen size='medium' text='Loading...' />
            ) : (
              <Table cssClass={Style.usersTable} headers={tableHeaders}>
                {editHistorySorted.length > 0 ? (
                  editHistorySorted.map((history: ICommentHistory) => (
                    <TableRow key={indexKey()} rowClass={Style.tableRow}>
                      <TableCell cellClass={Style.userName}>{history.UserName}</TableCell>
                      <TableCell cellClass={Style.commentText}>
                        <Linkify text={history.CommentText} className={Style.linkified} />
                      </TableCell>
                      <TableCell cellClass={Style.action}>{history.Action}</TableCell>
                      <TableCell cellClass={Style.userName}>
                        {dateToString(history.DateChanged, 'MM/dd/yyyy hh:mm a')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow rowClass={Style.tableRow}>
                    <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                      No available data
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default CommentsHistoryModal
