import { Button, FormInput, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useMemo, useState } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil'
import { automationDetailsRefresh } from '../../stores/AutomationViewerStore'
import { AppRoutes } from '../../enums/AppRoutes'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import { confirmActionRequired } from '../../api/AutomationService'
import {
  ActiveManageTabState,
  ApprovedAutomationsAndScripts,
  MyContributions,
  PendingApprovalList,
} from '../../stores/ManageAutomationStore'
import { TabsEnum } from '../../enums/Manage'
import { User } from '@auth0/auth0-react'
import { authorisationFormFields, authorisationFormSchema } from '../../validators/AuthorisationFormValidator'
import { formatValidationResult } from '../../helpers/commonFunctions'
import classNames from 'classnames'

interface ConfirmActionFormProps {
  automation: IGetAutomationResponse
  user?: User
}

const handleCancel = () => {
  location.hash = `#/${AppRoutes.MANAGE}`
}

const ConfirmActionForm: FC<ConfirmActionFormProps> = (props: ConfirmActionFormProps) => {
  const { automation, user } = props
  const toast = useToast()
  const [comment, setComment] = useState<string>('')
  const refreshManagePageApprovals = useRecoilRefresher_UNSTABLE(PendingApprovalList)
  const refreshManagePageContributions = useRecoilRefresher_UNSTABLE(MyContributions)
  const refreshDiscoverPageAutomations = useRecoilRefresher_UNSTABLE(ApprovedAutomationsAndScripts)
  const automationStateCounter = useSetRecoilState(automationDetailsRefresh)
  const setActiveManageTab = useSetRecoilState(ActiveManageTabState)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const refreshAutomationDetails = () => automationStateCounter((num) => num + 1)

  const hasFormErrors = Object.keys(formErrors).length !== 0
  const isActionDisabled = !comment.trim() || hasFormErrors || isSubmitting
  const canPostComment = useMemo(() => automation.AutomationAuthor?.UserEmail === user?.email, [automation, user])

  const onCommentValueChange = (value: string) => {
    setComment(value)
    const result = authorisationFormSchema.validate({ comment: value })
    if (!result.error) {
      setFormErrors({})
      return
    }

    const formattedErrors = formatValidationResult(result.error)
    setFormErrors(formattedErrors ?? {})
  }

  const handleConfirmAction = async () => {
    if (formErrors[authorisationFormFields.comment]) return
    setIsSubmitting(true)
    const response = await confirmActionRequired({
      automationId: automation.Id,
      comment: comment,
    })
    setIsSubmitting(false)
    if (response?.success) {
      setComment('')

      refreshManagePageApprovals()
      refreshManagePageContributions()
      if (automation.AutomationApprovals?.length === 2) refreshDiscoverPageAutomations()
      refreshAutomationDetails()
      setActiveManageTab(TabsEnum.APPROVALS)
      location.hash = `#/${AppRoutes.MANAGE}`
    } else {
      toast.addToast({
        message: `Error: ${response?.message}`,
        type: 'error',
        timeout: 3000,
      })
    }
  }

  const commentClasses = classNames({
    [Style.commentTextArea]: true,
    [Style.hasError]: !!formErrors[authorisationFormFields.comment],
  })

  return (
    <>
      <div className={Style.divider} />
      <div className={Style.comments}>
        <FormInput
          type='multiline'
          required
          label='Comments'
          placeholder='Enter comment here...'
          cssClass={commentClasses}
          value={comment}
          disabled={!canPostComment}
          onChange={onCommentValueChange}
          multilineLimit={1000}
          error={formErrors[authorisationFormFields.comment]}
        />

        <div className={Style.commentButtons}>
          <Button type='default' size='medium' label='Cancel' cssClass={Style.cancel} onClick={handleCancel} />
          <Button
            type='default'
            size='medium'
            label={isSubmitting ? 'Confirming Action' : 'Confirm Action'}
            icon='reply'
            cssClass={Style.confirmAction}
            onClick={handleConfirmAction}
            disabled={isActionDisabled}
          />
        </div>
      </div>
    </>
  )
}

export default ConfirmActionForm
