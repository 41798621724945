export enum FeaturedAutomationsEnum {
  TOP = 1,
  LEFT = 2,
  RIGHT = 3,
}

export const FeaturedAutomationsLabels = {
  [FeaturedAutomationsEnum.TOP]: 'Top',
  [FeaturedAutomationsEnum.LEFT]: 'Left',
  [FeaturedAutomationsEnum.RIGHT]: 'Right',
}
