import { FC } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { BarGraphSettings } from '../../config/config'
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export interface IBarChartData {
  name: string
  value: number
  fill: string
}
interface BarChartProps {
  isLoading: boolean
  barData: IBarChartData[]
}

const AppInsightsBarChart: FC<BarChartProps> = (props) => {
  const { isLoading, barData } = props

  const legendPayload = barData.map((data) => ({
    value: data.name,
    type: 'square' as const,
    color: data.fill,
  }))

  if (isLoading) {
    return (
      <div className={PageStyle.loadingGraph}>
        <Loader size='small' />
      </div>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={510}>
      <BarChart data={barData} barSize={BarGraphSettings.BarSize}>
        <Legend verticalAlign='top' payload={legendPayload} align='left' height={BarGraphSettings.LegendHeight} />

        <Tooltip />
        <YAxis tick={false} width={BarGraphSettings.AxisWidth} padding={BarGraphSettings.YAxisPadding} />
        <XAxis dataKey={'name'} tick={false} width={BarGraphSettings.AxisWidth} />

        <Bar dataKey='value' fill='fill'>
          <LabelList
            dataKey='value'
            position='top'
            offset={BarGraphSettings.LabelListOffset}
            fill={BarGraphSettings.LabelListFill}
            formatter={(value: number) => (value > 0 ? value : '')}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default AppInsightsBarChart
