import { useState } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Legend, Pie, PieChart, ResponsiveContainer, Sector, SectorProps } from 'recharts'
import { Props } from 'recharts/types/component/DefaultLegendContent'
import { indexKey } from '../../helpers/utils'
import { ExpandIcon } from '@aurecon-creative-technologies/styleguide'
import { IUniqueUsersByCountryResponse } from '../../models/api/IApplicationInsights'
import { ActiveShape } from 'recharts/types/util/types'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'

type IRenderActiveShapeProps = SectorProps & {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
  payload: { country: string }
  percent: number
  value: number
}

type Entry = {
  value: string
  color: string
  total: number
  states: { state: string; count: number }[]
}

interface IUniqueUsersProps {
  uniqueUsersByCountry?: IUniqueUsersByCountryResponse[]
  activeIndex: number
  setActiveIndex: (index: number) => void
}

const renderActiveShape = (props: IRenderActiveShapeProps) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 20) * cos
  const my = cy + (outerRadius + 20) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />

      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  )
}

type IRenderLegendProps = Props & {
  setActiveIndex: (index: number) => void
}

const RenderLegend: React.FC<IRenderLegendProps> = (props) => {
  const { payload } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null)

  return (
    <div className={PageStyle.legendList}>
      {(payload as Entry[])?.map((entry: Entry, index: number) => {
        return (
          <>
            <div
              key={indexKey()}
              className={PageStyle.legend}
              onMouseEnter={() => props.setActiveIndex(index)}
              role='none'
            >
              <div className={PageStyle.value}>
                <svg
                  className='recharts-surface'
                  width='14'
                  height='14'
                  viewBox='0 0 32 32'
                  style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}
                >
                  <path
                    fill={entry.color}
                    cx='16'
                    cy='16'
                    className='recharts-symbols'
                    transform='translate(16, 16)'
                    d='M-16,-16h32v32h-32Z'
                  ></path>
                </svg>
                <ExpandIcon
                  open={isExpanded && selectedCountry === entry.value}
                  onClick={() => {
                    setIsExpanded(!isExpanded)
                    setSelectedCountry(entry.value)
                  }}
                />
                <span className={PageStyle.legendCountry}>{entry.value}</span>
              </div>
              <span className={PageStyle.legendCount}>{entry.total}</span>
            </div>
            <div className={PageStyle.legendAccordion}>
              {isExpanded &&
                selectedCountry === entry.value &&
                entry.states.map((state: { state: string; count: number }) => (
                  <div key={indexKey()} className={PageStyle.row}>
                    <div className={PageStyle.value}>{state.state}</div>
                    <div className={PageStyle.count}>{state.count}</div>
                  </div>
                ))}
            </div>
          </>
        )
      })}
    </div>
  )
}

const UniqueUsersPieChart: React.FC<IUniqueUsersProps> = ({ uniqueUsersByCountry, activeIndex, setActiveIndex }) => {
  return uniqueUsersByCountry?.length === 0 ? (
    <div className={PageStyle.noData}>No available data</div>
  ) : (
    <ResponsiveContainer width='100%' height='100%' className={PageStyle.uniqueUsersPieGraph}>
      <PieChart width={300} height={300}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape as ActiveShape<PieSectorDataItem>}
          data={uniqueUsersByCountry}
          cx='50%'
          cy='50%'
          innerRadius={50}
          outerRadius={80}
          fill='fill'
          dataKey='totalCount'
          onMouseEnter={(_, index) => setActiveIndex(index)}
        />
        <Legend
          verticalAlign='top'
          align='right'
          layout='vertical'
          className={PageStyle.legendWrapper}
          wrapperStyle={{ top: 0, padding: '10px', overflowY: 'auto' }}
          payload={uniqueUsersByCountry?.map((country) => ({
            value: country.country,
            type: 'square',
            color: country.fill,
            total: country.totalCount,
            states: country.states,
          }))}
          content={<RenderLegend setActiveIndex={setActiveIndex} />}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default UniqueUsersPieChart
