import { FC, useEffect, useState } from 'react'
import PageStyle from '../styles/UploadGuidance.module.sass'
import {
  Container,
  FormInput,
  Icon,
  IHeader,
  Loader,
  Table,
  TableCell,
  TableRow,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { trimProtocol, validateUrl } from '../helpers/utils'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from 'recoil'
import { AllSources } from '../stores/AutomationViewerStore'
import { IAutomationSources } from '../models/api/IAutomationRequest'
import classNames from 'classnames'
import { updateAutomationSource } from '../api/AutomationService'
import { HasDirtyForm } from '../stores/AppStore'
import { AutomationSourcesID } from '../enums/AutomationSources'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import ErrorPage from './ErrorPage'

interface ISourcesField extends IAutomationSources {
  isEditing?: boolean
  errorMessage?: string
  isSaving?: boolean
}

const UploadGuidance: FC = () => {
  const sourcesLoader = useRecoilValueLoadable(AllSources)
  const [automationSources, setAutomationSources] = useState<ISourcesField[]>([])
  const [hasDirtyForm, setHasDirtyForm] = useRecoilState(HasDirtyForm)
  const canEditUploadGuidance = useUserPermission(actions.EDIT_UPLOAD_GUIDANCE)
  const refreshSources = useRecoilRefresher_UNSTABLE(AllSources)

  const { addToast } = useToast()

  useEffect(() => {
    if (sourcesLoader.state !== 'hasValue' || !sourcesLoader.contents) return
    setAutomationSources(sourcesLoader.contents.filter((source) => source.Id !== AutomationSourcesID.STREAMLINER))
  }, [sourcesLoader.contents, sourcesLoader.state])

  useEffect(() => {
    const isDirty = automationSources.some((source) => source.isEditing && !source.isSaving)
    setHasDirtyForm(isDirty)
  }, [automationSources, setHasDirtyForm])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasDirtyForm) event?.preventDefault()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [hasDirtyForm])

  const VALID_PROTOCOL = 'https://'
  const tableHeaders = [
    {
      label: 'Sources',
    },
    {
      label: 'Upload URL',
    },
    {
      label: '',
    },
  ] as IHeader[]

  const setEditing = (data: ISourcesField) => {
    setAutomationSources((prevItems) =>
      prevItems.map((item) => (item.Id === data.Id ? { ...item, isEditing: true } : item)),
    )
  }

  const handleUrlUpdate = (data: ISourcesField, urlText: string) => {
    const dataUrl = trimProtocol(urlText)
    const isValid = urlText ? validateUrl(`${VALID_PROTOCOL}${dataUrl}`) : true

    setAutomationSources((prevItems) =>
      prevItems.map((item) =>
        item.Id === data.Id
          ? { ...item, UploadGuidanceUrl: dataUrl, errorMessage: !isValid ? 'Invalid URL' : undefined }
          : item,
      ),
    )
  }

  const handleSaveUrl = async (data: ISourcesField) => {
    if (data.errorMessage) return
    setAutomationSources((prevItems) =>
      prevItems.map((item) => (item.Id === data.Id ? { ...item, isSaving: true } : item)),
    )

    const newUrl = data.UploadGuidanceUrl ? trimProtocol(data.UploadGuidanceUrl) : null

    const result = await updateAutomationSource({
      id: Number(data.Id),
      name: data.Name,
      uploadGuidanceUrl: newUrl ? `${VALID_PROTOCOL}${newUrl}` : null,
    })

    if (result.success) {
      addToast({
        type: 'success',
        message: `Upload Guidance has been saved.`,
        timeout: 5000,
      })
      refreshSources()
    } else {
      addToast({
        type: 'error',
        message: `Update failed`,
        timeout: 5000,
      })
    }

    setAutomationSources((prevItems) =>
      prevItems.map((item) =>
        item.Id === data.Id
          ? {
              ...item,
              UploadGuidanceUrl: newUrl ? `${VALID_PROTOCOL}${newUrl}` : null,
              isSaving: false,
              isEditing: false,
            }
          : item,
      ),
    )
  }

  if (!canEditUploadGuidance) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />

  return (
    <div className={PageStyle.ApproverManagementContainer}>
      <Container cssClass={PageStyle.layoutContainer} fluid>
        <div className={PageStyle.tableContainer}>
          <Table headers={tableHeaders}>
            {automationSources.length > 0 ? (
              automationSources.map((data: ISourcesField) => (
                <TableRow key={data.Id} rowClass={PageStyle.tableRow}>
                  <TableCell cellClass={PageStyle.sourceColumn}>{data.Name}</TableCell>
                  {data.isEditing ? (
                    <>
                      <TableCell cellClass={PageStyle.urlColumn}>
                        <div
                          className={classNames({
                            [PageStyle.uploadUrlFieldContainer]: true,
                            [PageStyle.hasError]: !!data.errorMessage,
                          })}
                        >
                          <span>{VALID_PROTOCOL}</span>
                          <FormInput
                            placeholder='Type or paste a URL here'
                            cssClass={PageStyle.uploadUrlField}
                            value={trimProtocol(data.UploadGuidanceUrl ?? '')}
                            onChange={(value) => handleUrlUpdate(data, value)}
                            iconOnRight={false}
                            disabled={data.isSaving}
                          />
                        </div>
                        {data.errorMessage && <p className={PageStyle.errorMessage}>{data.errorMessage}</p>}
                      </TableCell>
                      <TableCell cellClass={PageStyle.actionColumn}>
                        {data.isSaving ? (
                          <div className={PageStyle.actionContainer}>
                            <Loader size='extra small' />
                          </div>
                        ) : (
                          <div className={PageStyle.actionContainer}>
                            <Icon
                              type='save'
                              size='medium'
                              cssClass={classNames({
                                [PageStyle.iconButton]: true,
                                [PageStyle.iconDisabled]: !!data.errorMessage,
                              })}
                              onClick={() => handleSaveUrl(data)}
                            />
                          </div>
                        )}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <div>
                          <span>{data.UploadGuidanceUrl}</span>
                        </div>
                      </TableCell>
                      <TableCell cellClass={PageStyle.actionColumn}>
                        <div className={PageStyle.actionContainer}>
                          <Icon
                            type='edit'
                            size='medium'
                            cssClass={PageStyle.iconButton}
                            onClick={() => setEditing(data)}
                          />
                        </div>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow rowClass={PageStyle.tableRow}>
                <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                  <Loader label={'Loading...'} />
                </TableCell>
              </TableRow>
            )}
          </Table>
        </div>
      </Container>
    </div>
  )
}

export default UploadGuidance
