import { AppRoles } from '../enums/AppRoles'

export const ALL_ROLES = [
  AppRoles.SystemAdmin,
  AppRoles.DigitalApprover,
  AppRoles.ITApprover,
  AppRoles.TechnicalApprover,
  AppRoles.StandardUser,
]

export const ALL_APPROVERS = [AppRoles.DigitalApprover, AppRoles.ITApprover, AppRoles.TechnicalApprover]

export const actions = {
  VIEW_ATC_HOME_PAGE: 0,
  VIEW_FEATURED_AUTOMATION: 1,
  SEARCH_AUTOMATION: 2,
  VIEW_AUTOMATION_DETAILS: 3,
  DOWNLOAD_AUTOMATION_FILE: 4,
  VIEW_APPROVAL_LIST: 5,
  VIEW_ALL_AUTOMATION_APPROVALS: 6,
  VIEW_CONTRIBUTION_LIST: 7,
  SEARCH_MANAGE_AUTOMATION: 8,
  UPLOAD_AUTOMATION: 9,
  EDIT_AUTOMATION: 10,
  POST_APPROVER_COMMENT: 11,
  AUTOMATION_REVIEW_REJECT: 12,
  AUTOMATION_REVIEW_APPROVE: 13,
  AUTOMATION_REVIEW_REQUIRE_ACTION: 14,
  AUTOMATION_REVIEW_ACCEPT_FOR_TRACKING: 15,
  DEACTIVATE_OWN_AUTOMATION: 16,
  DEACTIVATE_OTHER_AUTOMATION: 17,
  VIEW_DEACTIVATED_AUTOMATIONS: 18,
  REACTIVATE_OWN_AUTOMATION: 19,
  REACTIVATE_OTHER_AUTOMATION: 20,
  DELETE_OWN_AUTOMATION: 21,
  DELETE_OTHER_AUTOMATION: 22,
  REVERT_APPROVAL_STATUS: 23,
  VIEW_USER_LIST: 24,
  ADD_NEW_APPROVER: 25,
  CHANGE_APPROVER_ROLE: 26,
  REMOVE_APPROVER_ROLE: 27,
  VIEW_PERMISSION_HISTORY: 28,
  VIEW_LEARN_PAGE: 29,
  VIEW_AUTOMATION_NETWORK_PAGE: 30,
  VIEW_PROPELLER_PAGE: 31,
  VIEW_RELEASE_NOTE_PAGE: 32,
  VIEW_FAQ_PAGE: 33,
  VIEW_PROFILE_PAGE: 34,
  CHANGE_APPROVAL: 35,
  VIEW_APPLICATION_INSIGHTS: 36,
  VIEW_DOWNLOADS_INSIGHTS: 37,
  VIEW_UNIQUE_USERS_INSIGHTS: 38,
  VIEW_PUBLISHED_AUTOMATIONS: 39,
  DOWNLOAD_INSIGHTS: 40,
  VIEW_SUBMISSIONS_INSIGHTS: 41,
  VIEW_SINCE_LAUNCHED_INSIGHTS: 42,
  VIEW_PENDING_AUTOMATION_DETAILS: 43,
  DELETE_OTHERS_COMMENT: 44,
  EDIT_PUBLISHED_AUTOMATIONS: 45,
  CAN_VIEW_EDIT_HISTORY: 46,
  EDIT_IN_REVIEW_AUTOMATIONS: 47,
  VIEW_SETTINGS_PAGE: 48,
  EDIT_UPLOAD_GUIDANCE: 49,
  VIEW_FEATURED_AUTOMATION_SETTINGS: 50,
  VIEW_COMMENT_HISTORY: 51,
  EDIT_FEATURED_AUTOMATION: 52,
}

export const permissions = {
  [actions.VIEW_ATC_HOME_PAGE]: ALL_ROLES,
  [actions.VIEW_FEATURED_AUTOMATION]: ALL_ROLES,
  [actions.SEARCH_AUTOMATION]: ALL_ROLES,
  [actions.VIEW_AUTOMATION_DETAILS]: ALL_ROLES,
  [actions.DOWNLOAD_AUTOMATION_FILE]: ALL_ROLES,
  [actions.VIEW_APPROVAL_LIST]: [
    AppRoles.SystemAdmin,
    AppRoles.DigitalApprover,
    AppRoles.ITApprover,
    AppRoles.TechnicalApprover,
  ],
  [actions.VIEW_ALL_AUTOMATION_APPROVALS]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.VIEW_CONTRIBUTION_LIST]: ALL_ROLES,
  [actions.SEARCH_MANAGE_AUTOMATION]: ALL_ROLES,
  [actions.UPLOAD_AUTOMATION]: ALL_ROLES,
  [actions.EDIT_AUTOMATION]: ALL_ROLES,
  [actions.POST_APPROVER_COMMENT]: [AppRoles.DigitalApprover, AppRoles.ITApprover, AppRoles.TechnicalApprover],
  [actions.AUTOMATION_REVIEW_REJECT]: [AppRoles.DigitalApprover, AppRoles.ITApprover, AppRoles.TechnicalApprover],
  [actions.AUTOMATION_REVIEW_APPROVE]: [AppRoles.DigitalApprover, AppRoles.ITApprover, AppRoles.TechnicalApprover],
  [actions.AUTOMATION_REVIEW_REQUIRE_ACTION]: [
    AppRoles.DigitalApprover,
    AppRoles.ITApprover,
    AppRoles.TechnicalApprover,
  ],
  [actions.AUTOMATION_REVIEW_ACCEPT_FOR_TRACKING]: [AppRoles.DigitalApprover],
  [actions.DEACTIVATE_OWN_AUTOMATION]: ALL_ROLES,
  [actions.DEACTIVATE_OTHER_AUTOMATION]: [AppRoles.SystemAdmin],
  [actions.VIEW_DEACTIVATED_AUTOMATIONS]: ALL_ROLES,
  [actions.REACTIVATE_OWN_AUTOMATION]: ALL_ROLES,
  [actions.REACTIVATE_OTHER_AUTOMATION]: [AppRoles.SystemAdmin],
  [actions.DELETE_OWN_AUTOMATION]: ALL_ROLES,
  [actions.DELETE_OTHER_AUTOMATION]: [AppRoles.SystemAdmin],
  [actions.REVERT_APPROVAL_STATUS]: [AppRoles.SystemAdmin],
  [actions.VIEW_USER_LIST]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.ADD_NEW_APPROVER]: [AppRoles.SystemAdmin],
  [actions.CHANGE_APPROVER_ROLE]: [AppRoles.SystemAdmin],
  [actions.REMOVE_APPROVER_ROLE]: [AppRoles.SystemAdmin],
  [actions.VIEW_PERMISSION_HISTORY]: [AppRoles.SystemAdmin],
  [actions.VIEW_LEARN_PAGE]: ALL_ROLES,
  [actions.VIEW_AUTOMATION_NETWORK_PAGE]: ALL_ROLES,
  [actions.VIEW_PROPELLER_PAGE]: ALL_ROLES,
  [actions.VIEW_RELEASE_NOTE_PAGE]: ALL_ROLES,
  [actions.VIEW_FAQ_PAGE]: ALL_ROLES,
  [actions.VIEW_PROFILE_PAGE]: ALL_ROLES,
  [actions.CHANGE_APPROVAL]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.VIEW_APPLICATION_INSIGHTS]: ALL_ROLES,
  [actions.VIEW_DOWNLOADS_INSIGHTS]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.VIEW_UNIQUE_USERS_INSIGHTS]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.VIEW_PUBLISHED_AUTOMATIONS]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.DOWNLOAD_INSIGHTS]: ALL_ROLES,
  [actions.VIEW_SUBMISSIONS_INSIGHTS]: ALL_ROLES,
  [actions.VIEW_SINCE_LAUNCHED_INSIGHTS]: ALL_ROLES,
  [actions.VIEW_PENDING_AUTOMATION_DETAILS]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.DELETE_OTHERS_COMMENT]: [AppRoles.SystemAdmin],
  [actions.EDIT_PUBLISHED_AUTOMATIONS]: [AppRoles.SystemAdmin],
  [actions.CAN_VIEW_EDIT_HISTORY]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.EDIT_IN_REVIEW_AUTOMATIONS]: [AppRoles.SystemAdmin, AppRoles.DigitalApprover],
  [actions.VIEW_SETTINGS_PAGE]: [AppRoles.SystemAdmin, ...ALL_APPROVERS],
  [actions.EDIT_UPLOAD_GUIDANCE]: [AppRoles.SystemAdmin],
  [actions.VIEW_FEATURED_AUTOMATION_SETTINGS]: [AppRoles.SystemAdmin],
  [actions.VIEW_COMMENT_HISTORY]: [AppRoles.SystemAdmin],
  [actions.EDIT_FEATURED_AUTOMATION]: [AppRoles.SystemAdmin],
}
