import { Icon } from '@aurecon-creative-technologies/styleguide'
import { FC, ReactElement, ReactNode } from 'react'
import Style from '../../styles/common/modals/PermissionHistoryModal.module.sass'
import { IAutomationClientDTO, IEditHistory } from '../../models/api/IAutomationRequest'
import { IUser } from '../../models/IUser'
import { AutomationFormFieldsEnum } from '../../enums/AutomationFormFieldsEnum'
import { PlatformInterfaces } from '../../enums/PlatformInterfaces'
import { TagTypes } from '../../enums/TagTypes'
import { ITagProps, tagType } from '../Tag'
import { CapabilityLabels } from '../../enums/Capabilities'
import { renderTags } from '../AutomationCard'
import { Regions } from '../../enums/Regions'
import { Practises } from '../../enums/Practises'

interface IEditHistoryActionProps {
  historyData: IEditHistory
}

export const AutomationUserFields: string[] = [
  AutomationFormFieldsEnum.AutomationOriginators,
  AutomationFormFieldsEnum.AutomationVerifiers,
]

export const AutomationSingleFields: string[] = [
  AutomationFormFieldsEnum.CurrentActivityTime,
  AutomationFormFieldsEnum.PlatformInterfaceId,
  AutomationFormFieldsEnum.Title,
  AutomationFormFieldsEnum.AutomatedActivityTime,
]

export const AutomationPillFields: string[] = [
  AutomationFormFieldsEnum.AutomationCapabilities,
  AutomationFormFieldsEnum.AutomationRegions,
  AutomationFormFieldsEnum.AutomationPractices,
]

export const AutomationLongFields: string[] = [
  AutomationFormFieldsEnum.Description,
  AutomationFormFieldsEnum.GuidanceMaterialUrl,
  AutomationFormFieldsEnum.AutomationUrl,
  AutomationFormFieldsEnum.AutomationFileUpload,
  AutomationFormFieldsEnum.AutomationSourceId,
]

export const renderUserFields = (historyData: IEditHistory): ReactElement => {
  const newUsers = historyData.NewValue as IUser[]
  const oldUsers = historyData.OldValue as IUser[]
  const formattedNewUsers = formatItemNames(newUsers.map((data) => data.UserFullName))
  const formattedOldUsers = formatItemNames(oldUsers.map((data) => data.UserFullName))

  if (formattedNewUsers.length === 0 && formattedOldUsers.length > 0) {
    return <>{renderRemoveAction(<span>{formattedOldUsers}</span>)}</>
  }

  if (formattedNewUsers.length > 0 && formattedOldUsers.length === 0) {
    return <>{renderAddAction(<span>{formattedNewUsers}</span>)}</>
  }

  return <>{renderChangeAction(<span>{formattedOldUsers ?? ''}</span>, <span>{formattedNewUsers ?? ''}</span>)}</>
}

export const renderPrimaryAuthorField = (historyData: IEditHistory): ReactElement => {
  const newUsers = historyData.NewValue as IUser
  const oldUsers = historyData.OldValue as IUser

  return (
    <>{renderChangeAction(<span>{oldUsers.UserFullName ?? ''}</span>, <span>{newUsers.UserFullName ?? ''}</span>)}</>
  )
}

export const renderClientFields = (historyData: IEditHistory): ReactElement => {
  const newClients = historyData.NewValue as IAutomationClientDTO[]
  const oldClients = historyData.OldValue as IAutomationClientDTO[]
  const formattedNewClients = formatItemNames(newClients.map((data) => data.ClientName))
  const formattedOldClients = formatItemNames(oldClients.map((data) => data.ClientName))

  if (formattedNewClients.length === 0 && formattedOldClients.length > 0) {
    return <>{renderRemoveAction(<span>{formattedOldClients}</span>)}</>
  }

  if (formattedNewClients.length > 0 && formattedOldClients.length === 0) {
    return <>{renderAddAction(<span>{formattedNewClients}</span>)}</>
  }

  return <>{renderChangeAction(<span>{formattedOldClients ?? ''}</span>, <span>{formattedNewClients ?? ''}</span>)}</>
}

export const renderSingleFields = (historyData: IEditHistory): ReactElement => {
  let newValue = historyData.NewValue as string
  let oldValue = historyData.OldValue as string

  if (
    historyData.FieldName === AutomationFormFieldsEnum.CurrentActivityTime ||
    historyData.FieldName === AutomationFormFieldsEnum.AutomatedActivityTime
  ) {
    newValue = `${newValue} minutes`
    oldValue = `${oldValue} minutes`
  }

  if (historyData.FieldName === AutomationFormFieldsEnum.PlatformInterfaceId) {
    newValue = PlatformInterfaces.find((data) => Number(newValue) === Number(data.key))?.value.toString() ?? ''
    oldValue = PlatformInterfaces.find((data) => Number(oldValue) === Number(data.key))?.value.toString() ?? ''
  }

  if (!oldValue && newValue) return <>{renderAddAction(<span>{newValue ?? ''}</span>)}</>
  if (oldValue && !newValue) return <>{renderRemoveAction(<span>{newValue ?? ''}</span>)}</>

  return <>{renderChangeAction(<span>{oldValue ?? ''}</span>, <span>{newValue ?? ''}</span>)}</>
}

export const renderPillFields = (historyData: IEditHistory): ReactElement => {
  const newValue = historyData.NewValue as string[]
  const oldValue = historyData.OldValue as string[]

  let fieldTagType: tagType = TagTypes.CAPABILITY
  if (historyData.FieldName === AutomationFormFieldsEnum.AutomationRegions) fieldTagType = TagTypes.REGION
  if (historyData.FieldName === AutomationFormFieldsEnum.AutomationPractices) fieldTagType = TagTypes.PRACTICE

  const oldFieldTags = mapTagTypes(oldValue, fieldTagType)
  const newFieldTags = mapTagTypes(newValue, fieldTagType)

  if (newFieldTags.length === 0 && oldFieldTags.length > 0) {
    return <>{renderRemoveAction(<span>{renderTags(oldFieldTags, true)}</span>)}</>
  }

  if (newFieldTags.length > 0 && oldFieldTags.length === 0) {
    return <>{renderAddAction(<span>{renderTags(newFieldTags, true)}</span>)}</>
  }

  return (
    <>
      {renderChangeAction(<span>{renderTags(oldFieldTags, true)}</span>, <span>{renderTags(newFieldTags, true)}</span>)}
    </>
  )
}

export const renderLongFieldsAction = (historyData: IEditHistory): JSX.Element => {
  let itemLabel = 'Link'
  if (historyData.FieldName === AutomationFormFieldsEnum.Description) itemLabel = 'Description'
  if (historyData.FieldName === AutomationFormFieldsEnum.AutomationFileUpload) itemLabel = 'File'
  if (historyData.FieldName === AutomationFormFieldsEnum.AutomationSourceId) itemLabel = 'Source'

  return <div className={Style.actionHistory}>{`Updated the ${itemLabel}`}</div>
}

export const renderAuthorisingCapability = (historyData: IEditHistory) => {
  const newValue = historyData.NewValue as number
  const oldValue = historyData.OldValue as number

  const newCapabilityTag: ITagProps[] = [
    {
      id: newValue.toString(),
      type: TagTypes.CAPABILITY,
      label: CapabilityLabels[newValue as keyof typeof CapabilityLabels],
    },
  ]

  const oldCapabilityTag: ITagProps[] = [
    {
      id: oldValue.toString(),
      type: TagTypes.CAPABILITY,
      label: CapabilityLabels[oldValue as keyof typeof CapabilityLabels],
    },
  ]

  return (
    <>
      {renderChangeAction(
        <span>{renderTags(oldCapabilityTag, true)}</span>,
        <span>{renderTags(newCapabilityTag, true)}</span>,
      )}
    </>
  )
}

export const renderRemoveAction = (oldElement: ReactNode): JSX.Element => {
  return (
    <div className={Style.actionHistory}>
      <Icon type='remove' size='small' outlined />
      {oldElement}
    </div>
  )
}

export const renderAddAction = (newElement: ReactNode): JSX.Element => {
  return (
    <div className={Style.actionHistory}>
      <Icon type='add' size='small' outlined />
      {newElement}
    </div>
  )
}

export const renderChangeAction = (oldElement: ReactNode, newElement: ReactNode): JSX.Element => {
  return (
    <div className={Style.actionHistory}>
      {oldElement}
      <Icon type='arrow_forward' size='small' outlined />
      {newElement}
    </div>
  )
}

export const mapTagTypes = (tagData: string[], type: tagType): ITagProps[] => {
  const mappedData: ITagProps[] = []

  tagData.forEach((item) => {
    let itemLabel = CapabilityLabels[Number(item) as keyof typeof CapabilityLabels]
    if (type === TagTypes.REGION) itemLabel = Regions.find((data) => data.key === item)?.value.toString() ?? ''
    if (type === TagTypes.PRACTICE) itemLabel = Practises.find((data) => data.key === item)?.value.toString() ?? ''

    mappedData.push({
      id: item.toString(),
      type,
      label: itemLabel,
    })
  })

  return mappedData
}

const formatItemNames = (userData: string[]) => {
  const formattedNames = userData
    .reduce((acc, name, index, arr) => acc + (index === arr.length - 1 ? `${name}` : `${name}, `), '')
    .trim()

  return formattedNames
}

const EditHistoryAction: FC<IEditHistoryActionProps> = (props) => {
  const { historyData } = props

  return (
    <div>
      {AutomationSingleFields.includes(historyData.FieldName) && renderSingleFields(historyData)}
      {AutomationPillFields.includes(historyData.FieldName) && renderPillFields(historyData)}
      {AutomationUserFields.includes(historyData.FieldName) && renderUserFields(historyData)}
      {historyData.FieldName === AutomationFormFieldsEnum.AutomationClients && renderClientFields(historyData)}
      {historyData.FieldName === AutomationFormFieldsEnum.ApprovingCapability &&
        renderAuthorisingCapability(historyData)}
      {AutomationLongFields.includes(historyData.FieldName) && renderLongFieldsAction(historyData)}
      {historyData.FieldName === AutomationFormFieldsEnum.PrimaryAuthor && renderPrimaryAuthorField(historyData)}
    </div>
  )
}

export default EditHistoryAction
