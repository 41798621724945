import {
  Button,
  FormInput,
  IButtonProps,
  Icon,
  IHeader,
  Modal,
  Pill,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/ReplaceFeaturedModal.module.sass'
import {
  FeaturedAutomationsData,
  ShowEditBottomFeaturedModal,
  ShowEditTopFeaturedModal,
  ShowReplaceAutomationModal,
} from '../../../stores/SettingsStore'
import { IGetAutomationResponse, ILookUp, ISearchAutomationFilters } from '../../../models/api/IAutomationRequest'
import { dateToString, indexKey } from '../../../helpers/utils'
import { SearchAutomationFilters, SearchCurrentPage } from '../../../stores/AppStore'
import AutomationFilter from '../../AutomationFilter'
import {
  ApprovedAutomationsAndScripts,
  AutomationSearchResultCount,
  SearchAutomationCriteria,
} from '../../../stores/ManageAutomationStore'
import LoadingScreen from '../../LoadingScreen'
import { SEARCH_PAGE_SIZE } from '../../../config/config'
import { AppRoutes } from '../../../enums/AppRoutes'
import { AutomationUploadTypes } from '../../../enums/AutomationUploadTypes'
import { TagTypes } from '../../../enums/TagTypes'
import { FeaturedAutomationsEnum } from '../../../enums/FeaturedAutomationsEnum'

export interface IReplaceFeaturedModalProps {
  editingFeatured: number
}

const ReplaceFeaturedModal: FC<IReplaceFeaturedModalProps> = (props) => {
  const { editingFeatured } = props
  const [showReplaceModal, setShowReplaceModal] = useRecoilState(ShowReplaceAutomationModal)
  const approvedAutomationScriptsLoader = useRecoilValueLoadable(ApprovedAutomationsAndScripts)
  const [automations, setAutomations] = useState<IGetAutomationResponse[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchCurrentPage, setSearchCurrentPage] = useRecoilState(SearchCurrentPage)
  const [searchResultCount, setSearchResultCount] = useRecoilState(AutomationSearchResultCount)
  const [searchAutomationCriteria, setSearchAutomationCriteria] = useRecoilState(SearchAutomationCriteria)
  const [searchAutomationFilters, setSearchAutomationFilters] = useRecoilState(SearchAutomationFilters)
  const [selectedAutomation, setSelectedAutomation] = useState<IGetAutomationResponse | null>(null)
  const setShowEditTopModal = useSetRecoilState(ShowEditTopFeaturedModal)
  const setShowEditBottomModal = useSetRecoilState(ShowEditBottomFeaturedModal)
  const [featuredAutomations, setFeaturedAutomations] = useRecoilState(FeaturedAutomationsData)

  const hasNextPage =
    searchResultCount.atcTotalCount >= SEARCH_PAGE_SIZE ||
    searchResultCount.scriptLibraryTotalCount >= SEARCH_PAGE_SIZE ||
    searchResultCount.streamlinerTotalCount >= SEARCH_PAGE_SIZE

  useEffect(() => {
    if (approvedAutomationScriptsLoader.state === 'loading') {
      setLoading(true)
      return
    }
    if (approvedAutomationScriptsLoader.state !== 'hasValue' || !approvedAutomationScriptsLoader.contents) return
    setLoading(false)
    if (!Array.isArray(approvedAutomationScriptsLoader.contents)) {
      const { items, atcTotalCount, scriptLibraryTotalCount, streamlinerTotalCount } =
        approvedAutomationScriptsLoader.contents

      const featuredIds = new Set([
        featuredAutomations.top?.automation.Id,
        featuredAutomations.left?.automation.Id,
        featuredAutomations.right?.automation.Id,
      ])

      const filteredArray = items.filter((item) => !featuredIds.has(item.Id))

      setAutomations(filteredArray)
      setSearchResultCount({
        atcTotalCount: atcTotalCount ?? 0,
        scriptLibraryTotalCount: scriptLibraryTotalCount ?? 0,
        streamlinerTotalCount: streamlinerTotalCount ?? 0,
      })
    }
  }, [
    approvedAutomationScriptsLoader.contents,
    approvedAutomationScriptsLoader.state,
    featuredAutomations.left?.automation.Id,
    featuredAutomations.right?.automation.Id,
    featuredAutomations.top?.automation.Id,
    setSearchResultCount,
  ])

  const handleLink = () => {
    if (!selectedAutomation) return
    const data = {
      automation: selectedAutomation,
      content: '',
      bannerFile: null,
      thumbnailFile: null,
      bannerUrl: '',
      thumbnailUrl: '',
    }
    if (editingFeatured === FeaturedAutomationsEnum.LEFT) {
      setFeaturedAutomations({
        ...featuredAutomations,
        left: data,
      })
    } else if (editingFeatured === FeaturedAutomationsEnum.RIGHT) {
      setFeaturedAutomations({
        ...featuredAutomations,
        right: data,
      })
    } else {
      setFeaturedAutomations({
        ...featuredAutomations,
        top: data,
      })
    }
    handleClose()
  }

  const handleClose = () => {
    setShowReplaceModal(false)
    if (editingFeatured === FeaturedAutomationsEnum.TOP) setShowEditTopModal(true)
    else setShowEditBottomModal(true)
    handleClearFilter()
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'secondary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Link',
      type: 'primary',
      onClick: handleLink,
      cssClass: Style.primaryButton,
      disabled: !selectedAutomation,
    },
  ]

  const tableHeaders = [
    {
      label: '',
    },

    {
      label: 'Automation Title',
    },
    {
      label: 'Sources',
    },
    {
      label: 'Date of Creation',
    },
    {
      label: '',
    },
  ] as IHeader[]

  const handleSearch = (event: string) => {
    if (event === 'Enter') {
      setSearchAutomationCriteria({
        ...searchAutomationCriteria,
        searchText,
        currentPage: 1,
      })
      setSearchCurrentPage(1)
    }
  }

  const handleChange = (text: string) => {
    setSearchText(text)
    if (!text) {
      setSearchAutomationCriteria({
        searchText: '',
        currentPage: 1,
      })
      setSearchCurrentPage(1)
    }
  }

  const handleNextPage = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      currentPage: searchCurrentPage + 1,
      includeATC: searchResultCount.atcTotalCount >= SEARCH_PAGE_SIZE,
      includeSL: searchResultCount.scriptLibraryTotalCount >= SEARCH_PAGE_SIZE,
      includeStreamliner: searchResultCount.streamlinerTotalCount >= SEARCH_PAGE_SIZE,
    })
    setSearchCurrentPage(searchCurrentPage + 1)
  }

  const handlePreviousPage = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      currentPage: searchCurrentPage - 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(searchCurrentPage - 1)
  }

  const handleApplyFilter = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      filters: searchAutomationFilters as ISearchAutomationFilters,
      currentPage: 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(1)
  }

  const handleClearFilter = () => {
    setSearchAutomationCriteria({
      ...searchAutomationCriteria,
      filters: undefined,
      currentPage: 1,
      includeATC: true,
      includeSL: true,
      includeStreamliner: true,
    })
    setSearchCurrentPage(1)
    setSearchText('')
    setSearchAutomationFilters({
      [TagTypes.SOURCE]: [],
      [TagTypes.CAPABILITY]: [],
      [TagTypes.PRACTICE]: [],
      [TagTypes.REGION]: [],
    })
  }

  const handleOpenLink = (selected: IGetAutomationResponse) => {
    if (selected.UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`, '_blank')
      return
    }

    if (selected.UploadTypeId === AutomationUploadTypes.STREAMLINER) {
      window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}/${AutomationUploadTypes.STREAMLINER}`, '_blank')
      return
    }

    window.open(`#/${AppRoutes.VIEW_AUTOMATION}/${selected.Id}`, '_blank')
  }

  const handleAutomationChange = (data: IGetAutomationResponse) => {
    if (data.Id === selectedAutomation?.Id) setSelectedAutomation(null)
    else setSelectedAutomation(data)
  }

  return (
    <Modal
      isShowing={showReplaceModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.modalContainer}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.modalHeader}>
          <h2 className={Style.iconRow}>Replace Automation?</h2>
        </div>
        <div className={Style.modalContent}>
          <span>Select an automation that will be featured on users home page.</span>
          <div className={Style.searchfilterContainer}>
            <div className={Style.searchBox}>
              <FormInput
                type='text'
                placeholder={`Search Automation here...`}
                onChange={(e) => handleChange(e)}
                value={searchText}
                onKeyDown={(e) => handleSearch(e)}
                cssClass={Style.searchInput}
              />
              <Icon className={Style.icon} type='search' outlined />
            </div>
          </div>
          <AutomationFilter
            cssClass={Style.filters}
            filterState={SearchAutomationFilters}
            showApplyButton={true}
            handleApplyFilter={handleApplyFilter}
            handleClearFilter={handleClearFilter}
          />
          {loading && (
            <div className={Style.loadingContainer}>
              <LoadingScreen size='large' text={'Loading automations'} />
            </div>
          )}
          {!loading && (
            <>
              <Table cssClass={Style.usersTable} headers={tableHeaders}>
                {automations.length > 0 ? (
                  automations.map((data: IGetAutomationResponse) => (
                    <TableRow key={data.Id} rowClass={Style.tableRow}>
                      <TableCell cellClass={Style.radioColumn}>
                        <label className={Style.customRadio}>
                          <input
                            type='radio'
                            onClick={() => handleAutomationChange(data)}
                            checked={selectedAutomation?.Id === data.Id}
                            onChange={() => {
                              return
                            }}
                          />
                          <span className={Style.checkmark}></span>{' '}
                        </label>
                      </TableCell>
                      <TableCell cellClass={Style.titleColumn}>{data.Title}</TableCell>
                      <TableCell cellClass={Style.sourceColumn}>
                        {data.AutomationSources?.map((source: ILookUp) => {
                          return (
                            <Pill
                              key={indexKey()}
                              style={{ width: 'max-content' }}
                              cssClass={Style.pill}
                              size='mini'
                              colour={14}
                            >
                              {source.Name}
                            </Pill>
                          )
                        })}
                      </TableCell>
                      <TableCell cellClass={Style.dateColumn}>{dateToString(data.DateCreated, 'MM/dd/yyyy')}</TableCell>
                      <TableCell cellClass={Style.actionColumn}>
                        <div className={Style.actionContainer}>
                          <Icon
                            type='launch'
                            size='medium'
                            cssClass={Style.iconButton}
                            onClick={() => handleOpenLink(data)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow rowClass={Style.tableRow}>
                    <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                      No available data
                    </TableCell>
                  </TableRow>
                )}
              </Table>
              <div className={Style.sectionTitle}>
                <div className={Style.pageReference}>
                  <p>Page {searchCurrentPage}</p>
                </div>
                <div className={Style.pageButtonsContainer}>
                  <Button
                    onClick={handlePreviousPage}
                    type='icon-round-secondary'
                    size='medium'
                    icon='keyboard_arrow_left'
                    disabled={searchCurrentPage === 1}
                    cssClass={Style.pageButtons}
                  />
                  <Button
                    onClick={handleNextPage}
                    type='icon-round-secondary'
                    size='medium'
                    icon='keyboard_arrow_right'
                    disabled={!hasNextPage}
                    cssClass={Style.pageButtons}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Modal>
  )
}

export default ReplaceFeaturedModal
