import { deleteAsync, getAsync, postAsync, putAsync } from '../helpers/apiRequest'
import { IUser } from '../models/IUser'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import {
  IGetApproverResponse,
  IGetPermissionHistoryResponse,
  IPostAssignRoleRequest,
  IPutEditRoleRequest,
  IRegisterAuthUserRequest,
  IRegisterAuthUserResponse,
  IRegisterUserRequest,
  IRegisterUserResponse,
  IRemoveApproverRequest,
} from '../models/api/IUserRequestModel'
import { TokenExpiryWrapper } from './TokenManager'

export const postRegisterUser = TokenExpiryWrapper(
  async (request: IRegisterUserRequest): Promise<IResponse<IRegisterUserResponse>> => postAsync(`/v1/user`, request),
  [],
  null,
)

export const searchUsers = TokenExpiryWrapper(
  (request: IRegisterAuthUserRequest): Promise<IResponse<IRegisterAuthUserResponse[]>> => {
    return getAsync(`/v1/user/search/${request.email}`, request)
  },
  [],
  null,
)

export const getCurrentUser = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IUser>> => {
    return getAsync(`/v1/user/current`, request)
  },
  [],
  null,
)

export const getAllApprovers = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IGetApproverResponse[]>> => {
    return getAsync(`v1/user/approvers`, request)
  },
  [],
  null,
)

export const assignRoleToUser = TokenExpiryWrapper(
  (request: IPostAssignRoleRequest): Promise<IResponse<{ message: string }>> => {
    return postAsync(`/v1/user/role`, request)
  },
  [],
  {
    success: false,
    message: 'Failed to assign role',
  },
)

export const getPermissionHistory = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IGetPermissionHistoryResponse[]>> => {
    return getAsync(`v1/user/permission-history`, request)
  },
  [],
  null,
)

export const editUserRole = TokenExpiryWrapper(
  (request: IPutEditRoleRequest): Promise<IResponse<{ message: string }>> => {
    return putAsync(`/v1/user/role`, request)
  },
  [],
  {
    success: false,
    message: 'Failed to assign role',
  },
)

export const removeApprover = TokenExpiryWrapper(
  (request: IRemoveApproverRequest): Promise<IResponse<{ message: string }>> => {
    return deleteAsync(`/v1/user/roles`, request)
  },
  [],
  {
    success: false,
    message: 'Failed to remove approver',
  },
)
