import { IButtonProps, Modal, Dropdown, useToast, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/AddNewApproverModal.module.sass'
import { AllApprovers, PermissionHistory, ShowAddNewApproverModal } from '../../../stores/UserStore'
import ApproverComboBox from '../../ApproverComboBox'
import { AppRolesEnum } from '../../../config/config'
import { AppRoleName, ApproverRoleName } from '../../../enums/AppRoles'
import { assignRoleToUser } from '../../../api/UserService'
import { addNewApproverFormSchema } from '../../../validators/AddNewApproverFormValidator'
import classNames from 'classnames'
import { getUserCapability } from '../../../api/CapabilityService'

export interface IAddNewApproverModalProps {
  capabilities?: IDropdownItemProps[]
}

const AddNewApproverModal: FC<IAddNewApproverModalProps> = (props) => {
  const [showAddNewApproverModal, setShowAddNewApproverModal] = useRecoilState(ShowAddNewApproverModal)
  const resetAllApproverList = useRecoilRefresher_UNSTABLE(AllApprovers)
  const resetPermissionHistory = useRecoilRefresher_UNSTABLE(PermissionHistory)
  const [role, setRole] = useState('')
  const [user, setUser] = useState<string | number>()
  const [errors, setErrors] = useState<{ email: string; role: string; newCapability: string }>({
    email: '',
    role: '',
    newCapability: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loadingCapability, setLoadingCapability] = useState<boolean>(false)
  const [selectedCapability, setSelectedCapability] = useState('')
  const [previousCapability, setPreviousCapability] = useState('')
  const { capabilities } = props

  const { addToast } = useToast()

  const roleKeys = Object.keys(ApproverRoleName)
  const roleItems = roleKeys.map((key) => ({
    id: AppRolesEnum[key as keyof typeof AppRolesEnum],
    label: `${AppRoleName[AppRolesEnum[key as keyof typeof AppRolesEnum]]} Authoriser`,
  }))

  useEffect(() => {
    const fetchData = async (userEmail: string) => {
      try {
        setLoadingCapability(true)
        const response = await getUserCapability({ userEmail })
        if (response?.success) {
          const userCapability = capabilities?.find((cap) => cap.label === response.data?.capability)
          setSelectedCapability(userCapability?.id.toString() ?? '')
          setPreviousCapability(userCapability?.id.toString() ?? '')
        }
        setLoadingCapability(false)
      } catch (err) {
        return
      }
    }

    if (user) fetchData(user.toString())
  }, [capabilities, user])

  const handleClose = () => {
    setUser('')
    setRole('')
    setIsSubmitting(false)
    setShowAddNewApproverModal(false)
  }

  const handleSave = async () => {
    const { error } = addNewApproverFormSchema.validate(
      { email: user, role, newCapability: selectedCapability },
      {
        abortEarly: false,
      },
    )

    if (error) {
      setErrors({
        email: error.details.find((err) => err.path[0] === 'email')?.message || '',
        role: error.details.find((err) => err.path[0] === 'role')?.message || '',
        newCapability: error.details.find((err) => err.path[0] === 'newCapability')?.message || '',
      })
      return
    }

    setErrors({ email: '', role: '', newCapability: '' })
    setIsSubmitting(true)
    const result = await assignRoleToUser({
      email: user?.toString() ?? '',
      role: role,
      newCapability: selectedCapability,
      previousCapability,
    })

    if (!result.success) {
      addToast({
        type: 'error',
        message: 'Failed to assign role.',
        timeout: 4000,
        datetime: Date.now(),
      })
      return
    }

    resetAllApproverList()
    resetPermissionHistory()
    handleClose()
    addToast({
      type: 'success',
      message: 'Authoriser has been added.',
      timeout: 4000,
      datetime: Date.now(),
    })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: isSubmitting ? 'Saving' : 'Save',
      type: 'secondary',
      onClick: handleSave,
      cssClass: Style.primaryButton,
      disabled: isSubmitting,
    },
  ]

  const addModalClasses = classNames({
    [Style.addNewApproverModal]: true,
    [Style.addTechnicalApprovalModal]: role === AppRolesEnum.TECHNICAL,
  })

  return (
    <Modal
      isShowing={showAddNewApproverModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={addModalClasses}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Add Authoriser?</h2>
        </div>
        <div className={Style.modalContent}>
          <div className={Style.approverComboBoxContainer}>
            <ApproverComboBox
              label='User'
              placeholder='Search for User'
              required
              onSelectedItemsChange={(data) => setUser(data.id)}
            />
            {errors.email && <p className={Style.errorMessage}>{errors.email}</p>}
          </div>
          {role === AppRolesEnum.TECHNICAL && (
            <div className={Style.dropdownContainer}>
              <div className={Style.roleDrodownlabel}>Capability *</div>
              <Dropdown
                items={capabilities ?? []}
                selectedItem={selectedCapability}
                onSelectItem={(v) => setSelectedCapability(v.toString())}
                required
                placeholder={loadingCapability ? 'Loading...' : 'Select a Capability'}
                cssClass={Style.roleDropdown}
                disabled={loadingCapability}
              />
            </div>
          )}
          <div className={Style.dropdownContainer}>
            <div className={Style.roleDrodownlabel}>Role *</div>
            <Dropdown
              items={roleItems}
              selectedItem={role}
              onSelectItem={(v) => {
                setRole(v.toString())
                setSelectedCapability('')
              }}
              required
              placeholder='Select a Role'
              cssClass={Style.roleDropdown}
            />
          </div>
          {errors.role && <p className={Style.errorMessage}>{errors.role}</p>}
        </div>
      </>
    </Modal>
  )
}

export default AddNewApproverModal
