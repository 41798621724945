import React, { useState, useCallback, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'
import classNames from 'classnames'

import Style from '../styles/SingleUserComboBox.module.sass'
import { ComboBox, Grid, IOption } from '@aurecon-creative-technologies/styleguide'
import { ResponseData } from '../models/api/IResponse'
import { searchUsers } from '../api/UserService'
import { IUser } from '../models/IUser'

interface SingleUserComboBoxProps {
  id: string
  value: string
}

const SingleUserComboBox: React.FC<{
  currentUser: IUser | null
  onSelectedItemsChange: (user: IOption) => void
  required?: boolean
  disabled?: boolean
  placeholder?: string
}> = ({ currentUser, onSelectedItemsChange, required, disabled, placeholder }) => {
  const [val, setVal] = useState('')
  const [users, setUsers] = useState<SingleUserComboBoxProps[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const userRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!currentUser) return
    setVal(() => currentUser.AuthFederationId)
    setUsers([{ id: currentUser.AuthFederationId, value: `${currentUser.UserFullName} (${currentUser.UserEmail})` }])
  }, [currentUser])

  const handleAddUser = useCallback(
    (newOption: IOption | null) => {
      if (!newOption || !userRef.current) return
      onSelectedItemsChange(newOption)
      userRef.current.value = newOption.value
      setVal(newOption.value)
      userRef.current.blur()
    },
    [onSelectedItemsChange],
  )

  const onSearch = debounce(async (event: React.FormEvent<HTMLDivElement>) => {
    if (!userRef.current) return
    const value = (event.target as HTMLInputElement).value
    if (val === value) return
    setVal(value)
    userRef.current.value = value

    if (value.length < 3) {
      setUsers([])
      return
    }

    setIsLoading(true)
    const userList = ResponseData(await searchUsers({ email: value })) || []
    setUsers([...userList.map((u) => ({ id: u.auth0UserId ?? '', value: `${u.name} (${u.email})` }))])
    setIsLoading(false)
  }, 500)

  const comboBoxClasses = classNames({
    [Style.comboBox]: true,
    [Style.loading]: isLoading,
  })

  return (
    <Grid item xs={12} cssClass={Style.metadataRow}>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={comboBoxClasses} onChange={onSearch}>
          <ComboBox
            options={users}
            onSelect={handleAddUser}
            required={required}
            selected={val}
            disabled={disabled}
            cssClass={Style.comboBoxControl}
            openOnFocus
            placeholder={placeholder}
            ref={userRef}
            icon={isLoading ? 'sync' : undefined}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default SingleUserComboBox
