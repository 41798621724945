import { getAsync } from '../helpers/apiRequest'
import { ILookUp } from '../models/api/IAutomationRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { IWorkdayUserCapability, IWorkdayUserCapabilityRequest } from '../models/api/IWorkdayRequestModel'

import { TokenExpiryWrapper } from './TokenManager'

export const getAllCapabilities = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<ILookUp[]>> => getAsync(`/v1/capability/all`, request),
  [],
  null,
)

export const getUserCapability = TokenExpiryWrapper(
  (request: IWorkdayUserCapabilityRequest): Promise<IResponse<IWorkdayUserCapability>> => {
    return getAsync(`/v1/workday/user-capability/${request.userEmail}`, request)
  },
  [],
  null,
)
