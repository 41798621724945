import { FC, useEffect, useState } from 'react'
import Page from '../components/Page'
import { Button, Grid, Icon, Pill, useToast } from '@aurecon-creative-technologies/styleguide'
import EstimatedAverageTimeSaved from '../components/AutomationViewer/EstimatedAverageTimeSaved'
import AutomationInformation from '../components/AutomationViewer/AutomationInformation'
import DefaultCoverPhoto from '../assets/cover_default.png'
import Tag, { ITagProps } from '../components/Tag'
import { dateToString, dateTimeFormat, indexKey } from '../helpers/utils'
import { useParams } from 'react-router-dom'
import { IGetAutomationResponse, ILookUp } from '../models/api/IAutomationRequest'
import { AutomationType, AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { handleDownload } from '../helpers/commonFunctions'
import { TagTypes } from '../enums/TagTypes'
import Likes from '../components/Likes'
import Style from '../styles/FeaturedViewer.module.sass'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { CurrentUser } from '../stores/AppStore'
import Comments from '../components/AutomationViewer/comments/Comments'
import ErrorPage from './ErrorPage'
import { HttpStatusCodes } from '../enums/ApiRequestConstants'
import CommentsHistoryModal from '../components/common/modals/CommentsHistoryModal'
import { AllFeaturedAutomations, FeaturedContent } from '../stores/AutomationViewerStore'
import { IFeaturedAutomationResponse } from '../models/api/IFeaturedAutomations'
import LoadingScreen from '../components/LoadingScreen'
import { getAutomationAssets } from '../api/StorageService'
import { BLOB_FEATURED_CONTAINER } from '../config/config'
import { HeroAutomation, TemporaryFeaturedAutomations } from '../enums/TemporaryFeatured'

interface IFeaturedViewerProps {
  maxTags: number
}

const FeaturedViewer: FC<IFeaturedViewerProps> = (props) => {
  const currentUser = useRecoilValue(CurrentUser)

  const { addToast } = useToast()
  const { automationId } = useParams<{ automationId: string }>()
  const featuredContentLoader = useRecoilValueLoadable(FeaturedContent({ id: String(automationId) }))
  const [showCompleteTags, setShowCompleteTags] = useState<boolean>(false)
  const featuredLoader = useRecoilValueLoadable(AllFeaturedAutomations)
  const [automation, setAutomation] = useState<IFeaturedAutomationResponse>()
  const [isExternalSource, setIsExternalSource] = useState<boolean>(false)
  const [featuredContent, setFeaturedContent] = useState<string | null>()
  const [bannerUrl, setBannerUrl] = useState<string>()

  const canViewFeaturedAutomation = useUserPermission(actions.VIEW_FEATURED_AUTOMATION)
  const canViewCommentHistory = useUserPermission(actions.VIEW_COMMENT_HISTORY)

  useEffect(() => {
    const getBanner = async (fileUrl?: string) => {
      if (!fileUrl) {
        setBannerUrl(DefaultCoverPhoto)
        return
      }
      const url = await getAutomationAssets({
        container: BLOB_FEATURED_CONTAINER,
        blobName: fileUrl,
      })

      if (url) setBannerUrl(url)
      else setBannerUrl(DefaultCoverPhoto)
    }

    if (featuredLoader.state === 'loading') return

    if (featuredLoader.state === 'hasValue' && featuredLoader.contents) {
      const data = featuredLoader.contents.find((item) => item.Id === automationId)
      if (
        data?.UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY ||
        data?.UploadTypeId === AutomationUploadTypes.STREAMLINER
      ) {
        setIsExternalSource(true)
      }
      setAutomation(data)
      getBanner(data?.BannerUrl)
    } else {
      let automationData =
        automationId === HeroAutomation.Id
          ? HeroAutomation
          : TemporaryFeaturedAutomations.find((automation) => automation.Id === automationId)

      if (!automationData) automationData = HeroAutomation
      setAutomation(automationData)
      setBannerUrl(automationData?.BannerUrl)
    }
  }, [automationId, featuredLoader, setAutomation])

  useEffect(() => {
    if (featuredContentLoader.state === 'loading') return

    if (featuredContentLoader.state === 'hasValue') {
      setFeaturedContent(featuredContentLoader.contents)
    }
  }, [featuredContentLoader])

  if (!canViewFeaturedAutomation) return <ErrorPage errorCode={HttpStatusCodes.UNAUTHORISED} />
  const { maxTags } = props

  const now = new Date()
  const showToast = () => {
    addToast({
      type: 'success',
      message: 'Link Copied To Clipboard',
      timeout: 2000,
      datetime: Number(dateToString(now, dateTimeFormat)),
    })
  }

  const handleCopy = async () => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(currentUrl)
    showToast()
  }

  const renderTags = () => {
    const completeTagItems = []
    let limitedTagItems: ITagProps[] = []
    if (automation?.AutomationCapabilities)
      completeTagItems.push(...mapPillItems(automation?.AutomationCapabilities, TagTypes.CAPABILITY))
    if (automation?.AutomationSources)
      completeTagItems.push(...mapPillItems(automation?.AutomationSources, TagTypes.SOURCE))
    if (automation?.AutomationPractices)
      completeTagItems.push(...mapPillItems(automation?.AutomationPractices, TagTypes.PRACTICE))

    if (completeTagItems.length > maxTags) limitedTagItems = completeTagItems.splice(0, maxTags)

    const displayTags = !showCompleteTags && completeTagItems.length > maxTags ? limitedTagItems : completeTagItems

    const moreTags = completeTagItems.length - maxTags

    return (
      <div className={Style.tags}>
        {displayTags.map((item) => (
          <Tag id={item.id} type={item.type} size='medium' label={String(item.label)} key={indexKey()} />
        ))}
        {completeTagItems.length > maxTags && !showCompleteTags && (
          <Pill size='medium' cssClass={Style.displayPill} onClick={() => setShowCompleteTags(true)} icon={'add'}>
            Show {moreTags} more
          </Pill>
        )}
        {completeTagItems.length > maxTags && showCompleteTags && (
          <Pill size='medium' cssClass={Style.displayPill} onClick={() => setShowCompleteTags(false)} icon={'remove'}>
            Show less
          </Pill>
        )}
      </div>
    )
  }

  const mapPillItems = (data: ILookUp[], type: TagTypes.CAPABILITY | TagTypes.SOURCE | TagTypes.PRACTICE) => {
    const result: ITagProps[] = []
    data.forEach((item) => {
      result.push({
        id: item.Id.toString(),
        label: item.Name,
        type,
      })
    })

    return result
  }

  if (!automation && featuredContentLoader.state === 'loading')
    return (
      <Page menu>
        <LoadingScreen text='Loading featured automation...' />
      </Page>
    )

  return (
    <Grid row xs={12}>
      <div
        className={Style.cover}
        style={{
          backgroundImage: `url(${bannerUrl})`,
        }}
      >
        <Button
          type='icon-round-secondary'
          size='small'
          icon='chevron_left'
          onClick={() => history.back()}
          cssClass={Style.backButton}
        />

        <div className={Style.titleContainer}>
          <div className={Style.upperSection}>
            <div className={Style.title}>{automation?.Title}</div>
            {automation?.UploadTypeId === AutomationUploadTypes.UPLOAD_FILE ? (
              <div className={Style.downloadBtn} onClick={() => handleDownload(automation, currentUser)} role='none'>
                <Icon type='file_download' outlined />
              </div>
            ) : (
              <div className={Style.downloadBtn}>
                <Icon
                  type='open_in_new'
                  outlined
                  onClick={() => window.open(automation?.AutomationUrl?.Url, '_blank')}
                />
              </div>
            )}
          </div>
          {renderTags()}
        </div>
      </div>
      <div className={Style.details}>
        <Page contentWrapper>
          <div className={Style.copyContainer}>
            <Likes size='24px' id={automationId ?? ''} defaultUp={false} />
            <Icon type='copy' outlined onClick={handleCopy} cssClass={Style.copyIcon} />
          </div>

          {featuredContent && (
            <>
              <div className={Style.divider} />
              <div dangerouslySetInnerHTML={{ __html: featuredContent }} />
            </>
          )}

          <AutomationInformation showHeader showDivider automationDetails={automation as IGetAutomationResponse} />
          <EstimatedAverageTimeSaved automationDetails={automation as IGetAutomationResponse} />
          {automation && (
            <Comments
              automation={automation as IGetAutomationResponse}
              automationType={AutomationType.FEATURED}
              isExternalSource={isExternalSource}
            />
          )}
          {canViewCommentHistory && automation?.Id && <CommentsHistoryModal automationId={automation?.Id} />}
        </Page>
      </div>
    </Grid>
  )
}

export default FeaturedViewer
