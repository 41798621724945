import { Status, Statuses, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { AppRoleName } from '../../enums/AppRoles'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import { IAutomationApproval } from '../../models/api/IManageAutomationTableRow'
import Style from '../../styles/Manage.module.sass'
import { AutomationStateNames, AutomationStates } from '../../enums/AutomationStates'
import { StatusIconColours } from '../../config/config'
import { useCallback, useMemo } from 'react'

interface IGetStatusIcon {
  automation: Partial<IGetAutomationResponse>
  appRole: string
  size?: 'extra small' | 'small' | 'medium' | 'large'
  showApproverRole?: boolean
  showApproverName?: boolean
}

interface IApprovalStatus extends Pick<IGetStatusIcon, 'appRole' | 'size' | 'showApproverRole'> {
  hasApprovalState: IAutomationApproval
}

const ApprovalStatus = (props: IApprovalStatus) => {
  const { hasApprovalState, appRole, size, showApproverRole } = props
  switch (hasApprovalState.ApprovalState.Name) {
    case AutomationApprovalDisplayStates.ActionRequired:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.ActionRequired}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.ActionRequired}`
              : AutomationApprovalDisplayStates.ActionRequired
          }
        />
      )
    case AutomationApprovalDisplayStates.Approved:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          colour={StatusIconColours.Approved}
          size={size}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Approved}`
              : AutomationApprovalDisplayStates.Approved
          }
        />
      )

    case AutomationApprovalDisplayStates.Rejected:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.Rejected}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Rejected}`
              : AutomationApprovalDisplayStates.Rejected
          }
        />
      )

    case AutomationApprovalDisplayStates.AwaitingApproval:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.AwaitingApproval}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.AwaitingApproval}`
              : AutomationApprovalDisplayStates.AwaitingApproval
          }
        />
      )

    default:
      return (
        <Status
          cssClass={Style.approvalStatus}
          type='success'
          size={size}
          colour={StatusIconColours.Deactivated}
          label={
            showApproverRole
              ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.Deactivated}`
              : AutomationApprovalDisplayStates.Deactivated
          }
        />
      )
  }
}

export const StatusIcon = ({
  automation,
  appRole,
  size = 'small',
  showApproverRole,
  showApproverName,
}: IGetStatusIcon) => {
  const approvals = automation.AutomationApprovals as IAutomationApproval[]

  const sortedApprovals = useMemo(
    () => [...approvals].sort((a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime()),
    [approvals],
  )

  const latestApproval = sortedApprovals[0]

  const hasApprovalState = useMemo(() => {
    return sortedApprovals.find((approval) => approval.ApproverRole.Name === appRole)
  }, [appRole, sortedApprovals])

  const renderStatus = useCallback(
    (type: Statuses, colour: string, label: string) => (
      <Status cssClass={Style.approvalStatus} type={type} size={size} colour={colour} label={label} />
    ),
    [size],
  )

  if (automation.AutomationState?.Id === AutomationStates.DRAFT)
    return (
      <Status
        cssClass={Style.approvalStatus}
        type='success'
        size={size}
        colour={StatusIconColours.Draft}
        label={AutomationApprovalDisplayStates.Draft}
      />
    )

  if (
    !hasApprovalState ||
    (hasApprovalState.ApprovalState.Name === AutomationApprovalDisplayStates.ActionRequired &&
      hasApprovalState.IsResolved === true)
  ) {
    const label = showApproverRole
      ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.AwaitingApproval}`
      : AutomationApprovalDisplayStates.AwaitingApproval
    return renderStatus('success', StatusIconColours.AwaitingApproval, label)
  }

  if (automation.AutomationState?.Name === AutomationStateNames.DRAFT) {
    return renderStatus('success', StatusIconColours.Draft, AutomationApprovalDisplayStates.Draft)
  }

  if (
    latestApproval?.ApprovalState?.Name === AutomationApprovalDisplayStates.Rejected &&
    latestApproval?.ApproverRole?.Name !== appRole
  ) {
    const label = showApproverRole
      ? `${AppRoleName[appRole]} ${AutomationApprovalDisplayStates.NotApplicable}`
      : AutomationApprovalDisplayStates.NotApplicable
    return renderStatus('success', StatusIconColours.Deactivated, label)
  }

  return showApproverName ? (
    <Tooltip show={hasApprovalState.ApprovedBy.UserFullName} defaultUp padding={25} cssClass='tooltip-theme-dark'>
      <ApprovalStatus
        appRole={appRole}
        hasApprovalState={hasApprovalState}
        size={size}
        showApproverRole={showApproverRole}
      />
    </Tooltip>
  ) : (
    <ApprovalStatus
      appRole={appRole}
      hasApprovalState={hasApprovalState}
      size={size}
      showApproverRole={showApproverRole}
    />
  )
}
