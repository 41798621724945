import { getUserRoles } from '../helpers/appRoles'
import { useAuth0 } from '@auth0/auth0-react'
import { permissions } from '../helpers/userPermission'
import { AppRoles } from '../enums/AppRoles'

export const useUserPermission = (action: number): boolean => {
  const { user } = useAuth0()
  const userRoles = getUserRoles(user)

  if (userRoles.length === 0) userRoles.push(AppRoles.StandardUser)

  const rolesAllowed = permissions[action]

  const isAllowed = userRoles.some((role) => rolesAllowed.includes(role))

  return isAllowed
}
