import { FC, useCallback, useState } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import { Accordion, AccordionPanel, Icon, Status } from '@aurecon-creative-technologies/styleguide'
import { IAutomationApproval } from '../../models/api/IManageAutomationTableRow'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import { dateToString, htmlEntitiesParser, indexKey, URL_MATCHER_REGEX, URL_SPLITTER_REGEX } from '../../helpers/utils'
import { AppRoleLabel, AppRoleName } from '../../enums/AppRoles'
import { AppRolesEnum, StatusIconColours } from '../../config/config'

interface IVerificationInfoProps {
  automationApprovals: IAutomationApproval[]
}

export const getStatusIconColor = (approval: IAutomationApproval) => {
  switch (approval.ApprovalState.Name) {
    case AutomationApprovalDisplayStates.Approved:
      return StatusIconColours.Approved

    case AutomationApprovalDisplayStates.ActionRequired:
      return StatusIconColours.ActionRequired

    case AutomationApprovalDisplayStates.Rejected:
      return StatusIconColours.Rejected

    case AutomationApprovalDisplayStates.ConfirmedAction:
      return StatusIconColours.ConfrimedAction
    default:
      return StatusIconColours.AwaitingApproval
  }
}

const VerificationInformation: FC<IVerificationInfoProps> = (props) => {
  const { automationApprovals } = props
  const sortedApprovals = [...automationApprovals].sort((a, b) => {
    return new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime()
  })

  const [open, setOpen] = useState(false)

  const header = (
    <div className={Style.headingHolder}>
      <div className={Style.verificationHeading}>Authorisation</div>
      <div className={Style.verificationHeadingDate}>
        Automation authorised on{' '}
        {dateToString(new Date(sortedApprovals[sortedApprovals.length - 1]?.DateCreated), 'dd/MM/yyyy')}
      </div>
    </div>
  )

  const getVerificationLabel = useCallback((approval: IAutomationApproval, isAutomaticallyApproved: boolean) => {
    if (isAutomaticallyApproved) {
      return `${AppRoleName[approval.ApproverRole.Name]} ${approval.ApprovalState.Name} automatically by ${
        approval.ApprovedBy.UserFullName
      }`
    }

    if (approval.ApprovalState.Name === AutomationApprovalDisplayStates.ConfirmedAction) {
      return `${approval.ApprovalState.Name} by ${approval.ApprovedBy.UserFullName}`
    }

    return `${AppRoleName[approval.ApproverRole.Name]} ${approval.ApprovalState.Name} by ${
      approval.ApprovedBy.UserFullName
    }`
  }, [])

  return (
    <Accordion
      activePanelIds={open ? 'main' : ''}
      onPanelToggle={() => setOpen((o) => !o)}
      cssClass={Style.verificationWrapper}
    >
      <AccordionPanel
        label={header}
        panelId='main'
        cssHeaderClass={Style.accordionPanel}
        cssContentClass={Style.accordion}
      >
        <div className={Style.verification}>
          {sortedApprovals.map((approval) => {
            const isAutomaticallyApproved = approval.ApprovedBy.AuthFederationId === AppRolesEnum.SYSTEM_ADMIN
            const verificationLabel = getVerificationLabel(approval, isAutomaticallyApproved)
            if (approval.Override) {
              const previousRoleApprovals = [...sortedApprovals]
                .filter((a) => a.ApproverRole.Name === approval.ApproverRole.Name)
                .sort((a, b) => {
                  return new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime()
                })

              const index = previousRoleApprovals.findIndex((a) => a === approval)
              const previousApproval = previousRoleApprovals[index + 1]
              return (
                <div key={indexKey()} className={Style.verificationHolder}>
                  <div className={Style.overrideStatus}>
                    <Status
                      cssClass={Style.verificationStatus}
                      type='success'
                      colour={getStatusIconColor(previousApproval)}
                      label={`${AppRoleName[previousApproval.ApproverRole.Name]} ${
                        previousApproval.ApprovalState.Name
                      }`}
                      size='small'
                    />
                    <Icon type='arrow_forward' size='medium' cssClass={Style.arrow} />
                    <Status
                      cssClass={Style.newVerificationStatus}
                      type='success'
                      colour={getStatusIconColor(approval)}
                      label={`${AppRoleName[approval.ApproverRole.Name]} ${approval.ApprovalState.Name} by ${
                        approval.ApprovedBy.UserFullName
                      } (${AppRoleLabel[approval.OverrideRoleDetails?.Name ?? '']})`}
                      size='small'
                    />
                  </div>
                  <p className={Style.verificationDate}>
                    {dateToString(new Date(approval.DateCreated), 'dd/MM/yyyy h:maaa')}
                  </p>
                </div>
              )
            }

            const approverComment = htmlEntitiesParser(approval.ApproverComment) ?? ''
            const commentParts = approverComment.split(URL_SPLITTER_REGEX)

            return (
              <div key={indexKey()} className={Style.verificationHolder}>
                <Status
                  cssClass={Style.verificationStatus}
                  type='success'
                  colour={getStatusIconColor(approval)}
                  label={verificationLabel}
                  size='small'
                />
                <p className={Style.verificationDate}>
                  {dateToString(new Date(approval.DateCreated), 'dd/MM/yyyy h:maaa')}
                </p>
                {!isAutomaticallyApproved && (
                  <div className={Style.verificationCommentContainer}>
                    <span className={Style.automationDescription}>
                      {commentParts.map((part) =>
                        URL_MATCHER_REGEX.exec(part) ? (
                          <a
                            className={Style.automationDescription}
                            key={indexKey()}
                            href={part}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {part}
                          </a>
                        ) : (
                          part
                        ),
                      )}
                    </span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  )
}

export default VerificationInformation
