import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Button, Dropdown, IDropdownItemProps, useToast } from '@aurecon-creative-technologies/styleguide'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { PublishedInsights } from '../../stores/AppStore'
import { useRecoilValueLoadable } from 'recoil'
import AppInsightsLineChart, { ILineChartData } from './AppInsightsLineChart'
import { dateToString } from '../../helpers/utils'
import { ResponseData } from '../../models/api/IResponse'
import { exportPublishedInsights } from '../../api/AppService'

const THREE_MONTH_DATA = 1
const SIX_MONTH_DATA = 2
const dropdownItems: IDropdownItemProps[] = [
  { id: THREE_MONTH_DATA, label: '3 months' },
  { id: SIX_MONTH_DATA, label: '6 months' },
]
const MAX_MONTH_PUBLISHED_DATA = 6
const MIN_MONTH_PUBLISHED_DATA = 3

export interface IPublishedAutomations {
  name: string
  value: number
}

const PublishedAutomations: React.FC = () => {
  const { addToast, removeToast } = useToast()
  const canDownloadInsights = useUserPermission(actions.DOWNLOAD_INSIGHTS)
  const publishedInsights = useRecoilValueLoadable(PublishedInsights)
  const [publishedAutomations, setPublishedAutomations] = useState<IPublishedAutomations[]>([])
  const [lineData, setLineData] = useState<ILineChartData[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<number>(THREE_MONTH_DATA)
  const [dropdownData, setDropdownData] = useState<IDropdownItemProps[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if (publishedInsights.state !== 'hasValue' || !publishedInsights.contents) return
    const mappedData = publishedInsights.contents.monthData?.map((data) => {
      return {
        name: data.month,
        value: data.count,
      }
    })

    setPublishedAutomations(mappedData)
    setTotalCount(publishedInsights.contents.totalCount)
    setDropdownData(dropdownItems)
    if (mappedData?.length >= MAX_MONTH_PUBLISHED_DATA) {
      setLineData(mappedData?.slice(-MAX_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(SIX_MONTH_DATA)
    } else {
      setLineData(mappedData?.slice(-MIN_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(THREE_MONTH_DATA)
    }
  }, [publishedInsights.contents, publishedInsights.state])

  const handleDateRangeChange = (value: string | number) => {
    if (value === SIX_MONTH_DATA) {
      setLineData(publishedAutomations.slice(-MAX_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(SIX_MONTH_DATA)
    } else {
      setLineData(publishedAutomations.slice(-MIN_MONTH_PUBLISHED_DATA))
      setSelectedDateRange(THREE_MONTH_DATA)
    }
  }

  const months = useMemo(() => {
    const dropdownItem = dropdownData?.find((item) => item.id === selectedDateRange)
    if (!dropdownItem) return 3
    const months = parseInt((dropdownItem.label as string).replace(' months', ''))
    return months
  }, [dropdownData, selectedDateRange])

  const handleCSVDownload = useCallback(async () => {
    const infoToast = addToast({
      type: 'info',
      message: `Generating CSV file, please wait...`,
    })
    setIsDownloading(true)

    const dateFrom = new Date().setMonth(new Date().getMonth() - months)
    const endDate = new Date()

    const filename = `Published Automations-${dateToString(dateFrom, 'ddMMyyyy')} to ${dateToString(
      endDate,
      'ddMMyyyy',
    )}.csv`
    const csv = ResponseData(
      await exportPublishedInsights({
        months,
      }),
    )

    if (!csv) {
      removeToast(infoToast)
      addToast({
        type: 'error',
        message: `Failure to export, please try again`,
        timeout: 5000,
      })
      setIsDownloading(false)
      return
    }

    removeToast(infoToast)
    addToast({
      type: 'success',
      message: `Download data has been exported as CSV file.`,
      timeout: 5000,
    })

    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    link.download = filename
    link.click()
    link.remove()
    setIsDownloading(false)
  }, [addToast, months, removeToast])

  return (
    <div className={PageStyle.publishedAutomationsContainer}>
      <div className={PageStyle.publishedAutomationsHeader}>
        <div className={PageStyle.publishedAutomationstitle}>Published Automations</div>
        {canDownloadInsights && (
          <Button
            cssClass={PageStyle.downloadButton}
            type='icon-round'
            icon='file_download'
            onClick={handleCSVDownload}
            disabled={isDownloading}
          />
        )}
      </div>
      <div className={PageStyle.uniqueAndPublishedActionContainer}>
        <div className={PageStyle.sinceLaunchedContainer}>
          <div className={PageStyle.sinceLaunchedCount}> {totalCount ?? 0} </div>
          <span className={PageStyle.sinceLaunchedLabel}>Total since launched</span>
        </div>
        <div className={PageStyle.dateRange}>
          <div className={PageStyle.dateRangeTitle}> Date Range </div>
          <Dropdown
            items={dropdownData}
            selectedItem={selectedDateRange}
            onSelectItem={(data) => handleDateRangeChange(data)}
            cssClass={PageStyle.dateRangeDropdown}
          />
        </div>
      </div>
      <div className={PageStyle.publishedAutomationsGraphContainer}>
        <AppInsightsLineChart isLoading={publishedInsights.state === 'loading'} lineData={lineData} />
      </div>
    </div>
  )
}

export default PublishedAutomations
