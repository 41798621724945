import { IButtonProps, Modal, Dropdown, useToast, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/EditApproverRoleModal.module.sass'
import { AllApprovers, PermissionHistory, ShowEditApproverRoleModal } from '../../../stores/UserStore'
import { AppRolesEnum } from '../../../config/config'
import { AppRoleName, ApproverRoleName } from '../../../enums/AppRoles'
import { IGetApproverResponse, IPutEditRoleRequest } from '../../../models/api/IUserRequestModel'
import { editApproverRoleFormSchema } from '../../../validators/EditApproverRoleFormValidator'
import { editUserRole } from '../../../api/UserService'
import ConfirmModal from '../../ConfirmModal'
import classNames from 'classnames'
import { getUserCapability } from '../../../api/CapabilityService'

interface IEditApproverRoleModalProps {
  selectedUser: IGetApproverResponse | null
  capabilities?: IDropdownItemProps[]
}

const EditApproverRoleModal: FC<IEditApproverRoleModalProps> = ({ selectedUser, capabilities }) => {
  const [showEditApproverRoleModal, setShowEditApproverRoleModal] = useRecoilState(ShowEditApproverRoleModal)
  const resetAllApproverList = useRecoilRefresher_UNSTABLE(AllApprovers)
  const resetPermissionHistory = useRecoilRefresher_UNSTABLE(PermissionHistory)

  const [role, setRole] = useState('')
  const [selectedCapability, setSelectedCapability] = useState('')
  const [user, setUser] = useState<IGetApproverResponse>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const emptyForm: IPutEditRoleRequest = { email: '', previousRole: '', newRole: '' }
  const [errors, setErrors] = useState<IPutEditRoleRequest>(emptyForm)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [loadingCapability, setLoadingCapability] = useState<boolean>(false)
  const [previousCapability, setPreviousCapability] = useState('')

  const { addToast } = useToast()

  useEffect(() => {
    if (selectedUser) {
      const currentRoleKey = Object.entries(AppRolesEnum).find(
        ([key]) => AppRolesEnum[key as keyof typeof AppRolesEnum] === selectedUser?.roles[0],
      )?.[0]
      setUser(selectedUser)
      const roleName = AppRolesEnum[currentRoleKey as keyof typeof AppRolesEnum]
      setRole(roleName)
      if (roleName === AppRolesEnum.TECHNICAL) {
        const userCapability = capabilities?.find((cap) => cap.label === selectedUser.capability)
        setSelectedCapability(userCapability?.id.toString() ?? '')
        setPreviousCapability(userCapability?.id.toString() ?? '')
      }
    }
  }, [capabilities, selectedUser])

  useEffect(() => {
    const fetchData = async (userEmail: string) => {
      try {
        setLoadingCapability(true)
        const response = await getUserCapability({ userEmail })
        if (response?.success) {
          const userCapability = capabilities?.find((cap) => cap.label === response.data?.capability)
          setSelectedCapability(userCapability?.id.toString() ?? '')
        }
        setLoadingCapability(false)
      } catch (err) {
        return
      }
    }

    if (selectedUser && !selectedUser.capability) fetchData(selectedUser.email)
  }, [capabilities, selectedUser])

  const roleKeys = Object.keys(ApproverRoleName)
  const roleItems = roleKeys.map((key) => ({
    id: AppRolesEnum[key as keyof typeof AppRolesEnum],
    label: `${AppRoleName[AppRolesEnum[key as keyof typeof AppRolesEnum]]} Authoriser`,
  }))

  const handleClose = () => {
    setIsSubmitting(false)
    setShowConfirmationModal(false)
    setShowEditApproverRoleModal(false)
  }

  const handleSave = async () => {
    if (!user) return

    const payload = {
      email: user.email,
      previousRole: user.roles[0] ?? AppRolesEnum.STANDARD_USER,
      newRole: role,
      newCapability: selectedCapability,
    }

    const { error } = editApproverRoleFormSchema.validate(payload, {
      abortEarly: false,
    })

    if (error) {
      setErrors({
        email: error.details.find((err) => err.path[0] === 'email')?.message ?? '',
        previousRole: error.details.find((err) => err.path[0] === 'previousRole')?.message ?? '',
        newRole: error.details.find((err) => err.path[0] === 'newRole')?.message ?? '',
        newCapability: error.details.find((err) => err.path[0] === 'newCapability')?.message ?? '',
      })
      setShowConfirmationModal(false)
      setShowEditApproverRoleModal(true)
      return
    }

    setErrors(emptyForm)
    setIsSubmitting(true)
    const result = await editUserRole({ ...payload, previousCapability })

    if (!result.success) {
      addToast({
        type: 'error',
        message: 'Failed to assign role.',
        timeout: 4000,
        datetime: Date.now(),
      })
      return
    }

    resetAllApproverList()
    resetPermissionHistory()
    setIsSubmitting(false)
    handleClose()
    addToast({
      type: 'success',
      message: 'Authoriser’s role updated successfully.',
      timeout: 4000,
      datetime: Date.now(),
    })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: isSubmitting ? 'Saving' : 'Save',
      type: 'secondary',
      onClick: () => {
        setShowEditApproverRoleModal(false)
        setShowConfirmationModal(true)
      },
      cssClass: Style.primaryButton,
      disabled: isSubmitting,
    },
  ]

  const editModalClasses = classNames({
    [Style.editApprovalModal]: true,
    [Style.editTechnicalApprovalModal]: role === AppRolesEnum.TECHNICAL,
  })

  return (
    <>
      <Modal
        isShowing={showEditApproverRoleModal}
        actions={ModalActions}
        isCloseButton
        shouldOverlayClose={false}
        onClose={() => setShowEditApproverRoleModal(false)}
        size='medium'
        cssClass={editModalClasses}
        modalPadding={{
          top: '24px',
          right: '24px',
          left: '24px',
        }}
      >
        <>
          <div className={Style.iconRow}>
            <h2>Edit Authoriser Role?</h2>
          </div>
          <div className={Style.modalContent}>
            <div className={Style.userDetails}>
              <div className={Style.label}>User</div>
              <div className={Style.userEmail}>{`${selectedUser?.name} (${selectedUser?.email})`}</div>
            </div>
            {role === AppRolesEnum.TECHNICAL && (
              <div className={Style.dropdownContainer}>
                <div className={Style.statusDrodownlabel}>Capability *</div>
                <Dropdown
                  items={capabilities ?? []}
                  selectedItem={selectedCapability}
                  onSelectItem={(v) => setSelectedCapability(v.toString())}
                  required
                  placeholder={loadingCapability ? 'Loading...' : 'Select a Capability'}
                  cssClass={Style.statusDropdown}
                  disabled={loadingCapability}
                />
              </div>
            )}
            <div className={Style.dropdownContainer}>
              <div className={Style.statusDrodownlabel}>Role *</div>
              <Dropdown
                items={roleItems}
                selectedItem={role}
                onSelectItem={(v) => {
                  setRole(v.toString())
                  setSelectedCapability('')
                }}
                required
                placeholder='Select a Role'
                cssClass={Style.statusDropdown}
              />
            </div>
            {errors.newRole && <p className={Style.errorMessage}>{errors.newRole}</p>}
          </div>
        </>
      </Modal>
      <ConfirmModal
        title={'Update Role?'}
        message={
          <>
            This action will change the user’s permission on automations. Are you sure you want to change role for{' '}
            <span className={Style.userName}>{selectedUser?.name}</span>?
          </>
        }
        open={showConfirmationModal}
        onClose={() => {
          setShowEditApproverRoleModal(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onNo={() => {
          setShowEditApproverRoleModal(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onYes={handleSave}
        loading={isSubmitting}
        isCloseButton
      />
    </>
  )
}

export default EditApproverRoleModal
