export enum AutomationFormFieldsEnum {
  AutomationSourceId = 'AutomationSourceId',
  CurrentActivityTime = 'CurrentActivityTime',
  AutomatedActivityTime = 'AutomatedActivityTime',
  GuidanceMaterialUrl = 'GuidanceMaterialUrl',
  Title = 'Title',
  Description = 'Description',
  ApprovingCapability = 'ApprovingCapability',
  PlatformInterfaceId = 'PlatformInterfaceId',
  UploadTypeId = 'UploadTypeId',
  AutomationCapabilities = 'AutomationCapabilities',
  AutomationRegions = 'AutomationRegions',
  AutomationPractices = 'AutomationPractices',
  AutomationOriginators = 'AutomationOriginators',
  AutomationVerifiers = 'AutomationVerifiers',
  AutomationClients = 'AutomationClients',
  AutomationFileUpload = 'AutomationFileUpload',
  AutomationUrl = 'AutomationUrl',
  PrimaryAuthor = 'PrimaryAuthor',
}

export const AutomationFormFieldsLabel = {
  [AutomationFormFieldsEnum.AutomationSourceId]: 'Source',
  [AutomationFormFieldsEnum.CurrentActivityTime]: 'Current Activity Time',
  [AutomationFormFieldsEnum.AutomatedActivityTime]: 'Automation Activity Time',
  [AutomationFormFieldsEnum.GuidanceMaterialUrl]: 'Guidance Material URL',
  [AutomationFormFieldsEnum.Title]: 'Automation Title',
  [AutomationFormFieldsEnum.Description]: 'Automation Description',
  [AutomationFormFieldsEnum.ApprovingCapability]: 'Authorising Capability',
  [AutomationFormFieldsEnum.PlatformInterfaceId]: 'Platform Interface',
  [AutomationFormFieldsEnum.UploadTypeId]: 'UploadTypeId',
  [AutomationFormFieldsEnum.AutomationCapabilities]: 'Capability',
  [AutomationFormFieldsEnum.AutomationRegions]: 'Region',
  [AutomationFormFieldsEnum.AutomationPractices]: 'Practice',
  [AutomationFormFieldsEnum.AutomationOriginators]: 'Originator',
  [AutomationFormFieldsEnum.AutomationVerifiers]: 'Verifier',
  [AutomationFormFieldsEnum.AutomationClients]: 'Client',
  [AutomationFormFieldsEnum.AutomationFileUpload]: 'Source',
  [AutomationFormFieldsEnum.AutomationUrl]: 'Automation Url',
  [AutomationFormFieldsEnum.PrimaryAuthor]: 'Primary Author',
}
