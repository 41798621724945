import { FC } from 'react'
import {
  Loader,
  Pill,
  TableCell,
  TableRow,
  Tooltip as InfoToolTip,
  IHeader,
  Table,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'

import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { INSIGHTS_MAX_PAGE_SIZE } from '../../config/config'
import { ApplicationsLabel } from '../../enums/ApplicationInsightsEnums'
import { dateToString, indexKey } from '../../helpers/utils'
import { IAppInsightsRow } from '../../models/api/IApplicationInsights'

interface AppInsightsTableProps {
  isLoading?: boolean
  tableData: IAppInsightsRow[]
  tableHeaders: IHeader[]
  currentPage: number
}

const AppInsightsTable: FC<AppInsightsTableProps> = (props) => {
  const { isLoading, tableData, tableHeaders, currentPage } = props
  if (isLoading)
    return (
      <Table breakpoint={1} headers={tableHeaders}>
        <TableRow rowClass={PageStyle.submissionRow}>
          <TableCell colSpan={12} align='right' cellClass={PageStyle.noAvailableData}>
            <Loader size='small' />
          </TableCell>
        </TableRow>
      </Table>
    )

  if (tableData.length === 0)
    return (
      <Table breakpoint={1} headers={tableHeaders}>
        <TableRow rowClass={PageStyle.submissionRow}>
          <TableCell colSpan={12} align='right' cellClass={PageStyle.noAvailableData}>
            No available data
          </TableCell>
        </TableRow>
      </Table>
    )

  return (
    <Table breakpoint={1} headers={tableHeaders}>
      {tableData
        .slice((currentPage - 1) * INSIGHTS_MAX_PAGE_SIZE, currentPage * INSIGHTS_MAX_PAGE_SIZE)
        .map((data: IAppInsightsRow) => {
          const applicationTagClasses = classNames({
            [PageStyle.applicationTag]: true,
            [PageStyle.automationCentre]: data.application === ApplicationsLabel.AUTOMATIONCENTRE,
            [PageStyle.scriptLibrary]: data.application === ApplicationsLabel.SCRIPTLIBRARY,
          })

          return (
            <TableRow key={indexKey()} rowClass={PageStyle.submissionRow}>
              <TableCell>{data.title}</TableCell>
              <TableCell>
                <InfoToolTip
                  padding={32}
                  cssClass='tooltip-theme-dark'
                  defaultUp
                  show={`${data.user?.UserFullName} (${data.user?.UserEmail})`}
                >
                  {data.user?.UserFullName}
                </InfoToolTip>
              </TableCell>
              <TableCell>
                <Pill size='small' cssClass={applicationTagClasses}>
                  {data.application}
                </Pill>
              </TableCell>
              <TableCell>{dateToString(data.dateCreated)}</TableCell>
            </TableRow>
          )
        })}
    </Table>
  )
}

export default AppInsightsTable
