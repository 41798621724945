//These are the individual approvals by the different role groups on a given automation request
//The overall automation state is in the file AutomationStates.ts

//There are 2 versions of these states: one for display purposes and one for the DB

export enum AutomationApprovalDisplayStates {
  Draft = 'Draft',
  AwaitingApproval = 'Awaiting Authorisation',
  Approved = 'Authorised',
  Rejected = 'Rejected',
  ActionRequired = 'Action Required',
  NotApplicable = 'Not applicable',
  Deactivated = 'Deactivated',
  ConfirmedAction = 'Confirmed Action',
}

//This should match the table LUApprovalState
export enum AutomationApprovalStates {
  Approved = 1,
  Rejected = 2,
  ActionRequired = 3,
}
