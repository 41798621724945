import { FC, SetStateAction, useCallback, useState } from 'react'

import Style from '../../styles/common/CustomPagination.module.sass'
import { buttonType } from '../../enums/ButtonType'
import { Button } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'

interface ICustomPaginationProps {
  currentPage: number
  setCurrentPage: (value: SetStateAction<number>) => void
  maxPages: number
}

const CustomPagination: FC<ICustomPaginationProps> = ({ currentPage, setCurrentPage, maxPages }) => {
  const [activeButton, setActiveButton] = useState<string>('')

  const handleClick = useCallback(
    (buttonId: string) => {
      setActiveButton(buttonId)
      switch (buttonId) {
        case buttonType.START_BUTTON:
          setCurrentPage(1)
          break
        case buttonType.FORWARD_BUTTON:
          setCurrentPage(currentPage + 1)
          break
        case buttonType.BACK_BUTTON:
          setCurrentPage(currentPage - 1)
          break
        case buttonType.END_BUTTON:
          setCurrentPage(maxPages)
          break
        default:
          break
      }
    },
    [currentPage, maxPages, setCurrentPage],
  )

  const buttonClasses = useCallback(
    (buttonId: string): string => {
      return classNames({
        [Style.active]: activeButton === buttonId,
        [Style.pageButtons]: true,
      })
    },
    [activeButton],
  )

  return (
    <div className={Style.paginationContainer} id='custom-pagination'>
      <div className={Style.pageReference}>
        <p>
          Page <input type='text' value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} /> of{' '}
          {maxPages}
        </p>
      </div>
      <div className={Style.pageButtonsContainer}>
        <Button
          onClick={() => handleClick(buttonType.START_BUTTON)}
          type='icon-round-secondary'
          size='medium'
          icon='keyboard_double_arrow_left'
          disabled={currentPage === 1}
          cssClass={Style.pageButtons}
        />
        <Button
          onClick={() => handleClick(buttonType.BACK_BUTTON)}
          type='icon-round-secondary'
          size='medium'
          icon='keyboard_arrow_left'
          disabled={currentPage <= 1}
          cssClass={buttonClasses(buttonType.BACK_BUTTON)}
        />
        <Button
          onClick={() => handleClick(buttonType.FORWARD_BUTTON)}
          type='icon-round-secondary'
          size='medium'
          icon='keyboard_arrow_right'
          disabled={currentPage === maxPages}
          cssClass={buttonClasses(buttonType.FORWARD_BUTTON)}
        />
        <Button
          onClick={() => handleClick(buttonType.END_BUTTON)}
          type='icon-round-secondary'
          size='medium'
          icon='keyboard_double_arrow_right'
          disabled={currentPage === maxPages}
          cssClass={Style.pageButtons}
        />
      </div>
    </div>
  )
}

export default CustomPagination
