import React, { useEffect, useState, useCallback } from 'react'
import PageStyle from '../../styles/ApplicationInsights.module.sass'
import { Button, DateInput, Loader, useToast } from '@aurecon-creative-technologies/styleguide'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { UniqueUsersByCountryInsights, UniqueUsersDateRange, UniqueUsersInsights } from '../../stores/AppStore'
import { IUniqueUsersByCountryResponse } from '../../models/api/IApplicationInsights'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import UniqueUsersPieChart from './UniqueUsersPieChart'
import { exportUniqueUsersByCountryInsights } from '../../api/AppService'
import { ResponseData } from '../../models/api/IResponse'
import { convertToLocalISOString, dateToString } from '../../helpers/utils'
import { LAUNCH_DATE } from '../../config/config'

const UniqueUsers: React.FC = () => {
  const { addToast, removeToast } = useToast()
  const canDownloadInsights = useUserPermission(actions.DOWNLOAD_INSIGHTS)
  const [activeIndex, setActiveIndex] = useState(0)
  const [dateFilter, setDateFilter] = useRecoilState(UniqueUsersDateRange)
  const [uniqueUsersByCountry, setUniqueUsersByCountry] = useState<IUniqueUsersByCountryResponse[]>()
  const uniqueUsersByCountryLoadable = useRecoilValueLoadable(UniqueUsersByCountryInsights)
  const [isDownloading, setIsDownloading] = useState(false)
  const uniqueUsersLoadable = useRecoilValueLoadable(
    UniqueUsersInsights({
      startDate: convertToLocalISOString(dateFilter.startDate ?? new Date(LAUNCH_DATE)),
      endDate: convertToLocalISOString(dateFilter.endDate ?? new Date()),
    }),
  )

  useEffect(() => {
    if (uniqueUsersByCountryLoadable.state === 'hasValue') {
      setUniqueUsersByCountry(uniqueUsersByCountryLoadable.contents)
    }
  }, [uniqueUsersByCountryLoadable])

  const handleCSVDownload = useCallback(async () => {
    const infoToast = addToast({
      type: 'info',
      message: `Generating CSV file, please wait...`,
      timeout: 1000,
    })
    setIsDownloading(true)
    const startDate = dateFilter.startDate ?? new Date(LAUNCH_DATE)
    const endDate = dateFilter.endDate ?? new Date()
    const filename =
      !dateFilter.startDate || !dateFilter.endDate
        ? 'Total Unique Users-ALL.csv'
        : `Total Unique Users-${dateToString(startDate, 'ddMMyyyy')} to ${dateToString(endDate, 'ddMMyyyy')}.csv`
    const csv = ResponseData(
      await exportUniqueUsersByCountryInsights({
        startDate: convertToLocalISOString(startDate),
        endDate: convertToLocalISOString(endDate),
      }),
    )

    if (!csv) {
      removeToast(infoToast)
      addToast({
        type: 'error',
        message: `Failure to export, please try again`,
        timeout: 5000,
      })
      setIsDownloading(false)
      return
    }

    removeToast(infoToast)
    addToast({
      type: 'success',
      message: `Unique visitors data has been exported as CSV file.`,
      timeout: 5000,
    })

    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    link.download = filename
    link.click()
    link.remove()
    setIsDownloading(false)
  }, [addToast, dateFilter.endDate, dateFilter.startDate, removeToast])

  return (
    <div className={PageStyle.uniqueUsersContainer}>
      <div className={PageStyle.uniqueUsersHeader}>
        <div className={PageStyle.uniqueUserstitle}>Total Visits</div>
        {canDownloadInsights && (
          <Button
            cssClass={PageStyle.downloadButton}
            type='icon-round'
            icon='file_download'
            onClick={handleCSVDownload}
            disabled={isDownloading || uniqueUsersByCountry?.length === 0}
          />
        )}
      </div>
      <div className={PageStyle.uniqueAndPublishedActionContainer}>
        <div className={PageStyle.sinceLaunchedContainer}>
          <div className={PageStyle.sinceLaunchedCount}> {uniqueUsersLoadable.contents?.data?.[0]?.uniqueUsers} </div>
        </div>
        <div className={PageStyle.dateRange}>
          <div className={PageStyle.dateRangeTitle}> Date Range </div>
          <DateInput dates={dateFilter} rangeType cssClass={PageStyle.dateRangeInput} onDateChange={setDateFilter} />
        </div>
      </div>
      <div className={PageStyle.uniqueGraphContainer}>
        {uniqueUsersByCountryLoadable.state === 'loading' || uniqueUsersLoadable.state === 'loading' ? (
          <div className={PageStyle.loaderContainer}>
            <Loader size='small' />
          </div>
        ) : null}
        <UniqueUsersPieChart
          uniqueUsersByCountry={uniqueUsersByCountry}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </div>
  )
}

export default UniqueUsers
