import { FC, useCallback, useState } from 'react'
import Style from '../../../styles/common/modals/ReorderFeaturedModal.module.sass'
import { IButtonProps, Modal, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from 'recoil'
import { ShowReorderFeaturedModal } from '../../../stores/SettingsStore'
import { AllFeaturedAutomations } from '../../../stores/AutomationViewerStore'
import { IFeaturedAutomationResponse } from '../../../models/api/IFeaturedAutomations'
import { indexKey } from '../../../helpers/utils'
import { updateFeatureLocation } from '../../../api/AutomationService'
import LeaveModal from '../../LeaveModal'
import FeaturedList from '../../FeaturedAutomation/FeaturedList'
import { FeaturedAutomationsLabels } from '../../../enums/FeaturedAutomationsEnum'

const ReorderFeaturedModal: FC = () => {
  const { addToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const featuredLoader = useRecoilValueLoadable(AllFeaturedAutomations)
  const positions = Object.values(FeaturedAutomationsLabels)

  const [showReorderFeaturedModal, setShowReorderFeaturedModal] = useRecoilState(ShowReorderFeaturedModal)
  const [listItems, setListItems] = useState<IFeaturedAutomationResponse[]>(featuredLoader.contents ?? [])
  const refreshFeaturedAutomations = useRecoilRefresher_UNSTABLE(AllFeaturedAutomations)

  const resetOrder = useCallback(() => {
    setListItems(featuredLoader.contents ?? [])
    setIsChanged(false)
  }, [featuredLoader.contents])
  const handleClose = () => {
    if (isChanged) {
      setShowConfirmationModal(true)
      setShowReorderFeaturedModal(false)
      return
    }
    setShowReorderFeaturedModal(false)
  }

  const handleSave = async () => {
    setIsSaving(true)
    const reorderedList = listItems.map((item, index) => {
      return { AutomationId: item.Id, FeatureLocation: index + 1 }
    })

    const result = await updateFeatureLocation({ items: reorderedList })
    if (result?.success) {
      setShowReorderFeaturedModal(false)
      refreshFeaturedAutomations()
      addToast({ message: 'Featured automations have been updated.', type: 'success', timeout: 4000 })
    } else {
      addToast({ message: 'Failed to update featured automations.', type: 'error', timeout: 4000 })
    }

    setIsSaving(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: isSaving ? 'Saving' : 'Save',
      type: 'secondary',
      onClick: () => {
        handleSave()
      },
      cssClass: Style.primaryButton,
      disabled: isSaving || !isChanged,
    },
  ]

  return (
    <>
      <Modal
        isShowing={showReorderFeaturedModal}
        isCloseButton
        onClose={handleClose}
        actions={ModalActions}
        shouldOverlayClose={false}
        size='medium'
        cssClass={Style.featuredorderFormModal}
        modalPadding={{
          top: '24px',
          right: '24px',
          left: '24px',
        }}
      >
        <>
          <div className={Style.iconRow}>
            <h2>Change Order?</h2>
            <p className={Style.subheader}>
              Drag and drop to select where should featured automations sit on home page.{' '}
            </p>
          </div>

          {featuredLoader.state === 'loading' ? (
            <div className={Style.loadingContainer}>
              <p className={Style.noResultText}>Loading...</p>
            </div>
          ) : (
            <Grid gap={0} row cssClass={Style.modalContent}>
              <Grid item xs={2} cssClass={Style.positions}>
                <Grid item xs={12} cssClass={Style.positionIndicatorHeader}>
                  Order
                </Grid>
                {positions.map((position) => (
                  <Grid key={indexKey()} item xs={12} cssClass={Style.positionIndicator}>
                    {position}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={10} cssClass={Style.featuredAutomations}>
                <Grid item xs={12} cssClass={Style.featuredItemHeader}>
                  Automation Title
                </Grid>
                <FeaturedList listItems={listItems} setListItems={setListItems} setIsChanged={setIsChanged} />
              </Grid>
            </Grid>
          )}
        </>
      </Modal>
      <LeaveModal
        title={'Unsaved Changes'}
        message={<>You have unsaved changes. Are you sure you want to leave this page?</>}
        open={showConfirmationModal}
        onClose={() => {
          setShowReorderFeaturedModal(true)
          setShowConfirmationModal(false)
        }}
        onNo={() => {
          setShowReorderFeaturedModal(true)
          setShowConfirmationModal(false)
        }}
        onYes={() => {
          resetOrder()
          setShowReorderFeaturedModal(false)
          setShowConfirmationModal(false)
        }}
        labelYes={'Leave'}
        labelClose={'Cancel'}
        isCloseButton
      />
    </>
  )
}

export default ReorderFeaturedModal
